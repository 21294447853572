import React, { Component } from 'react';
import Dropdown from 'react-kiwi-dropdown';

import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import firebase from "firebase";

export default class Enquiry extends React.Component {
	constructor (props) {
    super(props)
    this.state = {
    	selectedBuyCity: 'mumbai',
      	selectedBuyCityOptions: 'Mumbai',
      	selectedEnquiry: "0-10,000",
	    selectedEnquiryOption: "Rs 0-10,000",

	    organizationEnquiry:'',
	    phoneEnquiry:'',
	    currentDateFormatted:''
    	}
	}

	componentDidMount(){
	
	// this.checkUserLogin()
	this.getCurrentDate()

  }

  getCurrentDate(){
  	var currentDate = new Date()
	const enquiryDate = currentDate.getDate() +'-'+(currentDate.getMonth()+1)+'-'+currentDate.getFullYear();
	this.setState({
		currentDateFormatted: enquiryDate
	})
  }



	onChangeBuyCityEnquiry(option){
		this.setState({
	    selectedBuyCity: option.value, 
	    selectedBuyCityOptions: option.content,


	  })
	}

	onChangeEnquiryOption(option){
		this.setState({
			selectedEnquiry: option.value,
	    	selectedEnquiryOption: option.content
	  })
	}

	submitEnquiry(){
		if (this.state.phoneEnquiry.length !== 10) {
			M.toast({html: '<span className="yellow-text">10 digit phone number required</span>'})
		}else if(this.state.organizationEnquiry.length ==0){
			M.toast({html: '<span className="yellow-text">Organization cannot be blank</span>'})
		}else{
			this.sendEnquiry()
		}
		  	
	}


	reload(){
		window.location.reload(false);
	}

	uniqueNumber(){
		var date = new Date();
		var un = ''+date.getDate()+(date.getMonth()+1)+date.getFullYear()+date.getHours()+date.getMinutes()+date.getMilliseconds();
		return un
	}


	sendEnquiry(){
		var enquiryId = this.uniqueNumber()

		var newEnquiry = 
			{city: this.state.selectedBuyCityOptions,
			 selectedBuyCity: this.state.selectedBuyCity, 
	    	 selectedBuyCityOptions: this.state.selectedBuyCityOptions,
			 date: this.state.currentDateFormatted,
			 email: global.userData.providerData[0].uid,
			 id: enquiryId,
			 name: global.userData.providerData[0].displayName,
			 organization: this.state.organizationEnquiry,
			 phone: this.state.phoneEnquiry,
			 status: 'new',
			 subject: 'Money Transfer Enuqiry',
			 volume	: this.state.selectedEnquiryOption,
			 code:1
			}

		firebase.firestore().collection("enquries").doc(enquiryId).set(newEnquiry)
		.then((doc) => {
        	var a = M.Modal.init(this.enqiry_form)
  			a.close()
  			M.toast({html: '<span className="yellow-text">Thank you. Your enquiry has been submitted successfully</span>'})
      	})
    	.catch(error => {
        console.log('firebase Error::'+error)
        M.toast({html: '<span className="yellow-text">Unable to submit enquiry</span>'})
      	 })

		
	}

	handleNumber(e){
  	this.setState({
       phoneEnquiry: e.target.value
    })
  }


  handleOrganization(e){
  	this.setState({
       organizationEnquiry: e.target.value
    })
  }



  render() {
    return (
    	<div id="enquiry-form" className="modal" ref={enqiry_form => { this.enqiry_form = enqiry_form; }}>
	        <div className="modal-content">
	           <div className="col s12 m12 relative r-top-10"  id="city_01_enquiry">
                  <div className="grey-text t-left f-12 p-bottom-5">City </div>
                  <img className="flag-position"  width="25" height="25" src={'https://img.icons8.com/fluent-systems-regular/344/user-location.png'} style={{marginRight:5, marginLeft:10}} />
                  <Dropdown
                    options={global.buyCity}
                    onChange={(e)=>{this.onChangeBuyCityEnquiry(e)}}
                    buttonLabel={this.state.selectedBuyCityOptions}
                    resetValue={''}
                    selectedOption={this.state.selectedBuyCity}
                  />
                </div>
                 
		         <div className="input-field col s12   r-top-10"  style={{marginTop:50}}>
		            <input placeholder="Enter phone number" id="phone-enquiry" type="number" className=" black-text white" value={this.state.phoneEnquiry} onChange={(e)=>{this.handleNumber(e)}} style={{marginTop:5, paddingLeft:5}}/>
		            <label htmlFor="Phone Number" className="">Phone Number</label>
		          </div>
		          <div className="input-field col s12   r-top-10"  style={{marginTop:20}}>
		            <input placeholder="Organization name" id="oraganization-enquiry" type="text" className=" black-text white" value={this.state.organizationEnquiry} onChange={(e)=>{this.handleOrganization(e)}} style={{marginTop:5, paddingLeft:5}}/>
		            <label htmlFor="Oragnization" className="">Organization Name</label>
		          </div> 

		          <div className="col s12 m12 relative r-top-10"  id="city_01_enquiry">
	                  <div className="grey-text t-left f-12 p-bottom-5">Monthly Import/ Export Volume </div>
	                  <img className="flag-position"  width="25" height="25" src={'https://img.icons8.com/plasticine/2x/money.png'} style={{marginRight:5, marginLeft:10}} />
	                  <Dropdown
	                    options={global.enquiry}
	                    onChange={(e)=>{this.onChangeEnquiryOption(e)}}
	                    buttonLabel={this.state.selectedEnquiryOption}
	                    resetValue={''}
	                    selectedOption={this.state.selectedEnquiry}
	                  />
                  </div>

                  <a className="waves-effect waves-light btn-large w-100p" onClick={()=>{this.submitEnquiry()}}  style={{marginTop:20}}>Submit Enquiry</a>

		          
	      	</div>
	      <div className="modal-footer">
	        <a href="#!" className="modal-close waves-effect waves-green btn-flat" onClick={()=>{this.reload()}}>close</a>
      	  </div>
      </div>
    );
  }
}
