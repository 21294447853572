import React, { Component } from 'react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true
  };

export default class Review extends React.Component {
  render() {
    return (
        <div id="reviewContainer">
          <Slider {...settings} className="grey lighten-3">

		      <div className="row">
				<div className="">
					<div className="col s12 m3 l2">
	    				<img  src="/user_2.png" style={{height:100, width:100, borderRadius:50}} className="relative green-border" />
	    			</div>
	    			<div className="col s12 m9 l10">
						<blockquote className="teal-text" style={{marginLeft:20}}>Hassle Free Inter country currency exchange services with best conversion rates. Very Prompt & Proactive.Got a Card delivered for my Son at my doorstep the next day morning itself. Wire Transfer services work so well in a limited period of time.Extra- Ordinary Services and Highly Recommendable.
						</blockquote>
					</div>
				</div>
		      </div>

		      <div className="row">
				<div className="">
					<div className="col s12 m3 l2">
	    				<img  src="/user_2.png" style={{height:100, width:100, borderRadius:50}} className="relative green-border" />
	    			</div>
	    			<div className="col s12 m9 l10">
						<blockquote className="teal-text" style={{marginLeft:20}}>I have transferred money to my son, studying abroad, many times in the last two years, through Book-Forex. Book-Forex is very safe, reliable, and very fast in wire transfer. Book-Forex is charging a very nominal exchange rate. I believe that Book-Forex is the real HERO in wire transfer.
						</blockquote>
					</div>
				</div>
		      </div>

		      
	    </Slider>
        </div>
    );
  }
}
