 global.buyCity =[{ value: "Mumbai", content: 'Mumbai' },
{ value: "Delhi", content: 'Delhi' },
{ value: "Bengaluru", content: 'Bengaluru' },
{ value: "Ahmedabad", content: 'Ahmedabad' },
{ value: "Hyderabad", content: 'Hyderabad' },
{ value: "Chennai", content: 'Chennai' },
{ value: "Kolkata", content: 'Kolkata' },
{ value: "Pune", content: 'Pune' },
{ value: "Jaipur", content: 'Jaipur' },
{ value: "Surat", content: 'Surat' },
{ value: "Lucknow", content: 'Lucknow' },
{ value: "Kanpur", content: 'Kanpur' },
{ value: "Nagpur", content: 'Nagpur' },
{ value: "Patna", content: 'Patna' },
{ value: "Indore", content: 'Indore' },
{ value: "Thane", content: 'Thane' },
{ value: "Bhopal", content: 'Bhopal' },
{ value: "Visakhapatnam", content: 'Visakhapatnam' },
{ value: "Vadodara", content: 'Vadodara' },
{ value: "Firozabad", content: 'Firozabad' },
{ value: "Ludhiana", content: 'Ludhiana' },
{ value: "Rajkot", content: 'Rajkot' },
{ value: "Agra", content: 'Agra' },
{ value: "Siliguri", content: 'Siliguri' },
{ value: "Nashik", content: 'Nashik' },
{ value: "Faridabad", content: 'Faridabad' },
{ value: "Patiala", content: 'Patiala' },
{ value: "Meerut", content: 'Meerut' },
{ value: "Kalyan-Dombivali", content: 'Kalyan-Dombivali' },
{ value: "Vasai-Virar", content: 'Vasai-Virar' },
{ value: "Varanasi", content: 'Varanasi' },
{ value: "Dhanbad", content: 'Dhanbad' },
{ value: "Jodhpur", content: 'Jodhpur' },
{ value: "Amritsar", content: 'Amritsar' },
{ value: "Raipur", content: 'Raipur' },
{ value: "Allahabad", content: 'Allahabad' },
{ value: "Coimbatore", content: 'Coimbatore' },
{ value: "Jabalpur", content: 'Jabalpur' },
{ value: "Gwalior", content: 'Gwalior' },
{ value: "Vijayawada", content: 'Vijayawada' },
{ value: "Madurai", content: 'Madurai' },
{ value: "Guwahati", content: 'Guwahati' },
{ value: "Chandigarh", content: 'Chandigarh' },
{ value: "Hubli-Dharwad", content: 'Hubli-Dharwad' },
{ value: "Amroha", content: 'Amroha' },
{ value: "Moradabad", content: 'Moradabad' },
{ value: "Gurgaon", content: 'Gurgaon' },
{ value: "Aligarh", content: 'Aligarh' },
{ value: "Solapur", content: 'Solapur' },
{ value: "Ranchi", content: 'Ranchi' },
{ value: "Jalandhar", content: 'Jalandhar' },
{ value: "Tiruchirappalli", content: 'Tiruchirappalli' },
{ value: "Bhubaneswar", content: 'Bhubaneswar' },
{ value: "Salem", content: 'Salem' },
{ value: "Warangal", content: 'Warangal' },
{ value: "Mira-Bhayandar", content: 'Mira-Bhayandar' },
{ value: "Thiruvananthapuram", content: 'Thiruvananthapuram' },
{ value: "Bhiwandi", content: 'Bhiwandi' },
{ value: "Saharanpur", content: 'Saharanpur' },
{ value: "Guntur", content: 'Guntur' },
{ value: "Amravati", content: 'Amravati' },
{ value: "Bikaner", content: 'Bikaner' },
{ value: "Noida", content: 'Noida' },
{ value: "Jamshedpur", content: 'Jamshedpur' },
{ value: "Bhilai Nagar", content: 'Bhilai Nagar' },
{ value: "Cuttack", content: 'Cuttack' },
{ value: "Kochi", content: 'Kochi' },
{ value: "Udaipur", content: 'Udaipur' },
{ value: "Bhavnagar", content: 'Bhavnagar' },
{ value: "Dehradun", content: 'Dehradun' },
{ value: "Asansol", content: 'Asansol' },
{ value: "Nanded-Waghala", content: 'Nanded-Waghala' },
{ value: "Ajmer", content: 'Ajmer' },
{ value: "Jamnagar", content: 'Jamnagar' },
{ value: "Ujjain", content: 'Ujjain' },
{ value: "Sangli", content: 'Sangli' },
{ value: "Loni", content: 'Loni' },
{ value: "Jhansi", content: 'Jhansi' },
{ value: "Pondicherry", content: 'Pondicherry' },
{ value: "Nellore", content: 'Nellore' },
{ value: "Jammu", content: 'Jammu' },
{ value: "Belagavi", content: 'Belagavi' },
{ value: "Raurkela", content: 'Raurkela' },
{ value: "Mangaluru", content: 'Mangaluru' },
{ value: "Tirunelveli", content: 'Tirunelveli' },
{ value: "Malegaon", content: 'Malegaon' },
{ value: "Gaya", content: 'Gaya' },
{ value: "Tiruppur", content: 'Tiruppur' },
{ value: "Davanagere", content: 'Davanagere' },
{ value: "Kozhikode", content: 'Kozhikode' },
{ value: "Akola", content: 'Akola' },
{ value: "Kurnool", content: 'Kurnool' },
{ value: "Bokaro Steel City", content: 'Bokaro Steel City' },
{ value: "Rajahmundry", content: 'Rajahmundry' },
{ value: "Ballari", content: 'Ballari' },
{ value: "Agartala", content: 'Agartala' },
{ value: "Bhagalpur", content: 'Bhagalpur' },
{ value: "Latur", content: 'Latur' },
{ value: "Dhule", content: 'Dhule' },
{ value: "Korba", content: 'Korba' },
{ value: "Bhilwara", content: 'Bhilwara' },
{ value: "Brahmapur", content: 'Brahmapur' },
{ value: "Mysore", content: 'Mysore' },
{ value: "Muzaffarpur", content: 'Muzaffarpur' },
{ value: "Ahmednagar", content: 'Ahmednagar' },
{ value: "Kollam", content: 'Kollam' },
{ value: "Raghunathganj", content: 'Raghunathganj' },
{ value: "Bilaspur", content: 'Bilaspur' },
{ value: "Shahjahanpur", content: 'Shahjahanpur' },
{ value: "Thrissur", content: 'Thrissur' },
{ value: "Alwar", content: 'Alwar' },
{ value: "Kakinada", content: 'Kakinada' },
{ value: "Nizamabad", content: 'Nizamabad' },
{ value: "Sagar", content: 'Sagar' },
{ value: "Tumkur", content: 'Tumkur' },
{ value: "Hisar", content: 'Hisar' },
{ value: "Rohtak", content: 'Rohtak' },
{ value: "Panipat", content: 'Panipat' },
{ value: "Darbhanga", content: 'Darbhanga' },
{ value: "Kharagpur", content: 'Kharagpur' },
{ value: "Aizawl", content: 'Aizawl' },
{ value: "Ichalkaranji", content: 'Ichalkaranji' },
{ value: "Tirupati", content: 'Tirupati' },
{ value: "Karnal", content: 'Karnal' },
{ value: "Bathinda", content: 'Bathinda' },
{ value: "Rampur", content: 'Rampur' },
{ value: "Shivamogga", content: 'Shivamogga' },
{ value: "Ratlam", content: 'Ratlam' },
{ value: "Modinagar", content: 'Modinagar' },
{ value: "Durg", content: 'Durg' },
{ value: "Shillong", content: 'Shillong' },
{ value: "Imphal", content: 'Imphal' },
{ value: "Hapur", content: 'Hapur' },
{ value: "Ranipet", content: 'Ranipet' },
{ value: "Anantapur", content: 'Anantapur' },
{ value: "Arrah", content: 'Arrah' },
{ value: "Karimnagar", content: 'Karimnagar' },
{ value: "Parbhani", content: 'Parbhani' },
{ value: "Etawah", content: 'Etawah' },
{ value: "Bharatpur", content: 'Bharatpur' },
{ value: "Begusarai", content: 'Begusarai' },
{ value: "New Delhi", content: 'New Delhi' },
{ value: "Chhapra", content: 'Chhapra' },
{ value: "Kadapa", content: 'Kadapa' },
{ value: "Ramagundam", content: 'Ramagundam' },
{ value: "Satna", content: 'Satna' },
{ value: "Vizianagaram", content: 'Vizianagaram' },
{ value: "Katihar", content: 'Katihar' },
{ value: "Hardwar", content: 'Hardwar' },
{ value: "Sonipat", content: 'Sonipat' },
{ value: "Nagercoil", content: 'Nagercoil' },
{ value: "Thanjavur", content: 'Thanjavur' },
{ value: "Murwara (Katni)", content: 'Murwara (Katni)' },
{ value: "Naihati", content: 'Naihati' },
{ value: "Sambhal", content: 'Sambhal' },
{ value: "Nadiad", content: 'Nadiad' },
{ value: "Yamunanagar", content: 'Yamunanagar' },
{ value: "English Bazar", content: 'English Bazar' },
{ value: "Eluru", content: 'Eluru' },
{ value: "Munger", content: 'Munger' },
{ value: "Panchkula", content: 'Panchkula' },
{ value: "Raayachuru", content: 'Raayachuru' },
{ value: "Panvel", content: 'Panvel' },
{ value: "Deoghar", content: 'Deoghar' },
{ value: "Ongole", content: 'Ongole' },
{ value: "Nandyal", content: 'Nandyal' },
{ value: "Morena", content: 'Morena' },
{ value: "Bhiwani", content: 'Bhiwani' },
{ value: "Porbandar", content: 'Porbandar' },
{ value: "Palakkad", content: 'Palakkad' },
{ value: "Anand", content: 'Anand' },
{ value: "Purnia", content: 'Purnia' },
{ value: "Baharampur", content: 'Baharampur' },
{ value: "Barmer", content: 'Barmer' },
{ value: "Morvi", content: 'Morvi' },
{ value: "Orai", content: 'Orai' },
{ value: "Bahraich", content: 'Bahraich' },
{ value: "Sikar", content: 'Sikar' },
{ value: "Vellore", content: 'Vellore' },
{ value: "Singrauli", content: 'Singrauli' },
{ value: "Khammam", content: 'Khammam' },
{ value: "Mahesana", content: 'Mahesana' },
{ value: "Silchar", content: 'Silchar' },
{ value: "Sambalpur", content: 'Sambalpur' },
{ value: "Rewa", content: 'Rewa' },
{ value: "Unnao", content: 'Unnao' },
{ value: "Hugli-Chinsurah", content: 'Hugli-Chinsurah' },
{ value: "Raiganj", content: 'Raiganj' },
{ value: "Phusro", content: 'Phusro' },
{ value: "Adityapur", content: 'Adityapur' },
{ value: "Alappuzha", content: 'Alappuzha' },
{ value: "Bahadurgarh", content: 'Bahadurgarh' },
{ value: "Machilipatnam", content: 'Machilipatnam' },
{ value: "Rae Bareli", content: 'Rae Bareli' },
{ value: "Jalpaiguri", content: 'Jalpaiguri' },
{ value: "Bharuch", content: 'Bharuch' },
{ value: "Pathankot", content: 'Pathankot' },
{ value: "Hoshiarpur", content: 'Hoshiarpur' },
{ value: "Baramula", content: 'Baramula' },
{ value: "Adoni", content: 'Adoni' },
{ value: "Jind", content: 'Jind' },
{ value: "Tonk", content: 'Tonk' },
{ value: "Tenali", content: 'Tenali' },
{ value: "Kancheepuram", content: 'Kancheepuram' },
{ value: "Sirsa", content: 'Sirsa' },
{ value: "Navsari", content: 'Navsari' },
{ value: "Mahbubnagar", content: 'Mahbubnagar' },
{ value: "Puri", content: 'Puri' },
{ value: "Robertson Pet", content: 'Robertson Pet' },
{ value: "Erode", content: 'Erode' },
{ value: "Batala", content: 'Batala' },
{ value: "Haldwani-cum-Kathgodam", content: 'Haldwani-cum-Kathgodam' },
{ value: "Vidisha", content: 'Vidisha' },
{ value: "Saharsa", content: 'Saharsa' },
{ value: "Thanesar", content: 'Thanesar' },
{ value: "Chittoor", content: 'Chittoor' },
{ value: "Veraval", content: 'Veraval' },
{ value: "Lakhimpur", content: 'Lakhimpur' },
{ value: "Sitapur", content: 'Sitapur' },
{ value: "Hindupur", content: 'Hindupur' },
{ value: "Santipur", content: 'Santipur' },
{ value: "Balurghat", content: 'Balurghat' },
{ value: "Ganjbasoda", content: 'Ganjbasoda' },
{ value: "Moga", content: 'Moga' },
{ value: "Proddatur", content: 'Proddatur' },
{ value: "Srinagar", content: 'Srinagar' },
{ value: "Medinipur", content: 'Medinipur' },
{ value: "Habra", content: 'Habra' },
{ value: "Sasaram", content: 'Sasaram' },
{ value: "Hajipur", content: 'Hajipur' },
{ value: "Bhuj", content: 'Bhuj' },
{ value: "Shivpuri", content: 'Shivpuri' },
{ value: "Ranaghat", content: 'Ranaghat' },
{ value: "Shimla", content: 'Shimla' },
{ value: "Tiruvannamalai", content: 'Tiruvannamalai' },
{ value: "Kaithal", content: 'Kaithal' },
{ value: "Rajnandgaon", content: 'Rajnandgaon' },
{ value: "Godhra", content: 'Godhra' },
{ value: "Hazaribag", content: 'Hazaribag' },
{ value: "Bhimavaram", content: 'Bhimavaram' },
{ value: "Mandsaur", content: 'Mandsaur' },
{ value: "Dibrugarh", content: 'Dibrugarh' },
{ value: "Kolar", content: 'Kolar' },
{ value: "Bankura", content: 'Bankura' },
{ value: "Mandya", content: 'Mandya' },
{ value: "Dehri-on-Sone", content: 'Dehri-on-Sone' },
{ value: "Madanapalle", content: 'Madanapalle' },
{ value: "Malerkotla", content: 'Malerkotla' },
{ value: "Lalitpur", content: 'Lalitpur' },
{ value: "Bettiah", content: 'Bettiah' },
{ value: "Pollachi", content: 'Pollachi' },
{ value: "Khanna", content: 'Khanna' },
{ value: "Neemuch", content: 'Neemuch' },
{ value: "Palwal", content: 'Palwal' },
{ value: "Palanpur", content: 'Palanpur' },
{ value: "Guntakal", content: 'Guntakal' },
{ value: "Nabadwip", content: 'Nabadwip' },
{ value: "Udupi", content: 'Udupi' },
{ value: "Jagdalpur", content: 'Jagdalpur' },
{ value: "Motihari", content: 'Motihari' },
{ value: "Pilibhit", content: 'Pilibhit' },
{ value: "Dimapur", content: 'Dimapur' },
{ value: "Mohali", content: 'Mohali' },
{ value: "Sadulpur", content: 'Sadulpur' },
{ value: "Rajapalayam", content: 'Rajapalayam' },
{ value: "Dharmavaram", content: 'Dharmavaram' },
{ value: "Kashipur", content: 'Kashipur' },
{ value: "Sivakasi", content: 'Sivakasi' },
{ value: "Darjiling", content: 'Darjiling' },
{ value: "Chikkamagaluru", content: 'Chikkamagaluru' },
{ value: "Gudivada", content: 'Gudivada' },
{ value: "Baleshwar Town", content: 'Baleshwar Town' },
{ value: "Mancherial", content: 'Mancherial' },
{ value: "Srikakulam", content: 'Srikakulam' },
{ value: "Adilabad", content: 'Adilabad' },
{ value: "Yavatmal", content: 'Yavatmal' },
{ value: "Barnala", content: 'Barnala' },
{ value: "Nagaon", content: 'Nagaon' },
{ value: "Narasaraopet", content: 'Narasaraopet' },
{ value: "Raigarh", content: 'Raigarh' },
{ value: "Roorkee", content: 'Roorkee' },
{ value: "Valsad", content: 'Valsad' },
{ value: "Ambikapur", content: 'Ambikapur' },
{ value: "Giridih", content: 'Giridih' },
{ value: "Chandausi", content: 'Chandausi' },
{ value: "Purulia", content: 'Purulia' },
{ value: "Patan", content: 'Patan' },
{ value: "Bagaha", content: 'Bagaha' },
{ value: "Hardoi ", content: 'Hardoi ' },
{ value: "Achalpur", content: 'Achalpur' },
{ value: "Osmanabad", content: 'Osmanabad' },
{ value: "Deesa", content: 'Deesa' },
{ value: "Nandurbar", content: 'Nandurbar' },
{ value: "Azamgarh", content: 'Azamgarh' },
{ value: "Ramgarh", content: 'Ramgarh' },
{ value: "Firozpur", content: 'Firozpur' },
{ value: "Baripada Town", content: 'Baripada Town' },
{ value: "Karwar", content: 'Karwar' },
{ value: "Siwan", content: 'Siwan' },
{ value: "Rajampet", content: 'Rajampet' },
{ value: "Pudukkottai", content: 'Pudukkottai' },
{ value: "Anantnag", content: 'Anantnag' },
{ value: "Tadpatri", content: 'Tadpatri' },
{ value: "Satara", content: 'Satara' },
{ value: "Bhadrak", content: 'Bhadrak' },
{ value: "Kishanganj", content: 'Kishanganj' },
{ value: "Suryapet", content: 'Suryapet' },
{ value: "Wardha", content: 'Wardha' },
{ value: "Ranebennuru", content: 'Ranebennuru' },
{ value: "Amreli", content: 'Amreli' },
{ value: "Neyveli (TS)", content: 'Neyveli (TS)' },
{ value: "Jamalpur", content: 'Jamalpur' },
{ value: "Marmagao", content: 'Marmagao' },
{ value: "Udgir", content: 'Udgir' },
{ value: "Tadepalligudem", content: 'Tadepalligudem' },
{ value: "Nagapattinam", content: 'Nagapattinam' },
{ value: "Buxar", content: 'Buxar' },
{ value: "Jehanabad", content: 'Jehanabad' },
{ value: "Phagwara", content: 'Phagwara' },
{ value: "Khair", content: 'Khair' },
{ value: "Sawai Madhopur", content: 'Sawai Madhopur' },
{ value: "Kapurthala", content: 'Kapurthala' },
{ value: "Chilakaluripet", content: 'Chilakaluripet' },
{ value: "Aurangabad", content: 'Aurangabad' },
{ value: "Malappuram", content: 'Malappuram' },
{ value: "Rewari", content: 'Rewari' },
{ value: "Nagaur", content: 'Nagaur' },
{ value: "Sultanpur", content: 'Sultanpur' },
{ value: "Nagda", content: 'Nagda' },
{ value: "Port Blair", content: 'Port Blair' },
{ value: "Lakhisarai", content: 'Lakhisarai' },
{ value: "Panaji", content: 'Panaji' },
{ value: "Tinsukia", content: 'Tinsukia' },
{ value: "Itarsi", content: 'Itarsi' },
{ value: "Kohima", content: 'Kohima' },
{ value: "Balangir", content: 'Balangir' },
{ value: "Nawada", content: 'Nawada' },
{ value: "Jharsuguda", content: 'Jharsuguda' },
{ value: "Jagtial", content: 'Jagtial' },
{ value: "Viluppuram", content: 'Viluppuram' },
{ value: "Amalner", content: 'Amalner' },
{ value: "Zirakpur", content: 'Zirakpur' },
{ value: "Tanda", content: 'Tanda' },
{ value: "Tiruchengode", content: 'Tiruchengode' },
{ value: "Nagina", content: 'Nagina' },
{ value: "Yemmiganur", content: 'Yemmiganur' },
{ value: "Vaniyambadi", content: 'Vaniyambadi' },
{ value: "Sarni", content: 'Sarni' },
{ value: "Theni Allinagaram", content: 'Theni Allinagaram' },
{ value: "Margao", content: 'Margao' },
{ value: "Akot", content: 'Akot' },
{ value: "Sehore", content: 'Sehore' },
{ value: "Mhow Cantonment", content: 'Mhow Cantonment' },
{ value: "Kot Kapura", content: 'Kot Kapura' },
{ value: "Makrana", content: 'Makrana' },
{ value: "Pandharpur", content: 'Pandharpur' },
{ value: "Miryalaguda", content: 'Miryalaguda' },
{ value: "Shamli", content: 'Shamli' },
{ value: "Seoni", content: 'Seoni' },
{ value: "Ranibennur", content: 'Ranibennur' },
{ value: "Kadiri", content: 'Kadiri' },
{ value: "Shrirampur", content: 'Shrirampur' },
{ value: "Parli", content: 'Parli' },
{ value: "Najibabad", content: 'Najibabad' },
{ value: "Nirmal", content: 'Nirmal' },
{ value: "Udhagamandalam", content: 'Udhagamandalam' },
{ value: "Shikohabad", content: 'Shikohabad' },
{ value: "Jhumri Tilaiya", content: 'Jhumri Tilaiya' },
{ value: "Aruppukkottai", content: 'Aruppukkottai' },
{ value: "Ponnani", content: 'Ponnani' },
{ value: "Jamui", content: 'Jamui' },
{ value: "Sitamarhi", content: 'Sitamarhi' },
{ value: "Chirala", content: 'Chirala' },
{ value: "Anjar", content: 'Anjar' },
{ value: "Karaikal", content: 'Karaikal' },
{ value: "Hansi", content: 'Hansi' },
{ value: "Anakapalle", content: 'Anakapalle' },
{ value: "Mahasamund", content: 'Mahasamund' },
{ value: "Faridkot", content: 'Faridkot' },
{ value: "Saunda", content: 'Saunda' },
{ value: "Dhoraji", content: 'Dhoraji' },
{ value: "Paramakudi", content: 'Paramakudi' },
{ value: "Balaghat", content: 'Balaghat' },
{ value: "Sujangarh", content: 'Sujangarh' },
{ value: "Khambhat", content: 'Khambhat' },
{ value: "Muktsar", content: 'Muktsar' },
{ value: "Rajpura", content: 'Rajpura' },
{ value: "Kavali", content: 'Kavali' },
{ value: "Dhamtari", content: 'Dhamtari' },
{ value: "Ashok Nagar", content: 'Ashok Nagar' },
{ value: "Sardarshahar", content: 'Sardarshahar' },
{ value: "Mahuva", content: 'Mahuva' },
{ value: "Bargarh", content: 'Bargarh' },
{ value: "Kamareddy", content: 'Kamareddy' },
{ value: "Sahibganj", content: 'Sahibganj' },
{ value: "Kothagudem", content: 'Kothagudem' },
{ value: "Ramanagaram", content: 'Ramanagaram' },
{ value: "Gokak", content: 'Gokak' },
{ value: "Tikamgarh", content: 'Tikamgarh' },
{ value: "Araria", content: 'Araria' },
{ value: "Rishikesh", content: 'Rishikesh' },
{ value: "Shahdol", content: 'Shahdol' },
{ value: "Medininagar (Daltonganj)", content: 'Medininagar (Daltonganj)' },
{ value: "Arakkonam", content: 'Arakkonam' },
{ value: "Washim", content: 'Washim' },
{ value: "Sangrur", content: 'Sangrur' },
{ value: "Bodhan", content: 'Bodhan' },
{ value: "Fazilka", content: 'Fazilka' },
{ value: "Palacole", content: 'Palacole' },
{ value: "Keshod", content: 'Keshod' },
{ value: "Sullurpeta", content: 'Sullurpeta' },
{ value: "Wadhwan", content: 'Wadhwan' },
{ value: "Gurdaspur", content: 'Gurdaspur' },
{ value: "Vatakara", content: 'Vatakara' },
{ value: "Tura", content: 'Tura' },
{ value: "Narnaul", content: 'Narnaul' },
{ value: "Kharar", content: 'Kharar' },
{ value: "Yadgir", content: 'Yadgir' },
{ value: "Ambejogai", content: 'Ambejogai' },
{ value: "Ankleshwar", content: 'Ankleshwar' },
{ value: "Savarkundla", content: 'Savarkundla' },
{ value: "Paradip", content: 'Paradip' },
{ value: "Virudhachalam", content: 'Virudhachalam' },
{ value: "Kanhangad", content: 'Kanhangad' },
{ value: "Kadi", content: 'Kadi' },
{ value: "Srivilliputhur", content: 'Srivilliputhur' },
{ value: "Gobindgarh", content: 'Gobindgarh' },
{ value: "Tindivanam", content: 'Tindivanam' },
{ value: "Mansa", content: 'Mansa' },
{ value: "Taliparamba", content: 'Taliparamba' },
{ value: "Manmad", content: 'Manmad' },
{ value: "Tanuku", content: 'Tanuku' },
{ value: "Rayachoti", content: 'Rayachoti' },
{ value: "Virudhunagar", content: 'Virudhunagar' },
{ value: "Koyilandy", content: 'Koyilandy' },
{ value: "Jorhat", content: 'Jorhat' },
{ value: "Karur", content: 'Karur' },
{ value: "Valparai", content: 'Valparai' },
{ value: "Srikalahasti", content: 'Srikalahasti' },
{ value: "Neyyattinkara", content: 'Neyyattinkara' },
{ value: "Bapatla", content: 'Bapatla' },
{ value: "Fatehabad", content: 'Fatehabad' },
{ value: "Malout", content: 'Malout' },
{ value: "Sankarankovil", content: 'Sankarankovil' },
{ value: "Tenkasi", content: 'Tenkasi' },
{ value: "Ratnagiri", content: 'Ratnagiri' },
{ value: "Rabkavi Banhatti", content: 'Rabkavi Banhatti' },
{ value: "Sikandrabad", content: 'Sikandrabad' },
{ value: "Chaibasa", content: 'Chaibasa' },
{ value: "Chirmiri", content: 'Chirmiri' },
{ value: "Palwancha", content: 'Palwancha' },
{ value: "Bhawanipatna", content: 'Bhawanipatna' },
{ value: "Kayamkulam", content: 'Kayamkulam' },
{ value: "Pithampur", content: 'Pithampur' },
{ value: "Nabha", content: 'Nabha' },
{ value: "Shahabad, Hardoi", content: 'Shahabad, Hardoi' },
{ value: "Dhenkanal", content: 'Dhenkanal' },
{ value: "Uran Islampur", content: 'Uran Islampur' },
{ value: "Gopalganj", content: 'Gopalganj' },
{ value: "Bongaigaon City", content: 'Bongaigaon City' },
{ value: "Palani", content: 'Palani' },
{ value: "Pusad", content: 'Pusad' },
{ value: "Sopore", content: 'Sopore' },
{ value: "Pilkhuwa", content: 'Pilkhuwa' },
{ value: "Tarn Taran", content: 'Tarn Taran' },
{ value: "Renukoot", content: 'Renukoot' },
{ value: "Mandamarri", content: 'Mandamarri' },
{ value: "Shahabad", content: 'Shahabad' },
{ value: "Barbil", content: 'Barbil' },
{ value: "Koratla", content: 'Koratla' },
{ value: "Madhubani", content: 'Madhubani' },
{ value: "Arambagh", content: 'Arambagh' },
{ value: "Gohana", content: 'Gohana' },
{ value: "Ladnu", content: 'Ladnu' },
{ value: "Pattukkottai", content: 'Pattukkottai' },
{ value: "Sirsi", content: 'Sirsi' },
{ value: "Sircilla", content: 'Sircilla' },
{ value: "Tamluk", content: 'Tamluk' },
{ value: "Jagraon", content: 'Jagraon' },
{ value: "AlipurdUrban Agglomerationr", content: 'AlipurdUrban Agglomerationr' },
{ value: "Alirajpur", content: 'Alirajpur' },
{ value: "Tandur", content: 'Tandur' },
{ value: "Naidupet", content: 'Naidupet' },
{ value: "Tirupathur", content: 'Tirupathur' },
{ value: "Tohana", content: 'Tohana' },
{ value: "Ratangarh", content: 'Ratangarh' },
{ value: "Dhubri", content: 'Dhubri' },
{ value: "Masaurhi", content: 'Masaurhi' },
{ value: "Visnagar", content: 'Visnagar' },
{ value: "Vrindavan", content: 'Vrindavan' },
{ value: "Nokha", content: 'Nokha' },
{ value: "Nagari", content: 'Nagari' },
{ value: "Narwana", content: 'Narwana' },
{ value: "Ramanathapuram", content: 'Ramanathapuram' },
{ value: "Ujhani", content: 'Ujhani' },
{ value: "Samastipur", content: 'Samastipur' },
{ value: "Laharpur", content: 'Laharpur' },
{ value: "Sangamner", content: 'Sangamner' },
{ value: "Nimbahera", content: 'Nimbahera' },
{ value: "Siddipet", content: 'Siddipet' },
{ value: "Suri", content: 'Suri' },
{ value: "Diphu", content: 'Diphu' },
{ value: "Jhargram", content: 'Jhargram' },
{ value: "Shirpur-Warwade", content: 'Shirpur-Warwade' },
{ value: "Tilhar", content: 'Tilhar' },
{ value: "Sindhnur", content: 'Sindhnur' },
{ value: "Udumalaipettai", content: 'Udumalaipettai' },
{ value: "Malkapur", content: 'Malkapur' },
{ value: "Wanaparthy", content: 'Wanaparthy' },
{ value: "Gudur", content: 'Gudur' },
{ value: "Kendujhar", content: 'Kendujhar' },
{ value: "Mandla", content: 'Mandla' },
{ value: "Mandi", content: 'Mandi' },
{ value: "Nedumangad", content: 'Nedumangad' },
{ value: "North Lakhimpur", content: 'North Lakhimpur' },
{ value: "Vinukonda", content: 'Vinukonda' },
{ value: "Tiptur", content: 'Tiptur' },
{ value: "Gobichettipalayam", content: 'Gobichettipalayam' },
{ value: "Sunabeda", content: 'Sunabeda' },
{ value: "Wani", content: 'Wani' },
{ value: "Upleta", content: 'Upleta' },
{ value: "Narasapuram", content: 'Narasapuram' },
{ value: "Nuzvid", content: 'Nuzvid' },
{ value: "Tezpur", content: 'Tezpur' },
{ value: "Una", content: 'Una' },
{ value: "Markapur", content: 'Markapur' },
{ value: "Sheopur", content: 'Sheopur' },
{ value: "Thiruvarur", content: 'Thiruvarur' },
{ value: "Sidhpur", content: 'Sidhpur' },
{ value: "Sahaswan", content: 'Sahaswan' },
{ value: "Suratgarh", content: 'Suratgarh' },
{ value: "Shajapur", content: 'Shajapur' },
{ value: "Rayagada", content: 'Rayagada' },
{ value: "Lonavla", content: 'Lonavla' },
{ value: "Ponnur", content: 'Ponnur' },
{ value: "Kagaznagar", content: 'Kagaznagar' },
{ value: "Gadwal", content: 'Gadwal' },
{ value: "Bhatapara", content: 'Bhatapara' },
{ value: "Kandukur", content: 'Kandukur' },
{ value: "Sangareddy", content: 'Sangareddy' },
{ value: "Unjha", content: 'Unjha' },
{ value: "Lunglei", content: 'Lunglei' },
{ value: "Karimganj", content: 'Karimganj' },
{ value: "Kannur", content: 'Kannur' },
{ value: "Bobbili", content: 'Bobbili' },
{ value: "Mokameh", content: 'Mokameh' },
{ value: "Talegaon Dabhade", content: 'Talegaon Dabhade' },
{ value: "Anjangaon", content: 'Anjangaon' },
{ value: "Sunam", content: 'Sunam' },
{ value: "Gangarampur", content: 'Gangarampur' },
{ value: "Thiruvallur", content: 'Thiruvallur' },
{ value: "Tirur", content: 'Tirur' },
{ value: "Rath", content: 'Rath' },
{ value: "Jatani", content: 'Jatani' },
{ value: "Viramgam", content: 'Viramgam' },
{ value: "Rajsamand", content: 'Rajsamand' },
{ value: "Yanam", content: 'Yanam' },
{ value: "Kottayam", content: 'Kottayam' },
{ value: "Panruti", content: 'Panruti' },
{ value: "Dhuri", content: 'Dhuri' },
{ value: "Namakkal", content: 'Namakkal' },
{ value: "Kasaragod", content: 'Kasaragod' },
{ value: "Modasa", content: 'Modasa' },
{ value: "Rayadurg", content: 'Rayadurg' },
{ value: "Supaul", content: 'Supaul' },
{ value: "Kunnamkulam", content: 'Kunnamkulam' },
{ value: "Umred", content: 'Umred' },
{ value: "Bellampalle", content: 'Bellampalle' },
{ value: "Sibsagar", content: 'Sibsagar' },
{ value: "Mandi Dabwali", content: 'Mandi Dabwali' },
{ value: "Ottappalam", content: 'Ottappalam' },
{ value: "Dumraon", content: 'Dumraon' },
{ value: "Samalkot", content: 'Samalkot' },
{ value: "Jaggaiahpet", content: 'Jaggaiahpet' },
{ value: "Goalpara", content: 'Goalpara' },
{ value: "Tuni", content: 'Tuni' },
{ value: "Lachhmangarh", content: 'Lachhmangarh' },
{ value: "Bhongir", content: 'Bhongir' },
{ value: "Amalapuram", content: 'Amalapuram' },
{ value: "Firozpur Cantt.", content: 'Firozpur Cantt.' },
{ value: "Vikarabad", content: 'Vikarabad' },
{ value: "Thiruvalla", content: 'Thiruvalla' },
{ value: "Sherkot", content: 'Sherkot' },
{ value: "Palghar", content: 'Palghar' },
{ value: "Shegaon", content: 'Shegaon' },
{ value: "Jangaon", content: 'Jangaon' },
{ value: "Bheemunipatnam", content: 'Bheemunipatnam' },
{ value: "Panna", content: 'Panna' },
{ value: "Thodupuzha", content: 'Thodupuzha' },
{ value: "KathUrban Agglomeration", content: 'KathUrban Agglomeration' },
{ value: "Palitana", content: 'Palitana' },
{ value: "Arwal", content: 'Arwal' },
{ value: "Venkatagiri", content: 'Venkatagiri' },
{ value: "Kalpi", content: 'Kalpi' },
{ value: "Rajgarh (Churu)", content: 'Rajgarh (Churu)' },
{ value: "Sattenapalle", content: 'Sattenapalle' },
{ value: "Arsikere", content: 'Arsikere' },
{ value: "Ozar", content: 'Ozar' },
{ value: "Thirumangalam", content: 'Thirumangalam' },
{ value: "Petlad", content: 'Petlad' },
{ value: "Nasirabad", content: 'Nasirabad' },
{ value: "Phaltan", content: 'Phaltan' },
{ value: "Rampurhat", content: 'Rampurhat' },
{ value: "Nanjangud", content: 'Nanjangud' },
{ value: "Forbesganj", content: 'Forbesganj' },
{ value: "Tundla", content: 'Tundla' },
{ value: "BhabUrban Agglomeration", content: 'BhabUrban Agglomeration' },
{ value: "Sagara", content: 'Sagara' },
{ value: "Pithapuram", content: 'Pithapuram' },
{ value: "Sira", content: 'Sira' },
{ value: "Bhadrachalam", content: 'Bhadrachalam' },
{ value: "Charkhi Dadri", content: 'Charkhi Dadri' },
{ value: "Chatra", content: 'Chatra' },
{ value: "Palasa Kasibugga", content: 'Palasa Kasibugga' },
{ value: "Nohar", content: 'Nohar' },
{ value: "Yevla", content: 'Yevla' },
{ value: "Sirhind Fatehgarh Sahib", content: 'Sirhind Fatehgarh Sahib' },
{ value: "Bhainsa", content: 'Bhainsa' },
{ value: "Parvathipuram", content: 'Parvathipuram' },
{ value: "Shahade", content: 'Shahade' },
{ value: "Chalakudy", content: 'Chalakudy' },
{ value: "Narkatiaganj", content: 'Narkatiaganj' },
{ value: "Kapadvanj", content: 'Kapadvanj' },
{ value: "Macherla", content: 'Macherla' },
{ value: "Raghogarh-Vijaypur", content: 'Raghogarh-Vijaypur' },
{ value: "Rupnagar", content: 'Rupnagar' },
{ value: "Naugachhia", content: 'Naugachhia' },
{ value: "Sendhwa", content: 'Sendhwa' },
{ value: "Byasanagar", content: 'Byasanagar' },
{ value: "Sandila", content: 'Sandila' },
{ value: "Gooty", content: 'Gooty' },
{ value: "Salur", content: 'Salur' },
{ value: "Nanpara", content: 'Nanpara' },
{ value: "Sardhana", content: 'Sardhana' },
{ value: "Vita", content: 'Vita' },
{ value: "Gumia", content: 'Gumia' },
{ value: "Jalandhar Cantt.", content: 'Jalandhar Cantt.' },
{ value: "Nehtaur", content: 'Nehtaur' },
{ value: "Changanassery", content: 'Changanassery' },
{ value: "Mandapeta", content: 'Mandapeta' },
{ value: "Dumka", content: 'Dumka' },
{ value: "Seohara", content: 'Seohara' },
{ value: "Umarkhed", content: 'Umarkhed' },
{ value: "Madhupur", content: 'Madhupur' },
{ value: "Vikramasingapuram", content: 'Vikramasingapuram' },
{ value: "Punalur", content: 'Punalur' },
{ value: "Kendrapara", content: 'Kendrapara' },
{ value: "Sihor", content: 'Sihor' },
{ value: "Nellikuppam", content: 'Nellikuppam' },
{ value: "Samana", content: 'Samana' },
{ value: "Warora", content: 'Warora' },
{ value: "Nilambur", content: 'Nilambur' },
{ value: "Rasipuram", content: 'Rasipuram' },
{ value: "Jammalamadugu", content: 'Jammalamadugu' },
{ value: "Nawanshahr", content: 'Nawanshahr' },
{ value: "Thoubal", content: 'Thoubal' },
{ value: "Athni", content: 'Athni' },
{ value: "Cherthala", content: 'Cherthala' },
{ value: "Sidhi", content: 'Sidhi' },
{ value: "Farooqnagar", content: 'Farooqnagar' },
{ value: "Peddapuram", content: 'Peddapuram' },
{ value: "Chirkunda", content: 'Chirkunda' },
{ value: "Pachora", content: 'Pachora' },
{ value: "Madhepura", content: 'Madhepura' },
{ value: "Pithoragarh", content: 'Pithoragarh' },
{ value: "Tumsar", content: 'Tumsar' },
{ value: "Phalodi", content: 'Phalodi' },
{ value: "Tiruttani", content: 'Tiruttani' },
{ value: "Rampura Phul", content: 'Rampura Phul' },
{ value: "Perinthalmanna", content: 'Perinthalmanna' },
{ value: "Padrauna", content: 'Padrauna' },
{ value: "Pipariya", content: 'Pipariya' },
{ value: "Dalli-Rajhara", content: 'Dalli-Rajhara' },
{ value: "Punganur", content: 'Punganur' },
{ value: "Mattannur", content: 'Mattannur' },
{ value: "Mathura", content: 'Mathura' },
{ value: "Thakurdwara", content: 'Thakurdwara' },
{ value: "Nandivaram-Guduvancheri", content: 'Nandivaram-Guduvancheri' },
{ value: "Mulbagal", content: 'Mulbagal' },
{ value: "Manjlegaon", content: 'Manjlegaon' },
{ value: "Wankaner", content: 'Wankaner' },
{ value: "Sillod", content: 'Sillod' },
{ value: "Nidadavole", content: 'Nidadavole' },
{ value: "Surapura", content: 'Surapura' },
{ value: "Rajagangapur", content: 'Rajagangapur' },
{ value: "Sheikhpura", content: 'Sheikhpura' },
{ value: "Parlakhemundi", content: 'Parlakhemundi' },
{ value: "Kalimpong", content: 'Kalimpong' },
{ value: "Siruguppa", content: 'Siruguppa' },
{ value: "Arvi", content: 'Arvi' },
{ value: "Limbdi", content: 'Limbdi' },
{ value: "Barpeta", content: 'Barpeta' },
{ value: "Manglaur", content: 'Manglaur' },
{ value: "Repalle", content: 'Repalle' },
{ value: "Mudhol", content: 'Mudhol' },
{ value: "Shujalpur", content: 'Shujalpur' },
{ value: "Mandvi", content: 'Mandvi' },
{ value: "Thangadh", content: 'Thangadh' },
{ value: "Sironj", content: 'Sironj' },
{ value: "Nandura", content: 'Nandura' },
{ value: "Shoranur", content: 'Shoranur' },
{ value: "Nathdwara", content: 'Nathdwara' },
{ value: "Periyakulam", content: 'Periyakulam' },
{ value: "Sultanganj", content: 'Sultanganj' },
{ value: "Medak", content: 'Medak' },
{ value: "Narayanpet", content: 'Narayanpet' },
{ value: "Raxaul Bazar", content: 'Raxaul Bazar' },
{ value: "Rajauri", content: 'Rajauri' },
{ value: "Pernampattu", content: 'Pernampattu' },
{ value: "Nainital", content: 'Nainital' },
{ value: "Ramachandrapuram", content: 'Ramachandrapuram' },
{ value: "Vaijapur", content: 'Vaijapur' },
{ value: "Nangal", content: 'Nangal' },
{ value: "Sidlaghatta", content: 'Sidlaghatta' },
{ value: "Punch", content: 'Punch' },
{ value: "Pandhurna", content: 'Pandhurna' },
{ value: "Wadgaon Road", content: 'Wadgaon Road' },
{ value: "Talcher", content: 'Talcher' },
{ value: "Varkala", content: 'Varkala' },
{ value: "Pilani", content: 'Pilani' },
{ value: "Nowgong", content: 'Nowgong' },
{ value: "Naila Janjgir", content: 'Naila Janjgir' },
{ value: "Mapusa", content: 'Mapusa' },
{ value: "Vellakoil", content: 'Vellakoil' },
{ value: "Merta City", content: 'Merta City' },
{ value: "Sivaganga", content: 'Sivaganga' },
{ value: "Mandideep", content: 'Mandideep' },
{ value: "Sailu", content: 'Sailu' },
{ value: "Vyara", content: 'Vyara' },
{ value: "Kovvur", content: 'Kovvur' },
{ value: "Vadalur", content: 'Vadalur' },
{ value: "Nawabganj", content: 'Nawabganj' },
{ value: "Padra", content: 'Padra' },
{ value: "Sainthia", content: 'Sainthia' },
{ value: "Siana", content: 'Siana' },
{ value: "Shahpur", content: 'Shahpur' },
{ value: "Sojat", content: 'Sojat' },
{ value: "Noorpur", content: 'Noorpur' },
{ value: "Paravoor", content: 'Paravoor' },
{ value: "Murtijapur", content: 'Murtijapur' },
{ value: "Ramnagar", content: 'Ramnagar' },
{ value: "Sundargarh", content: 'Sundargarh' },
{ value: "Taki", content: 'Taki' },
{ value: "Saundatti-Yellamma", content: 'Saundatti-Yellamma' },
{ value: "Pathanamthitta", content: 'Pathanamthitta' },
{ value: "Wadi", content: 'Wadi' },
{ value: "Rameshwaram", content: 'Rameshwaram' },
{ value: "Tasgaon", content: 'Tasgaon' },
{ value: "Sikandra Rao", content: 'Sikandra Rao' },
{ value: "Sihora", content: 'Sihora' },
{ value: "Tiruvethipuram", content: 'Tiruvethipuram' },
{ value: "Tiruvuru", content: 'Tiruvuru' },
{ value: "Mehkar", content: 'Mehkar' },
{ value: "Peringathur", content: 'Peringathur' },
{ value: "Perambalur", content: 'Perambalur' },
{ value: "Manvi", content: 'Manvi' },
{ value: "Zunheboto", content: 'Zunheboto' },
{ value: "Mahnar Bazar", content: 'Mahnar Bazar' },
{ value: "Attingal", content: 'Attingal' },
{ value: "Shahbad", content: 'Shahbad' },
{ value: "Puranpur", content: 'Puranpur' },
{ value: "Nelamangala", content: 'Nelamangala' },
{ value: "Nakodar", content: 'Nakodar' },
{ value: "Lunawada", content: 'Lunawada' },
{ value: "Murshidabad", content: 'Murshidabad' },
{ value: "Mahe", content: 'Mahe' },
{ value: "Lanka", content: 'Lanka' },
{ value: "Rudauli", content: 'Rudauli' },
{ value: "Tuensang", content: 'Tuensang' },
{ value: "Lakshmeshwar", content: 'Lakshmeshwar' },
{ value: "Zira", content: 'Zira' },
{ value: "Yawal", content: 'Yawal' },
{ value: "Thana Bhawan", content: 'Thana Bhawan' },
{ value: "Ramdurg", content: 'Ramdurg' },
{ value: "Pulgaon", content: 'Pulgaon' },
{ value: "Sadasivpet", content: 'Sadasivpet' },
{ value: "Nargund", content: 'Nargund' },
{ value: "Neem-Ka-Thana", content: 'Neem-Ka-Thana' },
{ value: "Memari", content: 'Memari' },
{ value: "Nilanga", content: 'Nilanga' },
{ value: "Naharlagun", content: 'Naharlagun' },
{ value: "Pakaur", content: 'Pakaur' },
{ value: "Wai", content: 'Wai' },
{ value: "Tarikere", content: 'Tarikere' },
{ value: "Malavalli", content: 'Malavalli' },
{ value: "Raisen", content: 'Raisen' },
{ value: "Lahar", content: 'Lahar' },
{ value: "Uravakonda", content: 'Uravakonda' },
{ value: "Savanur", content: 'Savanur' },
{ value: "Sirohi", content: 'Sirohi' },
{ value: "Udhampur", content: 'Udhampur' },
{ value: "Umarga", content: 'Umarga' },
{ value: "Lingsugur", content: 'Lingsugur' },
{ value: "Usilampatti", content: 'Usilampatti' },
{ value: "Palia Kalan", content: 'Palia Kalan' },
{ value: "Wokha", content: 'Wokha' },
{ value: "Rajpipla", content: 'Rajpipla' },
{ value: "Vijayapura", content: 'Vijayapura' },
{ value: "Rawatbhata", content: 'Rawatbhata' },
{ value: "Sangaria", content: 'Sangaria' },
{ value: "Paithan", content: 'Paithan' },
{ value: "Rahuri", content: 'Rahuri' },
{ value: "Patti", content: 'Patti' },
{ value: "Zaidpur", content: 'Zaidpur' },
{ value: "Lalsot", content: 'Lalsot' },
{ value: "Maihar", content: 'Maihar' },
{ value: "Vedaranyam", content: 'Vedaranyam' },
{ value: "Nawapur", content: 'Nawapur' },
{ value: "Solan", content: 'Solan' },
{ value: "Vapi", content: 'Vapi' },
{ value: "Sanawad", content: 'Sanawad' },
{ value: "Warisaliganj", content: 'Warisaliganj' },
{ value: "Revelganj", content: 'Revelganj' },
{ value: "Sabalgarh", content: 'Sabalgarh' },
{ value: "Tuljapur", content: 'Tuljapur' },
{ value: "Simdega", content: 'Simdega' },
{ value: "Musabani", content: 'Musabani' },
{ value: "Kodungallur", content: 'Kodungallur' },
{ value: "Phulabani", content: 'Phulabani' },
{ value: "Umreth", content: 'Umreth' },
{ value: "Narsipatnam", content: 'Narsipatnam' },
{ value: "Nautanwa", content: 'Nautanwa' },
{ value: "Rajgir", content: 'Rajgir' },
{ value: "Yellandu", content: 'Yellandu' },
{ value: "Sathyamangalam", content: 'Sathyamangalam' },
{ value: "Pilibanga", content: 'Pilibanga' },
{ value: "Morshi", content: 'Morshi' },
{ value: "Pehowa", content: 'Pehowa' },
{ value: "Sonepur", content: 'Sonepur' },
{ value: "Pappinisseri", content: 'Pappinisseri' },
{ value: "Zamania", content: 'Zamania' },
{ value: "Mihijam", content: 'Mihijam' },
{ value: "Purna", content: 'Purna' },
{ value: "Puliyankudi", content: 'Puliyankudi' },
{ value: "Shikarpur, Bulandshahr", content: 'Shikarpur, Bulandshahr' },
{ value: "Umaria", content: 'Umaria' },
{ value: "Porsa", content: 'Porsa' },
{ value: "Naugawan Sadat", content: 'Naugawan Sadat' },
{ value: "Fatehpur Sikri", content: 'Fatehpur Sikri' },
{ value: "Manuguru", content: 'Manuguru' },
{ value: "Pipar City", content: 'Pipar City' },
{ value: "Pattamundai", content: 'Pattamundai' },
{ value: "Nanjikottai", content: 'Nanjikottai' },
{ value: "Taranagar", content: 'Taranagar' },
{ value: "Yerraguntla", content: 'Yerraguntla' },
{ value: "Satana", content: 'Satana' },
{ value: "Sherghati", content: 'Sherghati' },
{ value: "Sankeshwara", content: 'Sankeshwara' },
{ value: "Madikeri", content: 'Madikeri' },
{ value: "Thuraiyur", content: 'Thuraiyur' },
{ value: "Sanand", content: 'Sanand' },
{ value: "Rajula", content: 'Rajula' },
{ value: "Kyathampalle", content: 'Kyathampalle' },
{ value: "Shahabad, Rampur", content: 'Shahabad, Rampur' },
{ value: "Tilda Newra", content: 'Tilda Newra' },
{ value: "Chittur-Thathamangalam", content: 'Chittur-Thathamangalam' },
{ value: "Malaj Khand", content: 'Malaj Khand' },
{ value: "Sarangpur", content: 'Sarangpur' },
{ value: "Robertsganj", content: 'Robertsganj' },
{ value: "Sirkali", content: 'Sirkali' },
{ value: "Radhanpur", content: 'Radhanpur' },
{ value: "Tiruchendur", content: 'Tiruchendur' },
{ value: "Utraula", content: 'Utraula' },
{ value: "Patratu", content: 'Patratu' },
{ value: "Vijainagar, Ajmer", content: 'Vijainagar, Ajmer' },
{ value: "Periyasemur", content: 'Periyasemur' },
{ value: "Pathri", content: 'Pathri' },
{ value: "Sadabad", content: 'Sadabad' },
{ value: "Talikota", content: 'Talikota' },
{ value: "Sinnar", content: 'Sinnar' },
{ value: "Mungeli", content: 'Mungeli' },
{ value: "Sedam", content: 'Sedam' },
{ value: "Shikaripur", content: 'Shikaripur' },
{ value: "Sattur", content: 'Sattur' },
{ value: "Sugauli", content: 'Sugauli' },
{ value: "Lumding", content: 'Lumding' },
{ value: "Vandavasi", content: 'Vandavasi' },
{ value: "Titlagarh", content: 'Titlagarh' },
{ value: "Uchgaon", content: 'Uchgaon' },
{ value: "Mokokchung", content: 'Mokokchung' },
{ value: "Paschim Punropara", content: 'Paschim Punropara' },
{ value: "Sagwara", content: 'Sagwara' },
{ value: "Ramganj Mandi", content: 'Ramganj Mandi' },
{ value: "Tarakeswar", content: 'Tarakeswar' },
{ value: "Mahalingapura", content: 'Mahalingapura' },
{ value: "Dharmanagar", content: 'Dharmanagar' },
{ value: "Mahemdabad", content: 'Mahemdabad' },
{ value: "Manendragarh", content: 'Manendragarh' },
{ value: "Uran", content: 'Uran' },
{ value: "Tharamangalam", content: 'Tharamangalam' },
{ value: "Tirukkoyilur", content: 'Tirukkoyilur' },
{ value: "Pen", content: 'Pen' },
{ value: "Makhdumpur", content: 'Makhdumpur' },
{ value: "Maner", content: 'Maner' },
{ value: "Oddanchatram", content: 'Oddanchatram' },
{ value: "Palladam", content: 'Palladam' },
{ value: "Mundi", content: 'Mundi' },
{ value: "Nabarangapur", content: 'Nabarangapur' },
{ value: "Mudalagi", content: 'Mudalagi' },
{ value: "Samalkha", content: 'Samalkha' },
{ value: "Nepanagar", content: 'Nepanagar' },
{ value: "Karjat", content: 'Karjat' },
{ value: "Ranavav", content: 'Ranavav' },
{ value: "Pedana", content: 'Pedana' },
{ value: "Pinjore", content: 'Pinjore' },
{ value: "Lakheri", content: 'Lakheri' },
{ value: "Pasan", content: 'Pasan' },
{ value: "Puttur", content: 'Puttur' },
{ value: "Vadakkuvalliyur", content: 'Vadakkuvalliyur' },
{ value: "Tirukalukundram", content: 'Tirukalukundram' },
{ value: "Mahidpur", content: 'Mahidpur' },
{ value: "Mussoorie", content: 'Mussoorie' },
{ value: "Muvattupuzha", content: 'Muvattupuzha' },
{ value: "Rasra", content: 'Rasra' },
{ value: "Udaipurwati", content: 'Udaipurwati' },
{ value: "Manwath", content: 'Manwath' },
{ value: "Adoor", content: 'Adoor' },
{ value: "Uthamapalayam", content: 'Uthamapalayam' },
{ value: "Partur", content: 'Partur' },
{ value: "Nahan", content: 'Nahan' },
{ value: "Ladwa", content: 'Ladwa' },
{ value: "Mankachar", content: 'Mankachar' },
{ value: "Nongstoin", content: 'Nongstoin' },
{ value: "Losal", content: 'Losal' },
{ value: "Sri Madhopur", content: 'Sri Madhopur' },
{ value: "Ramngarh", content: 'Ramngarh' },
{ value: "Mavelikkara", content: 'Mavelikkara' },
{ value: "Rawatsar", content: 'Rawatsar' },
{ value: "Rajakhera", content: 'Rajakhera' },
{ value: "Lar", content: 'Lar' },
{ value: "Lal Gopalganj Nindaura", content: 'Lal Gopalganj Nindaura' },
{ value: "Muddebihal", content: 'Muddebihal' },
{ value: "Sirsaganj", content: 'Sirsaganj' },
{ value: "Surandai", content: 'Surandai' },
{ value: "Sangole", content: 'Sangole' },
{ value: "Pavagada", content: 'Pavagada' },
{ value: "Tharad", content: 'Tharad' },
{ value: "Umbergaon", content: 'Umbergaon' },
{ value: "Mavoor", content: 'Mavoor' },
{ value: "Nalbari", content: 'Nalbari' },
{ value: "Talaja", content: 'Talaja' },
{ value: "Malur", content: 'Malur' },
{ value: "Mangrulpir", content: 'Mangrulpir' },
{ value: "Soro", content: 'Soro' },
{ value: "Shahpura", content: 'Shahpura' },
{ value: "Vadnagar", content: 'Vadnagar' },
{ value: "Raisinghnagar", content: 'Raisinghnagar' },
{ value: "Sindhagi", content: 'Sindhagi' },
{ value: "Sanduru", content: 'Sanduru' },
{ value: "Sohna", content: 'Sohna' },
{ value: "Manavadar", content: 'Manavadar' },
{ value: "Pihani", content: 'Pihani' },
{ value: "Safidon", content: 'Safidon' },
{ value: "Risod", content: 'Risod' },
{ value: "Rosera", content: 'Rosera' },
{ value: "Sankari", content: 'Sankari' },
{ value: "Malpura", content: 'Malpura' },
{ value: "Sonamukhi", content: 'Sonamukhi' },
{ value: "Shamsabad, Agra", content: 'Shamsabad, Agra' },
{ value: "PandUrban Agglomeration", content: 'PandUrban Agglomeration' },
{ value: "Mainaguri", content: 'Mainaguri' },
{ value: "Afzalpur", content: 'Afzalpur' },
{ value: "Shirur", content: 'Shirur' },
{ value: "Salaya", content: 'Salaya' },
{ value: "Shenkottai", content: 'Shenkottai' },
{ value: "Pratapgarh", content: 'Pratapgarh' },
{ value: "Vadipatti", content: 'Vadipatti' },
{ value: "Nagarkurnool", content: 'Nagarkurnool' },
{ value: "Savner", content: 'Savner' },
{ value: "Sasvad", content: 'Sasvad' },
{ value: "Rudrapur", content: 'Rudrapur' },
{ value: "Soron", content: 'Soron' },
{ value: "Sholingur", content: 'Sholingur' },
{ value: "Pandharkaoda", content: 'Pandharkaoda' },
{ value: "Perumbavoor", content: 'Perumbavoor' },
{ value: "Maddur", content: 'Maddur' },
{ value: "Nadbai", content: 'Nadbai' },
{ value: "Talode", content: 'Talode' },
{ value: "Shrigonda", content: 'Shrigonda' },
{ value: "Madhugiri", content: 'Madhugiri' },
{ value: "Tekkalakote", content: 'Tekkalakote' },
{ value: "Seoni-Malwa", content: 'Seoni-Malwa' },
{ value: "Shirdi", content: 'Shirdi' },
{ value: "SUrban Agglomerationr", content: 'SUrban Agglomerationr' },
{ value: "Terdal", content: 'Terdal' },
{ value: "Raver", content: 'Raver' },
{ value: "Taraori", content: 'Taraori' },
{ value: "Mukhed", content: 'Mukhed' },
{ value: "Manachanallur", content: 'Manachanallur' },
{ value: "Rehli", content: 'Rehli' },
{ value: "Sanchore", content: 'Sanchore' },
{ value: "Rajura", content: 'Rajura' },
{ value: "Piro", content: 'Piro' },
{ value: "Mudabidri", content: 'Mudabidri' },
{ value: "Vadgaon Kasba", content: 'Vadgaon Kasba' },
{ value: "Nagar", content: 'Nagar' },
{ value: "Vijapur", content: 'Vijapur' },
{ value: "Viswanatham", content: 'Viswanatham' },
{ value: "Polur", content: 'Polur' },
{ value: "Panagudi", content: 'Panagudi' },
{ value: "Manawar", content: 'Manawar' },
{ value: "Tehri", content: 'Tehri' },
{ value: "Samdhan", content: 'Samdhan' },
{ value: "Pardi", content: 'Pardi' },
{ value: "Rahatgarh", content: 'Rahatgarh' },
{ value: "Panagar", content: 'Panagar' },
{ value: "Uthiramerur", content: 'Uthiramerur' },
{ value: "Tirora", content: 'Tirora' },
{ value: "Rangia", content: 'Rangia' },
{ value: "Sahjanwa", content: 'Sahjanwa' },
{ value: "Wara Seoni", content: 'Wara Seoni' },
{ value: "Magadi", content: 'Magadi' },
{ value: "Rajgarh (Alwar)", content: 'Rajgarh (Alwar)' },
{ value: "Rafiganj", content: 'Rafiganj' },
{ value: "Tarana", content: 'Tarana' },
{ value: "Rampur Maniharan", content: 'Rampur Maniharan' },
{ value: "Sheoganj", content: 'Sheoganj' },
{ value: "Raikot", content: 'Raikot' },
{ value: "Pauri", content: 'Pauri' },
{ value: "Sumerpur", content: 'Sumerpur' },
{ value: "Navalgund", content: 'Navalgund' },
{ value: "Shahganj", content: 'Shahganj' },
{ value: "Marhaura", content: 'Marhaura' },
{ value: "Tulsipur", content: 'Tulsipur' },
{ value: "Sadri", content: 'Sadri' },
{ value: "Thiruthuraipoondi", content: 'Thiruthuraipoondi' },
{ value: "Shiggaon", content: 'Shiggaon' },
{ value: "Pallapatti", content: 'Pallapatti' },
{ value: "Mahendragarh", content: 'Mahendragarh' },
{ value: "Sausar", content: 'Sausar' },
{ value: "Ponneri", content: 'Ponneri' },
{ value: "Mahad", content: 'Mahad' },
{ value: "Lohardaga", content: 'Lohardaga' },
{ value: "Tirwaganj", content: 'Tirwaganj' },
{ value: "Margherita", content: 'Margherita' },
{ value: "Sundarnagar", content: 'Sundarnagar' },
{ value: "Rajgarh", content: 'Rajgarh' },
{ value: "Mangaldoi", content: 'Mangaldoi' },
{ value: "Renigunta", content: 'Renigunta' },
{ value: "Longowal", content: 'Longowal' },
{ value: "Ratia", content: 'Ratia' },
{ value: "Lalgudi", content: 'Lalgudi' },
{ value: "Shrirangapattana", content: 'Shrirangapattana' },
{ value: "Niwari", content: 'Niwari' },
{ value: "Natham", content: 'Natham' },
{ value: "Unnamalaikadai", content: 'Unnamalaikadai' },
{ value: "PurqUrban Agglomerationzi", content: 'PurqUrban Agglomerationzi' },
{ value: "Shamsabad, Farrukhabad", content: 'Shamsabad, Farrukhabad' },
{ value: "Mirganj", content: 'Mirganj' },
{ value: "Todaraisingh", content: 'Todaraisingh' },
{ value: "Warhapur", content: 'Warhapur' },
{ value: "Rajam", content: 'Rajam' },
{ value: "Urmar Tanda", content: 'Urmar Tanda' },
{ value: "Lonar", content: 'Lonar' },
{ value: "Powayan", content: 'Powayan' },
{ value: "P.N.Patti", content: 'P.N.Patti' },
{ value: "Palampur", content: 'Palampur' },
{ value: "Srisailam Project (Right Flank Colony) Township", content: 'Srisailam Project (Right Flank Colony) Township' },
{ value: "Sindagi", content: 'Sindagi' },
{ value: "Sandi", content: 'Sandi' },
{ value: "Vaikom", content: 'Vaikom' },
{ value: "Malda", content: 'Malda' },
{ value: "Tharangambadi", content: 'Tharangambadi' },
{ value: "Sakaleshapura", content: 'Sakaleshapura' },
{ value: "Malkangiri", content: 'Malkangiri' },
{ value: "Rapar", content: 'Rapar' },
{ value: "Mauganj", content: 'Mauganj' },
{ value: "Todabhim", content: 'Todabhim' },
{ value: "Srinivaspur", content: 'Srinivaspur' },
{ value: "Murliganj", content: 'Murliganj' },
{ value: "Reengus", content: 'Reengus' },
{ value: "Sawantwadi", content: 'Sawantwadi' },
{ value: "Tittakudi", content: 'Tittakudi' },
{ value: "Lilong", content: 'Lilong' },
{ value: "Rajaldesar", content: 'Rajaldesar' },
{ value: "Pathardi", content: 'Pathardi' },
{ value: "Achhnera", content: 'Achhnera' },
{ value: "Pacode", content: 'Pacode' },
{ value: "Naraura", content: 'Naraura' },
{ value: "Nakur", content: 'Nakur' },
{ value: "Palai", content: 'Palai' },
{ value: "Morinda, India", content: 'Morinda, India' },
{ value: "Manasa", content: 'Manasa' },
{ value: "Nainpur", content: 'Nainpur' },
{ value: "Sahaspur", content: 'Sahaspur' },
{ value: "Pauni", content: 'Pauni' },
{ value: "Prithvipur", content: 'Prithvipur' },
{ value: "Ramtek", content: 'Ramtek' },
{ value: "Silapathar", content: 'Silapathar' },
{ value: "Songadh", content: 'Songadh' },
{ value: "Safipur", content: 'Safipur' },
{ value: "Sohagpur", content: 'Sohagpur' },
{ value: "Mul", content: 'Mul' },
{ value: "Sadulshahar", content: 'Sadulshahar' },
{ value: "Phillaur", content: 'Phillaur' },
{ value: "Sambhar", content: 'Sambhar' },
{ value: "Prantij", content: 'Prantij' },
{ value: "Nagla", content: 'Nagla' },
{ value: "Pattran", content: 'Pattran' },
{ value: "Mount Abu", content: 'Mount Abu' },
{ value: "Reoti", content: 'Reoti' },
{ value: "Tenu dam-cum-Kathhara", content: 'Tenu dam-cum-Kathhara' },
{ value: "Panchla", content: 'Panchla' },
{ value: "Sitarganj", content: 'Sitarganj' },
{ value: "Pasighat", content: 'Pasighat' },
{ value: "Motipur", content: 'Motipur' },
{ value: "Raghunathpur", content: 'Raghunathpur' },
{ value: "Suriyampalayam", content: 'Suriyampalayam' },
{ value: "Qadian", content: 'Qadian' },
{ value: "Rairangpur", content: 'Rairangpur' },
{ value: "Silvassa", content: 'Silvassa' },
{ value: "Nowrozabad (Khodargama)", content: 'Nowrozabad (Khodargama)' },
{ value: "Mangrol", content: 'Mangrol' },
{ value: "Soyagaon", content: 'Soyagaon' },
{ value: "Sujanpur", content: 'Sujanpur' },
{ value: "Manihari", content: 'Manihari' },
{ value: "Sikanderpur", content: 'Sikanderpur' },
{ value: "Mangalvedhe", content: 'Mangalvedhe' },
{ value: "Phulera", content: 'Phulera' },
{ value: "Ron", content: 'Ron' },
{ value: "Sholavandan", content: 'Sholavandan' },
{ value: "Saidpur", content: 'Saidpur' },
{ value: "Shamgarh", content: 'Shamgarh' },
{ value: "Thammampatti", content: 'Thammampatti' },
{ value: "Maharajpur", content: 'Maharajpur' },
{ value: "Multai", content: 'Multai' },
{ value: "Mukerian", content: 'Mukerian' },
{ value: "Purwa", content: 'Purwa' },
{ value: "Sheohar", content: 'Sheohar' },
{ value: "Namagiripettai", content: 'Namagiripettai' },
{ value: "Parasi", content: 'Parasi' },
{ value: "Lathi", content: 'Lathi' },
{ value: "Lalganj", content: 'Lalganj' },
{ value: "Narkhed", content: 'Narkhed' },
{ value: "Mathabhanga", content: 'Mathabhanga' },
{ value: "Shendurjana", content: 'Shendurjana' },
{ value: "Peravurani", content: 'Peravurani' },
{ value: "Mariani", content: 'Mariani' },
{ value: "Phulpur", content: 'Phulpur' },
{ value: "Rania", content: 'Rania' },
{ value: "Pali", content: 'Pali' },
{ value: "Pachore", content: 'Pachore' },
{ value: "Parangipettai", content: 'Parangipettai' },
{ value: "Pudupattinam", content: 'Pudupattinam' },
{ value: "Panniyannur", content: 'Panniyannur' },
{ value: "Maharajganj", content: 'Maharajganj' },
{ value: "Rau", content: 'Rau' },
{ value: "Monoharpur", content: 'Monoharpur' },
{ value: "Mandawa", content: 'Mandawa' },
{ value: "Marigaon", content: 'Marigaon' },
{ value: "Pallikonda", content: 'Pallikonda' },
{ value: "Pindwara", content: 'Pindwara' },
{ value: "Shishgarh", content: 'Shishgarh' },
{ value: "Patur", content: 'Patur' },
{ value: "Mayang Imphal", content: 'Mayang Imphal' },
{ value: "Mhowgaon", content: 'Mhowgaon' },
{ value: "Guruvayoor", content: 'Guruvayoor' },
{ value: "Mhaswad", content: 'Mhaswad' },
{ value: "Sahawar", content: 'Sahawar' },
{ value: "Sivagiri", content: 'Sivagiri' },
{ value: "Mundargi", content: 'Mundargi' },
{ value: "Punjaipugalur", content: 'Punjaipugalur' },
{ value: "Kailasahar", content: 'Kailasahar' },
{ value: "Samthar", content: 'Samthar' },
{ value: "Sakti", content: 'Sakti' },
{ value: "Sadalagi", content: 'Sadalagi' },
{ value: "Silao", content: 'Silao' },
{ value: "Mandalgarh", content: 'Mandalgarh' },
{ value: "Loha", content: 'Loha' },
{ value: "Pukhrayan", content: 'Pukhrayan' },
{ value: "Padmanabhapuram", content: 'Padmanabhapuram' },
{ value: "Belonia", content: 'Belonia' },
{ value: "Saiha", content: 'Saiha' },
{ value: "Srirampore", content: 'Srirampore' },
{ value: "Talwara", content: 'Talwara' },
{ value: "Puthuppally", content: 'Puthuppally' },
{ value: "Khowai", content: 'Khowai' },
{ value: "Vijaypur", content: 'Vijaypur' },
{ value: "Takhatgarh", content: 'Takhatgarh' },
{ value: "Thirupuvanam", content: 'Thirupuvanam' },
{ value: "Adra", content: 'Adra' },
{ value: "Piriyapatna", content: 'Piriyapatna' },
{ value: "Obra", content: 'Obra' },
{ value: "Adalaj", content: 'Adalaj' },
{ value: "Nandgaon", content: 'Nandgaon' },
{ value: "Barh", content: 'Barh' },
{ value: "Panamattom", content: 'Panamattom' },
{ value: "Niwai", content: 'Niwai' },
{ value: "Bageshwar", content: 'Bageshwar' },
{ value: "Tarbha", content: 'Tarbha' },
{ value: "Adyar", content: 'Adyar' },
{ value: "Narsinghgarh", content: 'Narsinghgarh' },
{ value: "Warud", content: 'Warud' },
{ value: "Asarganj", content: 'Asarganj' },
{ value: "Sarsod", content: 'Sarsod' }
]