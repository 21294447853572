import React, { Component } from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';
import ReactAutocomplete from 'react-autocomplete';
import ReactFlagsSelect from 'react-flags-select';

//import css module
import 'react-flags-select/css/react-flags-select.css';

import M from 'materialize-css/dist/js/materialize.min.js';
import Dropdown from 'react-kiwi-dropdown';

import Chips, { Chip } from 'react-chips';
import firebase from "firebase";

export default class TravelInsurance extends React.Component {
	constructor (props) {
    super(props)
    this.state = {
      chips: ['United States of America'],
      age : ['32'],
      currentDateFormatted:'',
      selectedBuyCity: 'Mumbai',
      selectedBuyCityOptions: 'Mumbai',
    }


    this.onChangeChips = this.onChangeChips.bind(this);
    this.onAgeChange = this.onAgeChange.bind(this);
    this.onChangeBuyCity = this.onChangeBuyCity.bind(this);

  }

    componentDidMount() {

      this.getCurrentDate()
    }


getCurrentDate(){
     var currentDate = new Date()
     const enquiryDate = currentDate.getDate() +'-'+(currentDate.getMonth()+1)+'-'+currentDate.getFullYear();
     this.setState({
          currentDateFormatted: enquiryDate
     })
  }


uniqueNumber(){
    var date = new Date();
    var un = ''+date.getDate()+(date.getMonth()+1)+date.getFullYear()+date.getHours()+date.getMinutes()+date.getMilliseconds();
    return un
  }

onChangeChips(chips){

    this.setState({ chips:chips });
  }

onAgeChange(chips){
  this.setState({age:chips})
}

submitTravellerEnquiry(){
  if (this.state.chips.length===0) {
    M.toast({html: '<span className="yellow-text">Minimum one country required</span>'})
  }else if(this.state.age.length===0){
    M.toast({html: '<span className="yellow-text">Age cannot be blank</span>'})
  }else{
    
    if (global.login==true) {
      if (this.state.chips.length==0) {
        M.toast({html: '<span className="yellow-text">Select minimum one country</span>'})
          
      }else{
        this.sendTravelInsuranceCardEnquiry()
      }
    }else{
      var instance = M.Modal.getInstance(document.getElementById('auth-form'));
      instance.open();

      var modalBox = document.getElementById('auth-form');
      modalBox.setAttribute('opened-from', 'sim-form');
    }
    
  }
}

sendTravelInsuranceCardEnquiry(){
  var enquiryId = this.uniqueNumber()

    var insuranceEnquiry = 
      {
       id: enquiryId,
       name: global.userData.providerData[0].displayName,
       phone: global.phone,
       status: 'new',
       subject: 'Travel insurance enquiry',
       code:5,
       chips: this.state.chips,
       age: this.state.age,
       date: this.state.currentDateFormatted,
       selectedBuyCity: this.state.selectedBuyCity, 
       selectedBuyCityOptions: this.state.selectedBuyCityOptions
      }

    firebase.firestore().collection("enquries").doc(enquiryId).set(insuranceEnquiry)
    .then((doc) => {

        var baseurl = 'http://'+ window.location.host

        var mailBody = '<div>  <div style="text-align: center; margin-top:50px"> <img src="https://img.icons8.com/clouds/344/service-bell.png" style="height: 100px; width: 100px"></td>  </div><table style="width: 100%;">  <tr  style="text-align: center;"><td style="width: 50%;" align="center"> <div><div>  <h3 style="text-align: center;border: 1px solid #26a69a; padding: 10px; color: #26a69a; width:400px">You have recieved a new enquiry</h3></div><div style="text-align: center;">  '+global.userData.providerData[0].displayName+' has submitted a travel insurance enquiry <br/>  contact: '+ global.phone+'</div><div style="margin-top: 50px">  <a href="'+baseurl+'/admin" style="text-align: center;background:#26a69a; padding: 10px; color: white; width:100px; border-radius:5px; margin-top:50px; cursor: pointer;">View Details</a></div></div>    </td>   </tr></table>  <div style="text-align: center; background: #26a69a; margin-top:50px"> <div><img src="https://voice-278216.web.app/logo_small.png" style="width: 170px; height: 50px"></div>  </div></div>'

        this.sendMail('Travel insurance enquiry recieved', mailBody )
        

        M.toast({html: '<span className="yellow-text">Thank you. Your request has been submitted successfully</span>'})
        })
      .catch(error => {
        console.log('firebase Error::'+error)
        M.toast({html: '<span className="yellow-text">Unable to submit enquiry</span>'})
         })
  
}

onChangeBuyCity(option){
    this.setState({
    selectedBuyCity: option.value, 
    selectedBuyCityOptions: option.content
  },()=>{console.log(this.state.selectedBuyCity)})

}

sendMail(subject, body){

  window.Email.send({
    Host: global.mailHost,
    Username : global.mailUser,
    Password : global.password,
    To : global.adminEmail,
    From : global.mailUser,
    Subject : subject,
    Body : body,
  }).then(
    // console.log('Mail send successfully'+global.adminEmail)
  );

  }



  render() {
    return (
        <div className="col s12 m6 row  w-100p formContainer" style={{marginLeft:20, marginRight:20}}>
          <div className="row" style={{height:400}}>
                <div className="col s12">
                  <ul className="tabs transparent">
                    <li className="tab col s12"><a className="active white-text" href="#sim-card">Travel Insurance</a></li>
                  </ul>
                </div>
                <div id="sim-card" className="col s12">
                    <div className="row s12" style={{marginTop:30}}>
                    	<div className="white-text t-left f-12 p-bottom-5" style={{paddingLeft:10}}>Destination Country </div>
                        <div className="col s12" id="multi_country_select_01">
                          <Chips
        					          value={this.state.chips}
        					          onChange={this.onChangeChips}
        					          suggestions={global.coutryMultiSelectList}

        					        />
                        </div>
                      </div>

                    <div className="row s12" style={{marginTop:30}}>
                      <div className="white-text t-left f-12 p-bottom-5" style={{paddingLeft:10}}>Travellers Age </div>
                        <div className="col s12" id="multi_country_select_01">
                          <Chips
                            value={this.state.age}
                            onChange={this.onAgeChange}
                            suggestions={global.age}

                          />
                        </div>
                      </div>

                    <div className="relative "  id="city_07">
                          <div className="white-text t-left f-12 p-bottom-5">My nearest location </div>
                          <img className="flag-position"  width="25" height="25" src={'https://img.icons8.com/fluent-systems-regular/344/user-location.png'} style={{marginRight:5, marginLeft:10}} />
                          <Dropdown
                            options={global.buyCity}
                            onChange={this.onChangeBuyCity}
                            buttonLabel={this.state.selectedBuyCityOptions}
                            resetValue={''}
                            selectedOption={this.state.selectedBuyCity}
                          />
                        </div>


                    <div className="row">
                        <div className="input-field col s12">
                          <a className="waves-effect waves-light btn-large w-100p" onClick={()=>{this.submitTravellerEnquiry()}}>ENQUIRY INSURANCE PLANS</a>
                        </div>
                    </div>
                </div>
                

              </div>
        </div>
    );
  }
}
