global.login = false;
global.userData={};
global.phone = null;
global.admin = false;
global.localAdmin = false;
global.adminLocation = null;

global.perPageLength = 200;

global.mailHost = "smtp.gmail.com";
global.mailUser = "notification.book.forex@gmail.com";
global.password = "@dgjadgj";

global.adminEmail = 'book.forex.2021@gmail.com'



global.buyServices = [
  { value: 'forex-card', content: '💳 Forex Card' },
  { value: 'currency-note', content: '💵 Currency Note' },
  { value: 'traveler-cheque', content: '📝 Traveler Cheque' }
]

global.sellService = [
	{ value: 'currency-note', content: '💳 Currency Note' },
	{ value: 'traveler-cheque', content: '📝 Traveler Cheque' }
]

global.transferService = [
	{ value: 'wire-transfer', content: '💳 Wire Transfer' },
	{ value: 'demand-draft-transfer', content: '📝 Demand Draft' }
]

global.oneCountry = [
	{ value: "india", content: 'IN Rupee' }
]

global.forexCardService = [
	{ value: "Axis Bank Forex Card", content: "💳 Axis Bank Forex Card"},
	{value:'ICICI Bank Forex Card', content: '💳 ICICI Bank Forex Card'},  
	{value:'Other Bank Card', content: '💳 Other Bank Card'}, 
	{value:'None', content: '⛔ None'}
]

//For testing------
// global.firebaseConfig = {
//     apiKey: "AIzaSyCCfnb3ibHy8AUWDJHZNH1Lv7Btl68olY4",
//     authDomain: "voice-278216.firebaseapp.com",
//     databaseURL: "https://voice-278216.firebaseio.com",
//     projectId: "voice-278216",
//     storageBucket: "voice-278216.appspot.com",
//     messagingSenderId: "154084698153",
//     appId: "1:154084698153:web:3d44e9f39a560abe3d9438"
//   }

global.firebaseConfig = {
apiKey: "AIzaSyAdhmlkyeP7htYYI68xDvPXI9VWR4rik78",
    authDomain: "book-forex.firebaseapp.com",
    projectId: "book-forex",
    storageBucket: "book-forex.appspot.com",
    messagingSenderId: "22178336191",
    appId: "1:22178336191:web:fc0f014ad428d1d367e50a",
    measurementId: "G-0V5T90P0RX"
  }

global.rate = []


global.enquiry = [
{ value: "0-10,000", content: "Rs 0-10,000"},
{ value: "10,000-10,00,000", content: "Rs 10,000-10,00,000"},
{ value: "10,00,000-1,00,00,000", content: "Rs 10,00,000-1,00,00,000"},
{ value: "1,00,00,000 +", content: "Rs 1,00,00,000 +"},
]