import React, { Component } from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';
import ReactAutocomplete from 'react-autocomplete';
import ReactFlagsSelect from 'react-flags-select';


import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import firebase from "firebase";

//import css module
import 'react-flags-select/css/react-flags-select.css';

import Dropdown from 'react-kiwi-dropdown'

export default class MoneyTransfer extends React.Component {
	constructor (props) {
    super(props)
    this.state = {
      value: 'INDIA (Rupee)',
      buyHavingValue: 'india',
      buyHavingOption: 'IN Rupee',

      buyWantValue: 'united-states-of-america',
      buyWantOption: 'US Dollar',

      sellHavingValue: 'united-states-of-america',
      sellHavingOption: 'US Dollar',
      sellWantValue: 'india',
      sellWantOption: 'IN Rupee',


      forexAmountBuy:'',
      inrAmountBuy:0,

      forexAmountSell:'',
      inrAmountSell:'',


      selectedOption:'wire-transfer', 
      setSelectedOption:'💳 Wire Transfer',

      sellSelectedOption:'currency-note', 
      sellSetSelectedOption:'💳 Currency Note',


      selectedBuyCity: 'Mumbai',
      selectedBuyCityOptions: 'Mumbai',

      rateAppliedBuy:0,
      data: this.props.data,
      currentDateFormatted:''

    }
    this.onChangeTransferService = this.onChangeTransferService.bind(this);
    this.onChangeSellService = this.onChangeSellService.bind(this);

    this.onChangeBuyCity = this.onChangeBuyCity.bind(this);


    this.onChangeTransferFrom = this.onChangeTransferFrom.bind(this);
    this.onChangeTransferTo = this.onChangeTransferTo.bind(this);



    this.handleChangeRecieveAmountBuy = this.handleChangeRecieveAmountBuy.bind(this);


    this.handleChangeINRAmountBuy = this.handleChangeINRAmountBuy.bind(this);

  }

    componentDidMount() {
    this.setInitialBuyRate();
    this.getCurrentDate();
  }


  getCurrentDate(){
     var currentDate = new Date()
     const enquiryDate = currentDate.getDate() +'-'+(currentDate.getMonth()+1)+'-'+currentDate.getFullYear();
     this.setState({
          currentDateFormatted: enquiryDate
     })
  }

  setInitialBuyRate(){
  
    var a = this.state.data.find(x=>x.currency==="US Dollar")
    
    if (a!==undefined) {
      this.setState({
      rateAppliedBuy: ((Math.round(a.buy_forex_rate*100)/100)).toFixed(2) 
    })
    }
}

  onChangeTransferService(option){
  
  this.setState({
    selectedOption: option.value, 
    setSelectedOption: option.content
  })
  
}


onChangeSellService(option){
  this.setState({
    sellSelectedOption: option.value, 
    sellSetSelectedOption: option.content
  })
}

onChangeBuyCity(option){
    this.setState({
    selectedBuyCity: option.value, 
    selectedBuyCityOptions: option.content
  },()=>{console.log(this.state.selectedBuyCity)})

}

onChangeTransferFrom(option){
  
  this.setState({
    buyHavingValue: option.value, 
    buyHavingOption: option.content
  })
  
}

onChangeTransferTo(option){ 
  console.log(option.value+':::::'+option.content)

  var a = this.state.data.find(x=>x.currency===option.content)
  console.log(JSON.stringify(a.buy_forex_rate))

  var updateBuyINRAmount = this.state.forexAmountBuy* Number(a.buy_forex_rate)


  this.setState({
    buyWantValue: option.value, 
    buyWantOption: option.content,
    rateAppliedBuy: ((Math.round(a.buy_forex_rate*100)/100)).toFixed(2),
    inrAmountBuy: ((Math.round(updateBuyINRAmount*100)/100)).toFixed(2)
  })
  
}

AddMoneyTransferOrderToFirebase(){
    var orderId = this.uniqueNumber()

    var moneyTransferOrder = 
      {
        inrAmountBuy: this.state.inrAmountBuy,
        forexAmountBuy: this.state.forexAmountBuy,

        id: orderId,
        name:  global.userData.providerData[0].displayName,
        phone: global.phone,       
        rate: this.state.rateAppliedBuy,

        selectedOption: this.state.selectedOption,
        setSelectedOption: this.state.setSelectedOption,

        selectedBuyCityOptions: this.state.selectedBuyCityOptions,
        selectedBuyCity: this.state.selectedBuyCity,

        buyWantOption: this.state.buyWantOption,
        buyWantValue: this.state.buyWantValue,

        buyHavingOption: this.state.buyHavingOption,
        buyHavingValue: this.state.buyHavingValue,

        status: 'new',
        subject: 'Money Transfer',
        trade: 'send',

        code:2,
        date: this.state.currentDateFormatted,
        email: global.userData.providerData[0].uid

      }

    firebase.firestore().collection("orderlist").doc(orderId).set(moneyTransferOrder)
    .then((doc) => {

        var baseurl = 'http://'+ window.location.host

        var mailBody = '<div>  <div style="text-align: center; margin-top:50px"> <img src="https://img.icons8.com/clouds/344/service-bell.png" style="height: 100px; width: 100px"></td>  </div><table style="width: 100%;">  <tr  style="text-align: center;"><td style="width: 50%;" align="center"> <div><div>  <h3 style="text-align: center;border: 1px solid #26a69a; padding: 10px; color: #26a69a; width:400px">You have recieved a new order</h3></div><div style="text-align: center;">  '+global.userData.providerData[0].displayName+' has placed money tranfer order <br/>  contact: '+ global.phone+'</div><div style="margin-top: 50px">  <a href="'+baseurl+'/admin" style="text-align: center;background:#26a69a; padding: 10px; color: white; width:100px; border-radius:5px; margin-top:50px; cursor: pointer;">View Details</a></div></div>    </td>   </tr></table>  <div style="text-align: center; background: #26a69a; margin-top:50px"> <div><img src="https://voice-278216.web.app/logo_small.png" style="width: 170px; height: 50px"></div>  </div></div>'

        this.sendMail('Money transfer order recieved', mailBody )
        
        M.toast({html: '<span className="yellow-text">Thank you. Your order placed successfully</span>'})
        })
      .catch(error => {
        console.log('firebase Error::'+error)
        M.toast({html: '<span className="yellow-text">Unable to place order</span>'})
         })
}


handleChangeRecieveAmountBuy(e){
    var updateBuyINRAmount = e.target.value* this.state.rateAppliedBuy

    this.setState({
       forexAmountBuy: e.target.value,
       inrAmountBuy: ((Math.round(updateBuyINRAmount*100)/100)).toFixed(2)
    })
}

handleChangeINRAmountBuy(e){
    var updateForexAmount = e.target.value/ this.state.rateAppliedBuy

    this.setState({
       inrAmountBuy: e.target.value,
       forexAmountBuy:  ((Math.round(updateForexAmount*100)/100)).toFixed(2)
    })
}

uniqueNumber(){
    var date = new Date();
    var un = ''+date.getDate()+(date.getMonth()+1)+date.getFullYear()+date.getHours()+date.getMinutes()+date.getMilliseconds();
    return un
  }


submitMoneyTransfer(){
  

  if (global.login===true) {
      if (this.state.forexAmountBuy===0) {
        M.toast({html: '<span className="yellow-text">Forex amount cannot be blank</span>'})
      }else{
        this.AddMoneyTransferOrderToFirebase()
      }
    }else{
      var instance = M.Modal.getInstance(document.getElementById('auth-form'));
      instance.open();

      var modalBox = document.getElementById('auth-form');
      modalBox.setAttribute('opened-from', 'transfer-currency-form');
    }


}

sendMail(subject, body){

  window.Email.send({
    Host: global.mailHost,
    Username : global.mailUser,
    Password : global.password,
    To : global.adminEmail,
    From : global.mailUser,
    Subject : subject,
    Body : body,
  }).then(
    // console.log('Mail send successfully'+global.userData.providerData[0].uid)
  );

  }


  render() {
    return (
        <div className="col s12 m6 row  w-100p formContainer" style={{marginLeft:20, marginRight:20}}>
          <div className="row" style={{height:400}}>
                <div className="col s12">
                  <ul className="tabs transparent">
                    <li className="tab col s12"><a className="active white-text" href="#money-transfer">MONEY TRANSFER</a></li>
                  </ul>
                </div>
                <div id="money-transfer" className="col s12">
                    <div className="row" style={{marginTop:30}}>
                        <div className="col s12 m6 relative " id="country_05">
                              <div className="white-text t-left f-12 p-bottom-5">Transfer from currency  </div>
                              <img className="flag-position"  width="30" height="25" src={'https://cdn.countryflags.com/thumbs/'+this.state.buyHavingValue+'/flag-square-250.png'} style={{marginRight:5, marginLeft:10}} />
                              <Dropdown
                                options={global.oneCountry}
                                onChange={this.onChangeTransferFrom}
                                buttonLabel={this.state.buyHavingOption}
                                resetValue={''}
                                selectedOption={this.state.buyHavingValue}
                              />
                            
                        </div>
                        <div className="col s12 m6 relative r-top-10" id="country_06">
                              <div className="white-text t-left f-12 p-bottom-5">Transfer to currency </div>
                              <img className="flag-position"  width="30" height="25" src={'https://cdn.countryflags.com/thumbs/'+this.state.buyWantValue+'/flag-square-250.png'} style={{marginRight:5, marginLeft:10}} />
                              <Dropdown
                                options={global.coutryList}
                                onChange={this.onChangeTransferTo}
                                buttonLabel={this.state.buyWantOption}
                                resetValue={''}
                                selectedOption={this.state.buyWantValue}
                              />
                            
                        </div>
                    </div>

                    <div className="row">

                        <div className="col s12 m6" >
                          <div className="white-text t-left f-12 p-bottom-5">Select service </div>
                          <Dropdown
                            options={global.transferService}
                            onChange={this.onChangeTransferService}
                            buttonLabel={this.state.setSelectedOption}
                            resetValue={''}
                            selectedOption={this.state.selectedOption}
                          />
                        </div>
                        <div className="col s12 m6 relative r-top-10"  id="city_01">
                          <div className="white-text t-left f-12 p-bottom-5">My nearest location </div>
                          <img className="flag-position"  width="25" height="25" src={'https://img.icons8.com/fluent-systems-regular/344/user-location.png'} style={{marginRight:5, marginLeft:10}} />
                          <Dropdown
                            options={global.buyCity}
                            onChange={this.onChangeBuyCity}
                            buttonLabel={this.state.selectedBuyCityOptions}
                            resetValue={''}
                            selectedOption={this.state.selectedBuyCity}
                          />
                        </div>
                    </div>

                    <div className="row">
                        <div className="input-field col s12 m6 r-top-10">
                          <input placeholder="Enter amount" id="inrMoneyTransfer" type="number" className=" black-text white" value={this.state.inrAmountBuy} onChange={this.handleChangeINRAmountBuy} style={{marginTop:5, paddingLeft:5}}/>
                          <label htmlFor="inrMoneyTransfer" className="white-text">Sending Amount</label>
                        </div>
                        <div className="input-field col s12 m6">
                           <input placeholder="Enter amount" type="number" id="moneyTransfer"  className=" black-text white" value={this.state.forexAmountBuy} onChange={this.handleChangeRecieveAmountBuy} style={{marginTop:5, paddingLeft:5}}/>
                          <label htmlFor="moneyTransfer" className="white-text">Recieving Amount</label>
                        </div>
                    </div>

                    <div className="row m-0">
                      <div className="col s6">
                      </div>
                      <div className="col s6">
                          {this.state.rateAppliedBuy===0?null:
                          <div className="white-text"><i className="fas fa-chart-line white-text" style={{fontSize:20}}></i> Current Rate: {this.state.rateAppliedBuy}</div>
                          }
                      </div>
                    </div>

                    <div className="row">
                        <div className="input-field col s6">
                          {this.state.inrAmountBuy===0?null:
                          <h5 className="white-text">Total: ₹ {this.state.inrAmountBuy}</h5>
                          }
                        </div>
                        <div className="input-field col s6">
                          <a className="waves-effect waves-light btn-large w-100p" onClick={()=>{this.submitMoneyTransfer()}}>BOOK THIS ORDER</a>
                        </div>
                    </div>
                </div>
                

              </div>
        </div>
    );
  }
}
