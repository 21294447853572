import React, { Component } from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';
import ReactAutocomplete from 'react-autocomplete';
import ReactFlagsSelect from 'react-flags-select';

//import css module
import 'react-flags-select/css/react-flags-select.css';

import M from 'materialize-css/dist/js/materialize.min.js';
import Dropdown from 'react-kiwi-dropdown';

import Chips, { Chip } from 'react-chips';
import firebase from "firebase";

export default class Others extends React.Component {
	constructor (props) {
    super(props)
    this.state = {
      
    }



  }

    componentDidMount() {

    }



  render() {
    return (
        <div className="col s12 m6 row  w-100p formContainer" style={{marginLeft:20, marginRight:20}}>
          <div className="row" style={{height:400}}>
                <div className="col s12">
                  <ul className="tabs transparent">
                    <li className="tab col s6"><a className="active white-text" href="#cfo">CFO Service</a></li>
                    <li className="tab col s6"><a className="active white-text" href="#other">Other</a></li>
                  </ul>
                </div>
                <div id="cfo" className="col s12">
                    <div className="row s12" style={{marginTop:0}}>
                    	<div className="white-text t-left f-16 p-bottom-5" style={{paddingLeft:10}}>We provide as-needed full-time, part-time, or short-term CFO services to businesses. Preferred CFO has an elite team of some of the most experienced outsourced CFOs in the industry. These experts have decades of experience providing CFO services to hundreds of clients in dozens and industries.
                      </div>
                      <div className="white-text t-left f-16 p-bottom-5">
                        <div>- Financial Strategy</div>
                        <div>- Short- and Long-Term Forecasting</div>
                        <div>- Financial Systems Strategy & Design </div>
                        <div>- Facilitating & Interpreting Financial Reporting</div>
                        <div>- Raising Capital and advisory on Capital Structure.</div>
                        <div>- Cash Flow Analysis & Restructuring</div>
                        <div>- Making Cost Cuts</div>
                        <div>- Facilitating Mergers & Acquisitions</div>
                        <div>- Manpower support</div>
                        <div>- Assisting in finding the best fit for the job, leadership hiring. Satisfying urgent requirements including providing staff on temporary basis.</div>
                      </div>

                      <a style={{marginTop:30}} target="_blank" href="https://cfoservice.in/" class="waves-effect waves-light btn">More Details </a>
                      
                    </div>
                </div>
                <div id="other" className="col s12">
                    <div className="row s12" style={{marginTop:0}}>
                      <div className="white-text t-left f-16 p-bottom-5">
                        <div>As a Business Partner</div>
                        <div>- Accounting and Business Support</div>
                        <div>- IFRS Expert. </div>
                        <div>- Indirect tax – GST.</div>
                        <div>- Direct tax.</div>
                        <div>- Valuation.</div>
                        <div>- Corporate Law.</div>
                        <div>- Legal Services.</div>
                      </div>

                      <a style={{marginTop:30}} target="_blank" href="https://cfoservice.in/" class="waves-effect waves-light btn">More Details </a>
                    </div>
                    
                </div>
              </div>
        </div>
    );
  }
}
