import React, { Component } from 'react';
import readXlsxFile from 'read-excel-file'

import firebase from "firebase";

import RateTable from '../RateTable'


// Your web app's Firebase configuration
  // var firebaseConfig = {
  //   apiKey: "AIzaSyCCfnb3ibHy8AUWDJHZNH1Lv7Btl68olY4",
  //   authDomain: "voice-278216.firebaseapp.com",
  //   databaseURL: "https://voice-278216.firebaseio.com",
  //   projectId: "voice-278216",
  //   storageBucket: "voice-278216.appspot.com",
  //   messagingSenderId: "154084698153",
  //   appId: "1:154084698153:web:3d44e9f39a560abe3d9438"
  // };
  // Initialize Firebase
  // firebase.initializeApp(firebaseConfig);
  // const app = firebase.app();

  // const db = firebase.firestore();


export default class RateAdmin extends React.Component {
	constructor (props) {
    super(props)
    this.state = {
     	data:this.props.data

    }
    this.initializeFIleInput = this.initializeFIleInput.bind(this);
    

  }

componentDidMount(){
	this.initializeFIleInput()
  }

initializeFIleInput(){
	if (global.admin===true) {
	const input = document.getElementById('rateChart')
    input.addEventListener('change', () => {
	  readXlsxFile(input.files[0]).then((rows) => {
	    
	    console.log(rows.length)
	    var tempArray = []
	    for (var i = 0; i < rows.length; i++) {

	    	if (i!=0) {

	    	if (rows[i].[0]===null) {
	    		rows[i].[0]='-'
	    	}

	    	if (rows[i].[1]===null) {
	    		rows[i].[1]='-'
	    	}

	    	if (rows[i].[2]===null) {
	    		rows[i].[2]='-'
	    	}

	    	if (rows[i].[3]===null) {
	    		rows[i].[3]='-'
	    	}

	    	if (rows[i].[4]===null) {
	    		rows[i].[4]='-'
	    	}

	    	if (rows[i].[5]===null) {
	    		rows[i].[5]='-'
	    	}


	    	var a = {"id":i, "currency":rows[i].[0], "buy_forex_rate":rows[i].[1], "sell_forex_rate": rows[i].[2],"buy_forex_card_rate":rows[i].[3], "sell_forex_card_rate":rows[i].[4], "wire_transfer": rows[i].[5]} 	

	    		tempArray.push(a)
	    	}
	    	
	    }
	    console.log(JSON.stringify(tempArray))
	    this.addDataToFirestore(tempArray)
	  })
	})
  }
}



  addDataToFirestore(data){
  	firebase.firestore().collection("rate").doc("master").set({data})
	.then(function() {
	    
	   // console.log('>>>>>>>>>>>>>>updated>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
	    window.location.reload()
	})
	.catch(function(error) {
	    console.error("Error writing document: ", error);
	   
	});


  }

  render() {
    return (
        <div >
          <div className="row">
          	  <div className="col s12 m8"></div>
          	  {global.admin===false?null:
		          <div className="file-field input-field col s12 m4 green-border-light b-5">
				      <div className="btn"  style={{marginTop:5}}>
				        <span>File</span>
				        <input type="file" id="rateChart"/>
				      </div>
				      <div className="file-path-wrapper">
				        <input id="rateUpload" className="file-path validate" type="text" placeholder="upload new rate"/>
				      </div>
				   </div>
			   }
		   </div>

		 <RateTable  data={this.state.data}/>
        </div>
    );
  }
}
