import React, { Component } from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import Enquiry from '../common/Enquiry';
import Authentication from '../common/Authentication';

import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };


export default class HomeSlide extends React.Component {

	constructor (props) {
    super(props)
    this.state = {
     

    }   
    // console.log('props admin 1>>>>>'+this.props.data)
    this.onEnquiryClicked = this.onEnquiryClicked.bind(this)
  }

	componentDidMount(){
     
  }


  
  onEnquiryClicked(){
  	if (global.login==true) {
  		var modalBox = document.getElementById('enquiry-form');
  		modalBox.setAttribute('opened-from', 'slide');

  		var instance = M.Modal.getInstance(document.getElementById('enquiry-form'));
  		instance.open()
  	}else{
  		var instance = M.Modal.getInstance(document.getElementById('auth-form'));
  		instance.open();

  		var modalBox = document.getElementById('auth-form');
  		modalBox.setAttribute('opened-from', 'slide');
  	}
  	
  }



  render() {
    return (
    	
    <div className="col s12 m6 row m-top-50">
    	<Slider {...settings}>
	      <div>
	        <div className="col s2"></div>
			<div className="col s8">
    			<i className="fas fa-envelope-open-text  white-text" style={{fontSize:50}}></i> 
    			<div className="f-wt-500 white-text" style={{marginTop:10}}>Zero markup money transfer</div>
    			<div className="center-align f-12  white-text" style={{marginTop:20}}>Enjoy first three Money Transfers at exact Interbank Rates from the comfort of your Home/Office I Valid for all types of Import/ Export and Commercial transactions</div>
    				<a className="waves-effect waves-light btn " href="#!"  style={{marginTop:30}} onClick={()=>{this.onEnquiryClicked()}}>ENQUIRY NOW</a>
			</div>
			<div className="col s2"></div>
	      </div>
	      <div>
	        <div className="col s2"></div>
			<div className="col s8">
    			<i className="fas fa-globe  white-text" style={{fontSize:50}}></i> 
    			<div className="f-wt-500 white-text" style={{marginTop:10}}>TRANSFER MONEY ABROAD FOR FREE</div>
    			<div className="center-align f-12  white-text" style={{marginTop:20}}>Zero Transfer Charges. Save 2-5% over Banks. Doorstep Document Pick-up.Funds received abroad in less 12-48 hours. Special Rates for Student.</div>
    			{/*<a className="waves-effect waves-light btn" style={{marginTop:30}}>TRANSFER NOW</a>*/}
			</div>
			<div className="col s2"></div>
	      </div>
	      {/*<div>
                  <div className="col s2"></div>
              <div className="col s8">
                  <i className="fas fa-users  white-text" style={{fontSize:50}}></i> 
                  <div className="f-wt-500 white-text" style={{marginTop:10}}>KNOW SOMEONE WHO NEEDS TO TRANSFER MONEY ABROAD</div>
                  <div className="center-align f-12  white-text" style={{marginTop:20}}>Earn up to ₹500 per referral</div>
                  <a className="waves-effect waves-light btn"  style={{marginTop:30}}>GET REFERAL LINK</a>
              </div>
              <div className="col s2"></div>
                </div>*/}
	      <div>
	        <div className="col s2"></div>
			<div className="col s8">
    			<i className="fas fa-check-circle  white-text" style={{fontSize:50}}></i> 
    			<div className="f-wt-500 white-text" style={{marginTop:10}}>INDIA'S TRUSTED FOREX CARD</div>
    			<div className="center-align f-12  white-text" style={{marginTop:20}}>Zero Margin/ Interbank rates (above ₹1lakh).Zero Loading/ Reloading/ Unloading Charges. Accepted Globally</div>
			</div>
			<div className="col s2"></div>
	      </div>
	      <div>
	        <div className="col s2"></div>
			<div className="col s8">
    			<i className="fas fa-shopping-bag white-text" style={{fontSize:50}}></i> 
    			<div className="f-wt-500 white-text" style={{marginTop:10}}>BUY FOREX CARD/CURRENCY NOTE AND GET HOME DELIVERY</div>
    			<div className="center-align f-12  white-text" style={{marginTop:20}}>Zero Margin/ Interbank rates (above ₹1lakh).Zero Loading/ Reloading/ Unloading Charges. Accepted Globally</div>
			</div>
			<div className="col s2"></div>
	      </div>
	    </Slider>

	    <Enquiry/>
	    <Authentication/>

  	</div>
 	)}
}