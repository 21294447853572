import React, { Component } from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';
import ReactAutocomplete from 'react-autocomplete';
import ReactFlagsSelect from 'react-flags-select';

//import css module
import 'react-flags-select/css/react-flags-select.css';

import M from 'materialize-css/dist/js/materialize.min.js';
import Dropdown from 'react-kiwi-dropdown';

import firebase from "firebase";

import Chips, { Chip } from 'react-chips';

const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export default class SimCard extends React.Component {
	constructor (props) {
    super(props)
    this.state = {
      chips: ['United States of America'],
      startDate: '',
      endDate: '',
      selectedBuyCity: 'Mumbai',
      selectedBuyCityOptions: 'Mumbai',
      
    }
    this.startDate = React.createRef();
    this.endDate = React.createRef();

    this.onChangeChips = this.onChangeChips.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeChips = this.onChangeChips.bind(this);
    this.setInitialDate = this.setInitialDate.bind(this);

     this.onChangeBuyCity = this.onChangeBuyCity.bind(this);

  }

    componentDidMount() {

    	var todayDate = new Date();
    	var startDateInitial = month[Number(todayDate.getMonth())]+' ' + todayDate.getDate()+', '+todayDate.getFullYear();

    	var nextDate = todayDate.setMonth(todayDate.getMonth()+3)
    	var e = new Date(nextDate);
    	// var endDateInitial = e.getDate()+'/'+ (Number(e.getMonth())+1)+'/'+e.getFullYear();

      var endDateInitial = month[Number(e.getMonth())]+' ' + e.getDate()+', '+e.getFullYear();

    	this.setInitialDate(startDateInitial, endDateInitial)

        // var context = this;
        // document.addEventListener('DOMContentLoaded', function () {
        //     var start = document.querySelectorAll('.datepicker');
        //     M.Datepicker.init(start, {
        //         format: "mm/dd/yyyy",
        //         autoClose: true,
        //         onClose: context.handleDate
        //     });
        // });
        this.getCurrentDate()
        
    }

    componentDidUpdate(){
      var elem = document.getElementById('startDate');
         M.Datepicker.init(elem,{
            onClose:()=>{

            }
        });


      var elem_2 = document.getElementById('endDate');
         M.Datepicker.init(elem_2,{
            onClose:()=>{

            }
        });
    }


    setInitialDate(start, end){
    	this.setState({
    		startDate: start,
      	endDate: end
    	})
    }

handleDate(){

	this.setState({
	    startDate: this.startDate.current.value,
	    endDate: this.endDate.current.value,
	})


}

handleChange(e){

      

        this.setState({
            [e.target.id]: e.target.value
        })
    }

uniqueNumber(){
    var date = new Date();
    var un = ''+date.getDate()+(date.getMonth()+1)+date.getFullYear()+date.getHours()+date.getMinutes()+date.getMilliseconds();
    return un
  }


getCurrentDate(){
     var currentDate = new Date()
     const enquiryDate = currentDate.getDate() +'-'+(currentDate.getMonth()+1)+'-'+currentDate.getFullYear();
     this.setState({
          currentDateFormatted: enquiryDate
     })
  }


submitSimEnquiry(){

  if (global.login==true) {
      if (this.state.chips.length==0) {
        M.toast({html: '<span className="yellow-text">Select minimum one country</span>'})
          
      }else{
        this.sendSimCardEnquiry()
      }
    }else{
      var instance = M.Modal.getInstance(document.getElementById('auth-form'));
      instance.open();

      var modalBox = document.getElementById('auth-form');
      modalBox.setAttribute('opened-from', 'sim-form');
    }  
}

sendSimCardEnquiry(){
    var enquiryId = this.uniqueNumber()

    var simEnquiry = 
      {
       id: enquiryId,
       name: global.userData.providerData[0].displayName,
       phone: global.phone,
       status: 'new',
       subject: 'Sim enquiry',
       code:4,
       chips: this.state.chips,
       startDate: this.state.startDate,
       endDate: this.state.endDate,
       date: this.state.currentDateFormatted,
       selectedBuyCity: this.state.selectedBuyCity, 
       selectedBuyCityOptions: this.state.selectedBuyCityOptions

      }

    firebase.firestore().collection("enquries").doc(enquiryId).set(simEnquiry)
    .then((doc) => {
        var baseurl = 'http://'+ window.location.host

        var mailBody = '<div>  <div style="text-align: center; margin-top:50px"> <img src="https://img.icons8.com/clouds/344/service-bell.png" style="height: 100px; width: 100px"></td>  </div><table style="width: 100%;">  <tr  style="text-align: center;"><td style="width: 50%;" align="center"> <div><div>  <h3 style="text-align: center;border: 1px solid #26a69a; padding: 10px; color: #26a69a; width:400px">You have recieved a new enquiry</h3></div><div style="text-align: center;">  '+global.userData.providerData[0].displayName+' has submitted a sim enquiry <br/>  contact: '+ global.phone+'</div><div style="margin-top: 50px">  <a href="'+baseurl+'/admin" style="text-align: center;background:#26a69a; padding: 10px; color: white; width:100px; border-radius:5px; margin-top:50px; cursor: pointer;">View Details</a></div></div>    </td>   </tr></table>  <div style="text-align: center; background: #26a69a; margin-top:50px"> <div><img src="https://voice-278216.web.app/logo_small.png" style="width: 170px; height: 50px"></div>  </div></div>'

        this.sendMail('Sim enquiry recieved', mailBody )

        M.toast({html: '<span className="yellow-text">Thank you. Your request has been submitted successfully</span>'})
        })
      .catch(error => {
        console.log('firebase Error::'+error)
        M.toast({html: '<span className="yellow-text">Unable to submit enquiry</span>'})
         })
}

sendMail(subject, body){

  window.Email.send({
    Host: global.mailHost,
    Username : global.mailUser,
    Password : global.password,
    To : global.adminEmail,
    From : global.mailUser,
    Subject : subject,
    Body : body,
  }).then(
    // console.log('Mail send successfully'+global.adminEmail)
  );

  }


onChangeBuyCity(option){
    this.setState({
    selectedBuyCity: option.value, 
    selectedBuyCityOptions: option.content
  })

}




onChangeChips(chips){

    this.setState({ chips:chips });
  }



  render() {
    return (
        <div className="col s12 m6 row  w-100p formContainer" style={{marginLeft:20, marginRight:20}}>
          <div className="row" style={{height:400}}>
                <div className="col s12">
                  <ul className="tabs transparent">
                    <li className="tab col s12"><a className="active white-text" href="#sim-card">SIM CARD</a></li>
                  </ul>
                </div>
                <div id="sim-card" className="col s12">
                    <div className="row s12" style={{marginTop:30}}>
                    	<div className="white-text t-left f-12 p-bottom-5" style={{paddingLeft:10}}>Select Country </div>
                        <div className="col s12" id="multi_country_select_01">
                          <Chips
					          value={this.state.chips}
					          onChange={this.onChangeChips}
					          suggestions={global.coutryMultiSelectList}

					        />
                        </div>
                    </div>

                    <div className="row">

                        <div className="col s12 m6" >
                          <div className="white-text t-left f-12 p-bottom-5">Valid From Date</div>
                          <input
	                            type="text"
	                            className="datepicker white"
	                            id="startDate"
	                            onChange={(e)=>{this.handleChange(e)}}
	                            value={this.state.startDate}
	                            ref={this.startDate}
	                            style={{paddingLeft:5}}
	                        />
                        </div>
                        <div className="col s12 m6 relative r-top-10"  id="city_01">
                          <div className="white-text t-left f-12 p-bottom-5">Valid Till Date</div>
                          <input
	                            type="text"
	                            className="datepicker white"
	                            id="endDate"
	                            onChange={(e)=>{this.handleChange(e)}}
	                            value={this.state.endDate}
	                            ref={this.endDate}
	                            style={{paddingLeft:5}}
	                        />
                        </div>
                    </div>

                    <div className="relative "  id="city_06">
                          <div className="white-text t-left f-12 p-bottom-5">My nearest location </div>
                          <img className="flag-position"  width="25" height="25" src={'https://img.icons8.com/fluent-systems-regular/344/user-location.png'} style={{marginRight:5, marginLeft:10}} />
                          <Dropdown
                            options={global.buyCity}
                            onChange={this.onChangeBuyCity}
                            buttonLabel={this.state.selectedBuyCityOptions}
                            resetValue={''}
                            selectedOption={this.state.selectedBuyCity}
                          />
                        </div>

                    

                    <div className="row">
                        <div className="input-field col s12">
                          <a className="waves-effect waves-light btn-large w-100p" onClick={()=>{this.submitSimEnquiry()}}>ENQUIRY SIM PLANS</a>
                        </div>
                    </div>
                </div>
                

              </div>
        </div>
    );
  }
}
