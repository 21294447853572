import React, { Component } from 'react';
import Home from './pages/Home';
import About from './pages/About';
import Admin from './pages/Admin';
import MyOrders from './pages/MyOrders';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicy from './pages/PrivacyPolicy';


import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import '@fortawesome/fontawesome-free/css/all.min.css';

import Footer from './components/Footer';


import './helper.js';
import './city-helper.js';
import './currency-country-helper.js';
import './county-helper.js';
import './age-helper.js';

import firebase from "firebase";

// Your web app's Firebase configuration
//old Key
  // var firebaseConfig = {
  //   apiKey: "AIzaSyCCfnb3ibHy8AUWDJHZNH1Lv7Btl68olY4",
  //   authDomain: "voice-278216.firebaseapp.com",
  //   databaseURL: "https://voice-278216.firebaseio.com",
  //   projectId: "voice-278216",
  //   storageBucket: "voice-278216.appspot.com",
  //   messagingSenderId: "154084698153",
  //   appId: "1:154084698153:web:3d44e9f39a560abe3d9438"
  // };

var firebaseConfig = {
  apiKey: "AIzaSyAdhmlkyeP7htYYI68xDvPXI9VWR4rik78",
    authDomain: "book-forex.firebaseapp.com",
    projectId: "book-forex",
    storageBucket: "book-forex.appspot.com",
    messagingSenderId: "22178336191",
    appId: "1:22178336191:web:fc0f014ad428d1d367e50a",
    measurementId: "G-0V5T90P0RX"
  }
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  const app = firebase.app();

 

export default class App extends Component {
  constructor (props) {
      super(props)
      this.state = {
        data:[],
        loadingFinished: false

      }  

      this.getPhone = this.getPhone.bind(this)
      this.getPaginatedData = this.getPaginatedData.bind(this)
      // this.sendMail = this.sendMail.bind(this);
    }

    

  componentDidMount(){
     // M.AutoInit();
     this.userLoggedIn()
     
     M.Carousel.init({
      fullWidth: true,
      indicators: true
    });

     this.getDataFromFiretore()


     // const script = document.createElement("script");
     // script.src = "https://smtpjs.com/v3/smtp.js";
     // script.async = true;

     // document.body.appendChild(script);

     // <script src="https://smtpjs.com/v3/smtp.js"></script>  
     // this.sendMail()
     // console.log(window.location.href)
     // console.log(window.location.host)
  }


  

  getPaginatedData(){
    firebase.firestore().collection("cities")
        .orderBy("population")
        .limit(2).get()
    .then((querySnapshot)=> {
        // Get the last document
        var  last = querySnapshot.docs[querySnapshot.docs.length - 1];


        querySnapshot.forEach((doc)=> {
            // doc.data() is never undefined for query doc snapshots
           
        });
         
        this.setState({
          lastState:last
         })

        })
      .catch(error => {
        console.log('firebase Error::'+error)
       
         })

      

}

getNextSet(){

 
  var last = this.state.lastState

  firebase.firestore().collection("cities")
  .orderBy('population')
  .startAfter(last)
  .limit(2).get()
  .then((querySnapshot)=> {
        var  last = querySnapshot.docs[querySnapshot.docs.length - 1];

        querySnapshot.forEach((doc)=> {
            // doc.data() is never undefined for query doc snapshots
            
        });

        this.setState({
          lastState:last
         })

        })
      .catch(error => {
        console.log('firebase Error::'+error)
       
         })
      
}





  userLoggedIn(){

    // firebase.auth().signOut();

    var loginFlag = false
    firebase.auth().onAuthStateChanged((user)=> {
      if (user) {
        // User is signed in.
        console.log('User is   signed in >>>>>>>>>>.'+JSON.stringify(user.providerData[0].uid))
        loginFlag=true;
        global.login = true;
        global.userData = user;
        this.getPhone(user.uid);
      }else{
        // console.log('User is not signed in >>>>>>>>>>.')
      }
    })
  }

  



  getPhone(id){
    var refrenceFB = firebase.database().ref('users/' + id);
    refrenceFB.on('value', (snapshot) =>{
      const data = snapshot.val();
      global.phone = data.phone;
      global.admin = data.admin;
      global.localAdmin = data.localAdmin;
      global.adminLocation = data.location;
      
      this.setState({
         localAdmin : data.localAdmin,
         admin: data.admin,
         adminLocation: data.location,
         loadingFinished: true
      })

      
    });
  }



  filteredData(){

    firebase.firestore().collection("orderlist").where("selectedBuyCity", "in", ['Delhi','Pune']).get()
    .then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            // doc.data() is never undefined for query doc snapshots
        });
    })
      .catch(error => {
            console.log('firebase Error::'+error)
           })
  }
  


   getDataFromFiretore(){

      firebase.firestore().collection("rate").doc("master").get()
      .then((doc) => {

          this.setState({
            data: doc.data().data
          })
      })
      .catch(error => {
            console.log('firebase Error::'+error)
           })
      
    }

    loginNow(){
      var instance = M.Modal.getInstance(document.getElementById('auth-form'));
      instance.open();

      var modalBox = document.getElementById('auth-form');
      modalBox.setAttribute('opened-from', 'nav-bar');
    }


    openMobileMenu(){
      var elems = document.getElementById('mobile-sidebar');
      var instances = M.Sidenav.init(elems)
      instances.open()
    }

    signout(){
      console.log('>>>>>>>>>>>>>>>>>>>')
      // firebase.auth().signOut();
      firebase.auth().signOut().then(function() {
        console.log('Signed Out');
        global.login = false;
        global.userData={};
        global.phone = null;
        global.admin = false;
        global.localAdmin = false;
        global.adminLocation = null;
        window.location.href="/"

      }, function(error) {
        console.error('Sign Out Error', error);
      });
      
    }

    sendSampleMail(){

    }



  render() {
    return (
    <Router>
        <div >  
          <script type="text/javascript" charSet="utf8" src="https://cdn.datatables.net/1.10.22/js/jquery.dataTables.js"></script>

          
          
          <nav className="nav-extended white" id="app-nav">
              <div className="nav-wrapper">
                <a href="/" className="brand-logo"><img src='/logo_small.png' style={{height:60, paddingLeft:20}}/></a>
                <a style={{paddingLeft:10}} onClick={()=>{this.openMobileMenu()}} className="hide-on-large-only"><i className="fas fa-bars blue-text text-darken-4"></i></a>
                <ul id="nav-mobile" className="right hide-on-med-and-down">
                  <li><a href="/" className="blue-text text-darken-4">Home</a></li>
                  <li><a href="/about" className="blue-text text-darken-4">About</a></li>
                  {global.login === true?
                    <li><a href="/myorders" className="blue-text text-darken-4">Account</a></li>
                    :
                    <li><a href="#!" className="blue-text text-darken-4" onClick={()=>{this.loginNow()}}>Login</a></li>
                  }
                  {this.state.localAdmin === true?
                    <li><a href="/admin" className="blue-text text-darken-4">Admin</a></li> 
                  :null
                  }
                  {global.login === true?
                     <li><a href="#!" onClick={()=>{this.signout()}} className="blue-text text-darken-4">Logout</a></li>
                    :
                    null
                  }
                </ul>
              </div>
              
            </nav> 

            <ul className="sidenav" id="mobile-sidebar">
              <li><a href="/">Home</a></li>
              <li><a href="/about">About</a></li>
              {global.login=== true?
                <li><a href="/myorders">Account</a></li>
                :
                <li onClick={()=>{this.loginNow()}}><a >Login</a></li>
              }
              {global.localAdmin === true?
                <li><a href="/admin">Admin</a></li>
                :null
              }
              {global.login === true?
                     <li><a href="#!" onClick={()=>{this.signout()}}>Logout</a></li>
                    :
                    null
                  }
            </ul>

            

          <Switch>
              <Route exact path='/' component={()=><Home data={this.state.data}/>} />
              <Route path='/about' component={()=><About/>} />
              <Route path='/admin' component={()=><Admin data={this.state.data}/>} />
              <Route path='/terms-of-use' component={TermsOfUse} />
              <Route path='/privacy-policy' component={PrivacyPolicy} />
              {this.state.loadingFinished === true?
                <Route path='/myorders' component={MyOrders} />
                :null
              }
          </Switch>

          <Footer/>

        </div>
        
      </Router>
    );
  }
}
