import React, { Component } from 'react';
import readXlsxFile from 'read-excel-file'

import firebase from "firebase";
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: 'CURRENCY',
    selector: 'currency',
    sortable: true,
  },
  {
    name: 'BUY FOREX',
    selector: 'buy_forex_rate',
    sortable: true,
    right: true,
  },
  {
    name: 'SELL FOREX',
    selector: 'sell_forex_rate',
    sortable: true,
    right: true,
  },
  {
    name: 'BUY FOREX CARD',
    selector: 'buy_forex_card_rate',
    sortable: true,
    right: true,
  },
  {
    name: 'SELL FOREX CARD',
    selector: 'sell_forex_card_rate',
    sortable: true,
    right: true,
  },
  {
    name: 'WIRE TRANSFER',
    selector: 'wire_transfer',
    sortable: true,
    right: true,
  },
];



export default class RateTable extends React.Component {

	constructor (props) {
	    super(props)
	    this.state = {
	     	data:this.props.data

	    }  
	   
	  }

	componentDidMount(){
	
  }




  render() {
    return (
        <DataTable
	        title="RATE CHART"
	        columns={columns}
	        pagination={true}
	        paginationPerPage={20}
	        paginationComponentOptions={{rowsPerPageText: ''}}
	        data={this.state.data}
	      />
    );
  }
}
