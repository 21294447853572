import React, { Component } from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';
import HomeSlide from '../components/home/HomeSlide';
import HomeForm from '../components/home/HomeForm';
import MoneyTransfer from '../components/home/MoneyTransfer';
import HomeMiddleSection from '../components/home/HomeMiddleSection';
import ForexCard from '../components/home/ForexCard';
import SimCard from '../components/home/SimCard';
import TravelInsurance from '../components/home/TravelInsurance';
import Others from '../components/home/Others';

import RateTable from '../components/RateTable';

import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

import Authentication from '../components/common/Authentication';
import Enquiry from '../components/common/Enquiry';



export default class Home extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      data: this.props.data,
      

    }   
    // console.log('props admin 1>>>>>'+this.props.data)
  }

  componentDidMount() {

    // M.Tabs.init(this.Tabs_2);
    M.AutoInit();
    // M.Modal.init(elems, options)
    // console.log('props admin>>>>>'+this.props.data)
  }

  


	
  render() {
    return (
        <div  style={{paddingLeft:10, paddingRight:10}}>
          <nav className="nav-extended white" id="home-nav" style={{marginBottom:10}}>
              <div className="nav-content">
                <ul className="tabs tabs-transparent"  ref={Tabs => { this.Tabs_2 = Tabs; }} id="tabs-home">
                  <li className="tab"><a href="#exchange-currency" id="excahnge-currency-nav" className="blue-text text-darken-4  active">Exchange Currency</a></li>
                  <li className="tab"><a className="blue-text text-darken-4 " id="money-transfer-nav" href="#money-transfer">Money Transfer</a></li>
                  <li className="tab"><a href="#forex-card" className="blue-text text-darken-4">Forex Card</a></li>
                  <li className="tab"><a href="#sim-card" className="blue-text text-darken-4">International Sim Card</a></li>
                  <li className="tab"><a href="#travel-insurance" className="blue-text text-darken-4">Travel Insurance</a></li>
                  <li className="tab"><a href="#other-services" className="blue-text text-darken-4">Others</a></li>
                </ul>
              </div>
            </nav>


            <div  className="row  center-align banner-form"   style={{ backgroundImage:`url(/stock_2.jpg)`, }} >
              <HomeSlide />

              <div id="exchange-currency" className="">
                <div className="center-align banner-form"   style={{ backgroundImage:`url(/stock_2.jpg)`}}>
                  <HomeForm  data={this.state.data}/>
                </div>
              </div>

              <div id="money-transfer" className="">
              <div className="center-align banner-form"   style={{ backgroundImage:`url(/stock_2.jpg)`}}>
                <MoneyTransfer    data={this.state.data}/>
              </div>
              </div>

              <div id="forex-card" className="">
              <div className="center-align banner-form"   style={{ backgroundImage:`url(/stock_2.jpg)`}}>
                <ForexCard   data={this.state.data}/>
              </div>
              </div>

              <div id="sim-card" className="">
              <div className="center-align banner-form"   style={{ backgroundImage:`url(/stock_2.jpg)`}}>
                <SimCard/>
              </div>
              </div>

              <div id="travel-insurance" className="">
                <div className="center-align banner-form"   style={{ backgroundImage:`url(/stock_2.jpg)`}}>
                  <TravelInsurance/>
                </div>
              </div>

              <div id="other-services" className="">
                <div className="center-align banner-form"   style={{ backgroundImage:`url(/stock_2.jpg)`}}>
                  <Others/>
                </div>
              </div>

            </div>
            
            
            <HomeMiddleSection data={this.state.data}/>

            <div id="home-full-rate" className="modal bottom-sheet">
              <div className="modal-content">
                <RateTable data={this.state.data}/>
              </div>
              <div className="modal-footer">
                <a href="#!" className="modal-close waves-effect waves-green btn">CLOSE</a>
              </div>
            </div>

        </div>
    );
  }
}

