import React, { Component } from 'react';
import RateAdmin from '../components/admin/RateAdmin';
import EnquiryAdmin from '../components/admin/EnquiryAdmin';
import OrdersAdmin from '../components/admin/OrdersAdmin';

import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

export default class Admin extends React.Component {

	constructor (props) {
    super(props)
    this.state = {
     	data: this.props.data

    }   
    // console.log('props admin 1>>>>>'+this.props.data)
  }

	componentDidMount() {
    M.Tabs.init(this.Tabs);
    // console.log('props admin>>>>>'+this.props.data)
  }

  
	
  render() {
    return (
        <div >
          <div className="row">
		    <div className="col s12">
		      <ul className="tabs" ref={Tabs => { this.Tabs = Tabs; }} id="tabs-admin" className="tabs">
		        <li className="tab col s4"><a href="#rate" className="active">Rate</a></li>
		        <li className="tab col s4"><a  href="#enquiry">Enquiry</a></li>
		        <li className="tab col s4"><a href="#orders"  >Orders</a></li>
		        {/*<li className="tab col s3"><a href="#test4">Test 4</a></li>*/}
		      </ul>
		    </div>
		    <div id="rate" className="col s12">
		    	<RateAdmin data={this.state.data}/>
		    </div>
		    <div id="enquiry" className="col s12">
		    	<EnquiryAdmin/>
		    </div>
		    <div id="orders" className="col s12">
		    	<OrdersAdmin/>
		    </div>
		   {/* <div id="test4" className="col s12">Test 4</div>*/}
		  </div>
        </div>
    );
  }
}
