import React  from 'react';
import firebase from "firebase";

import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

export default class ForexCardOrderDetails extends React.Component {
	constructor (props) {
      super(props)
      this.state = {
        selectedRow: this.props.selectedRow,
        originalSeletedRow: this.props.selectedRow
      }  

      
    }


    componentWillReceiveProps(nextProps) {

    
    this.setState({
      selectedRow: nextProps.selectedRow,
      originalSeletedRow: nextProps.selectedRow
    })
  }


  updateStatus(e){


        this.state.selectedRow.status=e

        firebase.firestore().collection("orderlist").doc(this.state.selectedRow.id).update({status:e})

       

      M.toast({html: '<span className="yellow-text">Order marked '+e+'</span>'})
      this.props.updateActionDoneData(e)
  }


  render() {
  	if (this.state.selectedRow === undefined) {
      return(
        <div>props undefined</div>
      )

    }else if (this.state.selectedRow.trade==='buy') {
    return (
        <div  style={{marginTop:60}} >
            <div className="row">
              <div className="d-flex col s12 m6">
                <div>
                  {this.state.selectedRow.status==='new'?
                    <i className="fas fa-circle orange-text" style={{fontSize:9}}></i>
                    :
                    <div>
                    {this.state.selectedRow.status==='completed'?
                      <i className="fas fa-circle green-text" style={{fontSize:9}}></i>
                      :
                      <i className="fas fa-circle grey-text" style={{fontSize:9}}></i>
                    }
                    </div>
                  } 
                </div>
                <div style={{marginLeft:5}}>
                  <div>{this.state.selectedRow.id}</div>
                </div>
              </div>
              <div className="col s12 m6">
                <div className="grey-text">Date:  {this.state.selectedRow.date} </div>
              </div>
            </div>


            <div className="row">
              <div className="col s12 m6 d-flex">
                <div className="grey-text">Name:  </div>
                <div  style={{marginLeft:5}}>
                  <div>{this.state.selectedRow.name}</div>
                  <div  style={{fontSize:11}}>{this.state.selectedRow.phone}</div>

                </div>
              </div>
              <div className="col s12 m6 d-flex">
                <div className="grey-text">Service:  </div>
                <div  style={{marginLeft:5}}>
                  <div>{this.state.selectedRow.setSelectedOption}</div>
                </div>
              </div>
            </div>


            <div className="d-flex" style={{marginBottom:10}}>
              <div><i className="fas fa-map-marker-alt"></i></div>
              <div style={{marginLeft:5}}>{this.state.selectedRow.selectedBuyCityOptions}</div>
            </div>


            <div className="row">
              <div className="col s12 m6 white b-dotted t-center" style={{height:80, fontSize:18}}>
                <div className="t-center d-flex f-align-center" style={{marginTop:15}}>

                  <img className=""  width="30" height="25" src={'https://cdn.countryflags.com/thumbs/'+this.state.selectedRow.buyWantValue+'/flag-square-250.png'} style={{marginRight:5}} />
                  <div className="flex-center" style={{marginLeft:5}} >{this.state.selectedRow.buyWantOption}</div>
                </div>

                <div className="t-center d-flex f-align-center">
                  <div className="grey-text" style={{fontSize:12}}>Forex Reload amount</div>
                  <div className="flex-center" style={{marginLeft:5}}  >{this.state.selectedRow.forexAmountBuy}</div>
                </div>

              </div>

              <div className="col s12 m6 white b-dotted" style={{height:80, fontSize:18}}>
                <div className="t-center d-flex f-align-center"  style={{marginTop:15}}>
                  <div className="grey-text" style={{fontSize:12}}>INR Amount</div>
                  <div className="flex-center" style={{marginLeft:5}}  >{this.state.selectedRow.inrAmountBuy}</div>
                </div>

                <div className="t-center d-flex f-align-center">
                  <div className="grey-text" style={{fontSize:12}}>Rate applied</div>
                  <div className="flex-center" style={{marginLeft:5}} >{this.state.selectedRow.rate}</div>
                </div>
              </div>
            </div>

            <div style={{fontSize:12}} className="t-right">Status: {this.state.selectedRow.status}</div>

            <div className="row">
              <div className="">
                {this.state.selectedRow.status === 'new'?
                  <a href="#!" className="waves-effect waves-green btn" onClick={()=>{this.updateStatus('completed')}} style={{marginRight:10,marginTop:10}}>Order Completed</a>
                  :null

                }
                {this.state.selectedRow.status === 'cancelled'?
                <a href="#!" className="waves-effect waves-green btn-flat green-border-light"  onClick={()=>{this.updateStatus('new')}} style={{marginTop:10}}>Re-Open Order</a>
                :
                <a href="#!" className="waves-effect waves-green btn-flat green-border-light"  onClick={()=>{this.updateStatus('cancelled')}}  style={{marginTop:10}}>Cancel Order</a>
                }
              </div>
            </div>
        </div>
      );
  	}else{
      return (
        <div  style={{marginTop:60}} >
            <div className="row">
              <div className="d-flex col s12 m6">
                <div>
                  {this.state.selectedRow.status==='new'?
                    <i className="fas fa-circle orange-text" style={{fontSize:9}}></i>
                    :
                    <div>
                    {this.state.selectedRow.status==='completed'?
                      <i className="fas fa-circle green-text" style={{fontSize:9}}></i>
                      :
                      <i className="fas fa-circle grey-text" style={{fontSize:9}}></i>
                    }
                    </div>
                  } 
                </div>
                <div style={{marginLeft:5}}>
                  <div>{this.state.selectedRow.id}</div>
                </div>
              </div>
              <div className="col s12 m6">
                <div className="grey-text">Date:  {this.state.selectedRow.date} </div>
              </div>
            </div>


            <div className="row">
              <div className="col s12 m6 d-flex">
                <div className="grey-text">Name:  </div>
                <div  style={{marginLeft:5}}>
                  <div>{this.state.selectedRow.name}</div>
                  <div  style={{fontSize:11}}>{this.state.selectedRow.phone}</div>

                </div>
              </div>
              <div className="col s12 m6 d-flex">
                <div className="grey-text">Service:  </div>
                <div  style={{marginLeft:5}}>
                  <div>{this.state.selectedRow.setSelectedOption}</div>
                </div>
              </div>
            </div>


            <div className="d-flex" style={{marginBottom:10}}>
              <div><i className="fas fa-map-marker-alt"></i></div>
              <div style={{marginLeft:5}}>{this.state.selectedRow.selectedBuyCityOptions}</div>
            </div>


            <div className="row">
              <div className="col s12 m6 white b-dotted t-center" style={{height:80, fontSize:18}}>
                <div className="t-center d-flex f-align-center" style={{marginTop:15}}>

                  <img className=""  width="30" height="25" src={'https://cdn.countryflags.com/thumbs/'+this.state.selectedRow.sellHavingValue+'/flag-square-250.png'} style={{marginRight:5}} />
                  <div className="flex-center" style={{marginLeft:5}} >{this.state.selectedRow.sellHavingOption}</div>
                </div>

                <div className="t-center d-flex f-align-center">
                  <div className="grey-text" style={{fontSize:12}}>Forex Upload amount</div>
                  <div className="flex-center" style={{marginLeft:5}}  >{this.state.selectedRow.forexAmountSell}</div>
                </div>

              </div>

              <div className="col s12 m6 white b-dotted" style={{height:80, fontSize:18}}>
                <div className="t-center d-flex f-align-center"  style={{marginTop:15}}>
                  <div className="grey-text" style={{fontSize:12}}>INR Amount</div>
                  <div className="flex-center" style={{marginLeft:5}}  >{this.state.selectedRow.inrAmountSell}</div>
                </div>

                <div className="t-center d-flex f-align-center">
                  <div className="grey-text" style={{fontSize:12}}>Rate applied</div>
                  <div className="flex-center" style={{marginLeft:5}} >{this.state.selectedRow.rate}</div>
                </div>
              </div>
            </div>

            <div style={{fontSize:12}} className="t-right">Status: {this.state.selectedRow.status}</div>

            <div className="row">
              <div className="">
                {this.state.selectedRow.status === 'new'?
                  <a href="#!" className="waves-effect waves-green btn" onClick={()=>{this.updateStatus('completed')}} style={{marginRight:10,marginTop:10}}>Order Completed</a>
                  :null

                }
                {this.state.selectedRow.status === 'cancelled'?
                <a href="#!" className="waves-effect waves-green btn-flat green-border-light"  onClick={()=>{this.updateStatus('new')}} style={{marginTop:10}}>Re-Open Order</a>
                :
                <a href="#!" className="waves-effect waves-green btn-flat green-border-light"  onClick={()=>{this.updateStatus('cancelled')}}  style={{marginTop:10}}>Cancel Order</a>
                }
              </div>
            </div>
        </div>
        );
    }
  }
}
