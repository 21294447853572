import React, { Component } from 'react';
import firebase from "firebase";

import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

export default class MoneyTransferLayout extends React.Component {
	constructor (props) {
	    super(props)
	    this.state = {
	    	selectedRow: this.props.selectedRow,
	    	originalSeletedRow: this.props.selectedRow
	    }  

	    
	  }


	componentWillReceiveProps(nextProps) {

    console.log('++++++++///++++++'+JSON.stringify(nextProps.selectedRow))
    this.setState({
    	selectedRow: nextProps.selectedRow,
	    originalSeletedRow: nextProps.selectedRow
    })
  }

	updateStatus(e){

   

        this.state.selectedRow.status=e

        firebase.firestore().collection("enquries").doc(this.state.selectedRow.id).update({status:e})

      M.toast({html: '<span className="yellow-text">Enquiry marked '+e+'</span>'})
      this.props.updateActionDoneData(e)
  }



	


  render() {
  	if (this.state.selectedRow === undefined) {
  		return(
  			<div>props undefined</div>
  		)

  	}else{
	    return (
	        <div  style={{marginTop:60}} >
	          <div className="row">
		          <div className="d-flex col s12 m6">
		          	<div>
		          		{this.state.selectedRow.status==='new'?
		          			<i className="fas fa-circle orange-text" style={{fontSize:9}}></i>
		          			:
		          			<div>
		          			{this.state.selectedRow.status==='completed'?
		          				<i className="fas fa-circle green-text" style={{fontSize:9}}></i>
		          				:
		          				<i className="fas fa-circle grey-text" style={{fontSize:9}}></i>
		          			}
		          			</div>
		          		}	
		          	</div>
		          	<div style={{marginLeft:5}}>
		          		<div>{this.state.selectedRow.id}</div>
		          	</div>
		          </div>
		          <div className="col s12 m6">
		          	<div className="grey-text">Date:  {this.state.selectedRow.date} </div>
		          </div>
	          </div>
	          
	          <div className="row">
	          	<div className="col s12 m6 d-flex" style={{marginTop:5}}>
	          		<div className="grey-text">Name:  </div>
	          		<div  style={{marginLeft:5}}>
	          			<div>{this.state.selectedRow.name}</div>
	          			<div  style={{fontSize:11}}>{this.state.selectedRow.phone}</div>
	          		</div>
	          	</div>
	          	<div className="col s12 m6 d-flex" style={{marginTop:5}}>
	          	  <div className="grey-text">Organization: </div>
	          	  <div style={{marginLeft:5}}>{this.state.selectedRow.organization} </div>
	          	</div>
	          </div>

	          <div className="d-flex" style={{marginBottom:10}}>
              	<div><i className="fas fa-map-marker-alt"></i></div>
              	<div style={{marginLeft:5}}>{this.state.selectedRow.city}</div>
              </div>
	          
	          
	          <div className="row">
	          	<div className="col s12 white b-dotted" style={{height:40, fontSize:18, display:'flex', justifyContent:'center', alignItems:'center'}}>
	          		<div className="grey-text" style={{fontSize:10, marginRight:10}}>Volume: </div>
	          		<div className="flex-center" >{this.state.selectedRow.volume}</div>
	          	</div>
	          </div>

	          <div style={{fontSize:12}} className="t-right">Status: {this.state.selectedRow.status}</div>

	          <div className="row">
	          	<div className="">
	          		{this.state.selectedRow.status === 'new'?
	          			<a href="#!" className="waves-effect waves-green btn" onClick={()=>{this.updateStatus('completed')}} style={{marginRight:10, marginTop:10}}>Enquiry Done</a>
	          			:null

	          		}
	          		<view>
	          			{this.state.selectedRow.status === 'completed' || this.state.selectedRow.status === 'cancelled'?
	          				<a href="#!" className="waves-effect waves-green btn-flat green-border-light"  onClick={()=>{this.updateStatus('new')}} style={{marginTop:10}}>Re-Open</a>
	          				:
	          				<a href="#!" className="waves-effect waves-green btn-flat green-border-light"  onClick={()=>{this.updateStatus('cancelled')}} style={{marginTop:10}}>Cancel Enquiry</a>
	          			}
	          		</view>
	          	</div>
	          </div>
	         
	        </div>
	    );
    }
  }
}
