import React from 'react';
import firebase from "firebase";

import DataTable from 'react-data-table-component';
import { useTable } from 'react-table'

import ForexOrderDetails from './orderPopupLayouts/ForexOrderDetails';
import MoneyTransferDetails from './orderPopupLayouts/MoneyTransferDetails';
import ForexCardOrderDetails from './orderPopupLayouts/ForexCardOrderDetails';


import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"


export default class OrdersAdmin extends React.Component {

  constructor (props) {
      super(props)
      this.state = {
        orderData:[],
        selectedRow:{},
        loadingCompleted: false
      }  
      this.renderTable = this.renderTable.bind(this)
      this.openModalOrders = this.openModalOrders.bind(this)
      this.getDataFromFiretore = this.getDataFromFiretore.bind(this)
      
    }

  componentDidMount(){
        
        this.getDataFromFiretore(0);
        

    }

    getDataFromFiretore(pagination){
        if (global.login===true && global.adminLocation!== null) {
        var tempArray = []
        var nextButton = false
       

       var emailId = global.userData.providerData[0].uid

       
       if (pagination===0) {
          if (global.admin===false) {
            var locations = global.adminLocation.split(',')
            var query = firebase.firestore().collection("orderlist").where("selectedBuyCity", "in", locations).limit(global.perPageLength)
          
          }else{
            var query = firebase.firestore().collection("orderlist").orderBy("date", "desc").limit(global.perPageLength)
          }
        
       }else{
        var lastSet= this.state.lastState
        if (global.admin===false) {
            var locations = global.adminLocation.split(',')
            
            var query = firebase.firestore().collection("orderlist").where("selectedBuyCity", "in", locations).limit(global.perPageLength).startAfter(lastSet)
            
          }else{
            var query = firebase.firestore().collection("orderlist").orderBy("date", "desc").limit(global.perPageLength).startAfter(lastSet)
          }
        
       }

        query.get()
        .then((querySnapshot)=> {

          var  last = querySnapshot.docs[querySnapshot.docs.length - 1];

          querySnapshot.forEach((doc)=> {
              // doc.data() is never undefined for query doc snapshots

              tempArray.push(doc.data())
          });
      

          if (tempArray.length===global.perPageLength) {
              nextButton=true
            }else{
              nextButton=false
            }
         
                 
                 
                  this.setState({
                    orderData: tempArray,
                    loadingCompleted: true,
                    lastState: last,
                    nextButton:nextButton
                  },()=>{

                     if (pagination===0) {
                    $('#table_id_admin_orders_tab').DataTable({
                  responsive: true,
                   "bInfo" : false,
                   "bLengthChange": false,
                   "pageLength": 50,
                   "language": { search: "" },
                   "aoColumns": [
                        { "bSortable": true },
                        { "bSortable": true },
                        { "bSortable": false }
                    ] 
                })

                }
                    })
            
              
        })
        .catch(error => {
            console.log('firebase Error::::::::::::'+error)
        })
      }
      
    }



    renderTable(){
      

      var tempData = this.state.orderData

      var allRows = []

      for (var i = 0; i < tempData.length ; i++) {
        var eachRow =   <tr key={[i]}>
                      <td className="f-left">
                        {tempData[i].status==='new'? 
                          <i className="fas fa-circle orange-text" style={{fontSize:9, paddingRight:5}}></i>
                          :
                          <div  className="f-left">
                            {tempData[i].status==='completed'?
                             <i className="fas fa-circle green-text" style={{fontSize:9, paddingRight:5}}></i>
                             :
                             <i className="fas fa-circle grey-text" style={{fontSize:9, paddingRight:5}}></i>
                            }
                          </div>
                          
                        } {tempData[i].id}
                      </td>
                      <td>
                        <div>
                        {tempData[i].code===1? 
                          <i className="fas fa-exchange-alt" style={{fontSize:12, marginRight:5}}></i>
                          :
                          <div  className="f-left">
                            {tempData[i].status===2?
                             <i className="fas fa-dolly-flatbed" style={{fontSize:12, marginRight:5}}></i>
                             :
                             <i className="far fa-credit-card" style={{fontSize:12, marginRight:5}}></i>
                            }
                          </div>
                          
                        }
                        {tempData[i].subject}
                        </div>

                        
                      </td>
                      <td className="t-right"><a className="waves-effect waves-light btn-flat green-border-light b-5 " href="#!" id={[i]} onClick={(e)=>{this.openModalOrders(e)}}>DETAILS</a></td>
                    </tr>
      allRows.push(eachRow)
      }  
      return(allRows)

    }

  

    openModalOrders(e){
      

      var selectedData = this.state.orderData[Number(e.currentTarget.id)]
      this.setState({
        selectedRow: selectedData
      },()=>{
        var a = $('#orderDetails')
        M.Modal.init(a)
      var instance = M.Modal.getInstance(a)
      instance.open()

  
      })
    
  }

  closeOrderModal(){
      var modalBox = document.getElementById('orderDetails');
      var c = modalBox.getAttribute('reload');
    if (c==='true') {
      window.location.reload(false);
    }else{
      
      var a = document.getElementById('orderDetails')
      var instance = M.Modal.getInstance(a)
      instance.close()
    }
  }

  updateActionDoneData(e){
    
  }
    

  render() {
    
      return (
          <div >

            <h5 className="black-text">ORDER LIST</h5>

            <div style={{marginBottom:100, marginTop:25}}>
              <table id="table_id_admin_orders_tab">
            <thead>
                <tr>
                    <th>Order Id</th>
                    <th>Subject</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            {this.state.orderData.length===0?<div>No data</div>:
              this.renderTable()
            }
            </tbody>
        </table>
        </div>

          {this.state.nextButton===true?
          <a class="waves-effect waves-light btn-flat green-border-light" onClick={()=>{this.getDataFromFiretore(1)}}>Next 200 records</a>
          :null
        }

        <div id="orderDetails" className="modal" ref={ModalOrder => {this.ModalOrder = ModalOrder; }} >
          <div className="modal-content">
            <h4>{this.state.selectedRow.subject}</h4>
            {this.state.selectedRow.code===1?
              <div>
                <ForexOrderDetails selectedRow={this.state.selectedRow} updateActionDoneData={this.updateActionDoneData.bind(this)}/>
              </div>
              :
              <div>
                {this.state.selectedRow.code===2?
                <MoneyTransferDetails selectedRow={this.state.selectedRow} updateActionDoneData={this.updateActionDoneData.bind(this)}/>
                :
                <ForexCardOrderDetails selectedRow={this.state.selectedRow} updateActionDoneData={this.updateActionDoneData.bind(this)}/>

                }
              </div>

            }
          </div>
          <div className="modal-footer">
            <a href="#!" className="modal-close waves-effect waves-green btn-flat" onClick={()=>{this.closeOrderModal()}}>Close</a>
          </div>
        </div>



          </div>
      );
    }
  
}
