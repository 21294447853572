import React, { Component } from 'react';
import Authentication from '../components/common/Authentication';
import M from 'materialize-css/dist/js/materialize.min.js';
import 'materialize-css/dist/css/materialize.min.css';

export default class About extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      email:'',
      password:'',
      phone:'',
      name:'',
      lastComponent: this.props.lastComponent
    }   

  }

  componentDidMount(){
    
  }



  render() {
    return (
        <div >
          <div className="t-center">
          		<h5 className="blue-text text-darken-2 t-center">About Us</h5>
          		<img src="/about_us.png" className="responsive-img" />
          	</div>

          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8">
          			<ul>
					  <li style={{listStyleType: "initial"}}>Company Registered in May 2019</li>
					  <li style={{listStyleType: "initial"}}>Company has Full Fledged Money Changing Licence</li>
					  <li style={{listStyleType: "initial"}}>Company proposes to have initial presence in Mumbai and Navi Mumbai
							e-commerce initiative in the retail foreign exchange and international money transfer space.</li>
					  <li style={{listStyleType: "initial"}}>Offer our customers a full suite of forex products consisting of currency notes, prepaid travel cards, traveler's cheques, demand drafts and wire transfers in an inexpensive and convenient way.</li>
					  <li style={{listStyleType: "initial"}}>Partner with select Banks and reputed exchange companies to fulfill the forex requirements of our customers</li>
					  <li style={{listStyleType: "initial"}}>Provides customers with the best exchange rate that is available in their area</li>
					  <li style={{listStyleType: "initial"}}>Activities related to travel</li>
					  <li style={{listStyleType: "initial"}}>Company initially proposes to start operations in Mumbai from its registered office at Opera House, Charni Road and Navi Mumbai -Koparkhirane</li>
					  <li style={{listStyleType: "initial"}}>Proposes to have Offices at Pune, Nashik, Bengaluru & Hyderabad in second phase.</li>

					  <li style={{listStyleType: "initial"}}>Company plans to collarabate with travel companies and help in travel plans.</li>
					</ul>				 
 				</div>
          		<div className="col s2"></div>
          	</div>

            <Authentication/>

        </div>
    );
  }
}
