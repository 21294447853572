import React, { Component } from 'react';
import firebase from "firebase";

import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

export default class Authentication extends React.Component {
	constructor (props) {
    super(props)
    this.state = {
      email:'',
      password:'',
      phone:'',
      name:'',
      lastComponent: this.props.lastComponent
    }   

  }

  componentDidMount(){
 
  	
  }

  verifyLogin(){
  	firebase.auth().onAuthStateChanged(function(user) {
	  if (user) {
	    // User is signed in.

	  } else {
	    // No user is signed in.
	  }
	});
  }


  signIn(){
  	var modalBox = document.getElementById('auth-form');
  	var c =	modalBox.getAttribute('opened-from');

  	firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
	  .then((user) => {
	    var a = M.Modal.init(this.authForm)
  			a.close()

  		
  		global.login = true;
        global.userData = user;

        if (c==='slide') {
        	var instance = M.Modal.getInstance(document.getElementById('enquiry-form'));
			    instance.open()
        }else if(c==='better-rate'){
          var instance = M.Modal.getInstance(document.getElementById('requestBetterRate'));
          instance.open()
          
        }else if(c==='sim-form' || c==='exchange-currency-form' || c==='transfer-currency-form' || c==='forex-card-form'){
          var instance = M.Modal.getInstance(document.getElementById('auth-form'));
          instance.close()
          M.toast({html: '<span className="yellow-text">Loggin successful</span>'})
        }else{
  			 window.location.reload(false);
        }
  	
	  })
	  .catch((error) => {
	  	console.log('error>>>>>>>>>>>>>>>'+ error.message)
	    M.toast({html: '<span className="yellow-text">'+error.message+'</span>'})
	  });
  }

  signUp(){
    if (this.state.phone.length!==10) {
      M.toast({html: '<span className="yellow-text">Enter 10 digit phone number</span>'})
    }else{
    
  	firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
	  .then((user) => {

      this.addUserData(user.user.uid)
	    global.login = true;
        global.userData = user;
        
      })
	  .catch((error) => {

	    M.toast({html: '<span className="yellow-text">'+error.message+'</span>'})
	  });
  }
}


  addUserData(id){
    firebase.database().ref('users/' + id).set({
      phone: this.state.phone,
      admin: false,
      localAdmin: false,
      location: '',
      uid:id,
      name: this.state.name,
      email: this.state.email
    })
    .then((doc) => {

          this.handleUpdateUserData()
      })
      .catch(error => {
            console.log('firebase Error::'+error)
           })
  }


  handleUpdateUserData(){
    var modalBox = document.getElementById('auth-form');
    var c = modalBox.getAttribute('opened-from');
      
      const currentUser = firebase.auth().currentUser;
        
            currentUser.updateProfile({
            displayName: this.state.name,
            firstName: this.state.name,
            phoneNumber: this.state.phone
          })
          .then(()=>{

            if (c==='slide') {
            var instance = M.Modal.getInstance(document.getElementById('enquiry-form'));
            instance.open()
          }else if(c==='better-rate'){
            var instance = M.Modal.getInstance(document.getElementById('requestBetterRate'));
            instance.open()
            
          }else if(c==='sim-form' || c==='exchange-currency-form' || c==='transfer-currency-form' || c==='forex-card-form'){
            var instance = M.Modal.getInstance(document.getElementById('auth-form'));
            instance.close()
            M.toast({html: '<span className="yellow-text">Loggin successful</span>'})
          }else{
           window.location.reload(false);
          }
            
            // this.createNodeForuser();  
            console.log('userDetails Updated>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
            var a = M.Modal.init(this.authForm)
  			a.close()

          })
          .catch(error => {
            
              console.log(error.message)
            })
    }


  changeTimesButton(){
  	console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
  }

  handleEmail(e){
  	console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>'+e.target.value)
  	this.setState({
       email: e.target.value
    })
  }

  handlePassword(e){
  	this.setState({
       password: e.target.value
    })
  }

  handleNumber(e){
  	this.setState({
       phone: e.target.value
    })
  }

  handleName(e){
  	this.setState({
       name: e.target.value
    })
  }


  render() {
    return (
    	<div id="auth-form" className="modal modal-fixed-footer" ref={authForm => { this.authForm = authForm; }}>
	        <div className="modal-content">
	             <div className="row">
				    <div className="col s12">
				      <ul className="tabs" ref={Tabs_auth => { this.Tabs_auth = Tabs_auth; }}>
				        <li className="tab col s3"><a className="active" href="#login">Login</a></li>
				        <li className="tab col s3"><a href="#signup">Sign Up</a></li>
				      </ul>
				    </div>

				    <div id="login" className="col s12">
				    	<form>
				          <div className="input-field col s12  r-top-10" style={{marginTop:50}}>
				            <input placeholder="Enter email" id="email_login" type="text" className=" black-text white" value={this.state.email} onChange={(e)=>{this.handleEmail(e)}} style={{marginTop:5, paddingLeft:5}}/>
				            <label htmlFor="email_login" className="">Email</label>
				          </div>
				          <div className="input-field col s12   r-top-10" style={{marginTop:20}}>
				            <input placeholder="Enter Password" id="password_login" type="password" className=" black-text white" value={this.state.password} onChange={(e)=>{this.handlePassword(e)}} style={{marginTop:5, paddingLeft:5}} autoComplete="on"/>
				            <label htmlFor="password_login" className="">Password</label>
				          </div> 

				          <a className="waves-effect waves-light btn-large w-100p" onClick={()=>{this.signIn()}}  style={{marginTop:20}}>Sign In</a>
				        </form>
				    </div>


				    <div id="signup" className="col s12">
				    	<div>
				          <div className="input-field col s12  r-top-10" style={{marginTop:50}}>
				            <input placeholder="Enter name" id="name" type="text" className=" black-text white" value={this.state.name} onChange={(e)=>{this.handleName(e)}} style={{marginTop:5, paddingLeft:5}}/>
				            <label htmlFor="name" className="">Name</label>
				          </div>
				          <div className="input-field col s12  r-top-10">
				            <input placeholder="Enter email" id="email_signup" type="text" className=" black-text white" value={this.state.email} onChange={(e)=>{this.handleEmail(e)}} style={{marginTop:5, paddingLeft:5}}/>
				            <label htmlFor="email_signup" className="">Email</label>
				          </div>
				          <div className="input-field col s12   r-top-10" style={{marginTop:20}}>
				            <input placeholder="Enter Password" id="password_signup" type="password" className=" black-text white" value={this.state.password} onChange={(e)=>{this.handlePassword(e)}} style={{marginTop:5, paddingLeft:5}}/>
				            <label htmlFor="password_signup" className="">Password</label>
				          </div>
				          <div className="input-field col s12   r-top-10"  style={{marginTop:20}}>
				            <input placeholder="Enter phone number" id="number_signup" type="number" className=" black-text white" value={this.state.phone} onChange={(e)=>{this.handleNumber(e)}} style={{marginTop:5, paddingLeft:5}}/>
				            <label htmlFor="number_signup" className="">Phone Number</label>
				          </div>  

				          <a className="waves-effect waves-light btn-large w-100p" onClick={()=>{this.signUp()}}  style={{marginTop:20}}>Sign Up</a>
				        </div>
				    </div>
				    
				  </div>


		        
	      </div>
	      <div className="modal-footer">
	        <a href="#!" className="modal-close waves-effect waves-green btn-flat">close</a>
      	  </div>
      </div>
    );
  }
}
