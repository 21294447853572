import React  from 'react';

import '@fortawesome/fontawesome-free/css/all.min.css';

import ReactAutocomplete from 'react-autocomplete';
import ReactFlagsSelect from 'react-flags-select';

//import css module
import 'react-flags-select/css/react-flags-select.css';

import Dropdown from 'react-kiwi-dropdown'

import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

import firebase from "firebase";


export default class ForexCard extends React.Component {

    constructor (props) {
    super(props)
    this.state = {
      value: 'INDIA (Rupee)',
      buyHavingValue: 'india',
      buyHavingOption: 'IN Rupee',

      buyWantValue: 'united-states-of-america',
      buyWantOption: 'US (Dollar)',

      sellHavingValue: 'united-states-of-america',
      sellHavingOption: 'US (Dollar)',
      sellWantValue: 'india',
      sellWantOption: 'IN Rupee',


      forexAmountBuy:'',
      inrAmountBuy:0,

      forexAmountSell:'',
      inrAmountSell:0,


      selectedOption:'Axis Bank Forex Card', 
      setSelectedOption:'💳 Axis Bank Forex Card',

      sellSelectedOption:'currency-note', 
      sellSetSelectedOption:'💳 Currency Note',


      selectedBuyCity: 'Mumbai',
      selectedBuyCityOptions: 'Mumbai',

      data: this.props.data,
      rateAppliedSell:0,
      rateAppliedBuy:0

    }
    this.onChangeBuyService = this.onChangeBuyService.bind(this);
    this.onChangeSellService = this.onChangeSellService.bind(this);


    this.onChangeBuyCity = this.onChangeBuyCity.bind(this);
    this.onChangeBuyHaving = this.onChangeBuyHaving.bind(this);
    this.onChangeBuyWant = this.onChangeBuyWant.bind(this);
    this.onChangeSellHaving = this.onChangeSellHaving.bind(this);
    this.onChangeSellWant = this.onChangeSellWant.bind(this);

    this.handleChangeForexAmountBuy = this.handleChangeForexAmountBuy.bind(this);
    this.handleChangeForexAmountSell = this.handleChangeForexAmountSell.bind(this);

    this.handleChangeINRAmountSell = this.handleChangeINRAmountSell.bind(this);
    this.handleChangeINRAmountBuy = this.handleChangeINRAmountBuy.bind(this);

  }

    componentDidMount() {
      this.setInitialBuyRate();
      this.getCurrentDate()
  }


  getCurrentDate(){
     var currentDate = new Date()
     const enquiryDate = currentDate.getDate() +'-'+(currentDate.getMonth()+1)+'-'+currentDate.getFullYear();
     this.setState({
          currentDateFormatted: enquiryDate
     })
  }

  onChangeBuyService(option){
  
  this.setState({
    selectedOption: option.value, 
    setSelectedOption: option.content
  })
  
}


setInitialBuyRate(){
  
    var a = this.state.data.find(x=>x.currency==="US Dollar")
    
    if (a!==undefined) {
      

      this.setState({
      rateAppliedBuy: ((Math.round(a.buy_forex_card_rate*100)/100)).toFixed(2) ,
      rateAppliedSell: ((Math.round(a.sell_forex_card_rate*100)/100)).toFixed(2) 
    })
    }
}


onChangeSellService(option){
  this.setState({
    sellSelectedOption: option.value, 
    sellSetSelectedOption: option.content
  })
}


onChangeBuyCity(option){
    this.setState({
    selectedBuyCity: option.value, 
    selectedBuyCityOptions: option.content
  },()=>{console.log(this.state.selectedBuyCity)})

}

onChangeBuyHaving(option){
  
  this.setState({
    buyHavingValue: option.value, 
    buyHavingOption: option.content
  })
  
}

onChangeSellHaving(option){
  
  var a = this.state.data.find(x=>x.currency===option.content)
  var updateSellINRAmount = this.state.forexAmountSell* Number(a.sell_forex_card_rate)

  this.setState({
    sellHavingValue: option.value, 
    sellHavingOption: option.content,
    rateAppliedSell: ((Math.round(a.sell_forex_card_rate*100)/100)).toFixed(2),
    inrAmountSell: ((Math.round(updateSellINRAmount*100)/100)).toFixed(2)
  })
}

onChangeBuyWant(option){ 
 

  var a = this.state.data.find(x=>x.currency===option.content)
  // console.log(JSON.stringify(a.buy_forex_card_rate))

  var updateBuyINRAmount = this.state.forexAmountBuy* Number(a.buy_forex_card_rate)


  this.setState({
    buyWantValue: option.value, 
    buyWantOption: option.content,
    rateAppliedBuy: ((Math.round(a.buy_forex_card_rate*100)/100)).toFixed(2),
    inrAmountBuy: ((Math.round(updateBuyINRAmount*100)/100)).toFixed(2)
  })
  
}

onChangeSellWant(option){
  this.setState({
    sellWantValue: option.value, 
    sellWantOption: option.content
  })
}

handleChangeForexAmountBuy(e){
    var updateBuyINRAmount = e.target.value* this.state.rateAppliedBuy

    this.setState({
       forexAmountBuy: e.target.value,
       inrAmountBuy: ((Math.round(updateBuyINRAmount*100)/100)).toFixed(2)
    })
}

handleChangeINRAmountBuy(e){
    var updateForexAmount = e.target.value/ this.state.rateAppliedBuy

    this.setState({
       inrAmountBuy: e.target.value,
       forexAmountBuy:  ((Math.round(updateForexAmount*100)/100)).toFixed(2)
    })
}

handleChangeForexAmountSell(e){
    console.log(e.target.value )
    var updateSellINRAmount = e.target.value* this.state.rateAppliedSell

    this.setState({
       forexAmountSell: e.target.value,
       inrAmountSell: ((Math.round(updateSellINRAmount*100)/100)).toFixed(2)
    })
}


handleChangeINRAmountSell(e){
    var updateForexAmount = e.target.value/ this.state.rateAppliedSell

    this.setState({
       inrAmountSell: e.target.value,
       forexAmountSell:  ((Math.round(updateForexAmount*100)/100)).toFixed(2)
    })
}


uniqueNumber(){
    var date = new Date();
    var un = ''+date.getDate()+(date.getMonth()+1)+date.getFullYear()+date.getHours()+date.getMinutes()+date.getMilliseconds();
    return un
  }


submitForexCardOrder(){
  if (global.login===true) {
      if (this.state.forexAmountBuy===0) {
        M.toast({html: '<span className="yellow-text">Forex amount cannot be blank</span>'})
      }else{
        this.AddForexCardBuyOrderToFirebase()
      }
    }else{
      var instance = M.Modal.getInstance(document.getElementById('auth-form'));
      instance.open();

      var modalBox = document.getElementById('auth-form');
      modalBox.setAttribute('opened-from', 'forex-card-form');
    }


}


submitForexCardSellOrder(){
  if (global.login===true) {
      if (this.state.forexAmountSell===0) {
        M.toast({html: '<span className="yellow-text">Forex amount cannot be blank</span>'})
      }else{
        this.AddForexCardSellOrderToFirebase()
      }
    }else{
      var instance = M.Modal.getInstance(document.getElementById('auth-form'));
      instance.open();

      var modalBox = document.getElementById('auth-form');
      modalBox.setAttribute('opened-from', 'forex-card-form');
    }


}



AddForexCardBuyOrderToFirebase(){
  var orderId = this.uniqueNumber()

  var forexCardBuyOrder = 
      {
        forexAmountBuy: this.state.forexAmountBuy,
        inrAmountBuy: this.state.inrAmountBuy,

        id: orderId,
        name:  global.userData.providerData[0].displayName,
        phone: global.phone,       
        rate: this.state.rateAppliedBuy,

        selectedOption: this.state.selectedOption,
        setSelectedOption: this.state.setSelectedOption,

        selectedBuyCityOptions: this.state.selectedBuyCityOptions,
        selectedBuyCity: this.state.selectedBuyCity,

        buyWantOption: this.state.buyWantOption,
        buyWantValue: this.state.buyWantValue,

        buyHavingOption: this.state.buyHavingOption,
        buyHavingValue: this.state.buyHavingValue,

        status: 'new',
        subject: 'Forex Card Reload',
        trade: 'buy',

        code: 3,
        date: this.state.currentDateFormatted,
        email: global.userData.providerData[0].uid

      }

    firebase.firestore().collection("orderlist").doc(orderId).set(forexCardBuyOrder)
    .then((doc) => {
        var baseurl = 'http://'+ window.location.host

        var mailBody = '<div>  <div style="text-align: center; margin-top:50px"> <img src="https://img.icons8.com/clouds/344/service-bell.png" style="height: 100px; width: 100px"></td>  </div><table style="width: 100%;">  <tr  style="text-align: center;"><td style="width: 50%;" align="center"> <div><div>  <h3 style="text-align: center;border: 1px solid #26a69a; padding: 10px; color: #26a69a; width:400px">You have recieved a new order</h3></div><div style="text-align: center;">  '+global.userData.providerData[0].displayName+' has placed a forex card buy order <br/>  contact: '+ global.phone+'</div><div style="margin-top: 50px">  <a href="'+baseurl+'/admin" style="text-align: center;background:#26a69a; padding: 10px; color: white; width:100px; border-radius:5px; margin-top:50px; cursor: pointer;">View Details</a></div></div>    </td>   </tr></table>  <div style="text-align: center; background: #26a69a; margin-top:50px"> <div><img src="https://voice-278216.web.app/logo_small.png" style="width: 170px; height: 50px"></div>  </div></div>'

        this.sendMail('Forex card buy order recieved', mailBody )

        M.toast({html: '<span className="yellow-text">Thank you. Your order placed successfully</span>'})
        })
      .catch(error => {
        console.log('firebase Error::'+error)
        M.toast({html: '<span className="yellow-text">Unable to place order</span>'})
         })
}


AddForexCardSellOrderToFirebase(){
    var orderId = this.uniqueNumber()
    
    var forexCardSellOrder = 
      {
        forexAmountSell: this.state.forexAmountSell,
        inrAmountSell: this.state.inrAmountSell,

        id: orderId,
        name:  global.userData.providerData[0].displayName,
        phone: global.phone,       
        rate: this.state.rateAppliedSell,

        // sellSelectedOption: this.state.sellSelectedOption,
        // sellSetSelectedOption: this.state.sellSetSelectedOption,

        selectedBuyCityOptions: this.state.selectedBuyCityOptions,
        selectedBuyCity: this.state.selectedBuyCity,

        selectedOption: this.state.selectedOption,
        setSelectedOption: this.state.setSelectedOption,

        sellHavingOption: this.state.sellHavingOption,
        sellHavingValue: this.state.sellHavingValue,



        status: 'new',
        subject: 'Forex Card Upload',
        trade: 'sell',

        code:3,
        date: this.state.currentDateFormatted,
        email: global.userData.providerData[0].uid

      }

    firebase.firestore().collection("orderlist").doc(orderId).set(forexCardSellOrder)
    .then((doc) => {
        var baseurl = 'http://'+ window.location.host

        var mailBody = '<div>  <div style="text-align: center; margin-top:50px"> <img src="https://img.icons8.com/clouds/344/service-bell.png" style="height: 100px; width: 100px"></td>  </div><table style="width: 100%;">  <tr  style="text-align: center;"><td style="width: 50%;" align="center"> <div><div>  <h3 style="text-align: center;border: 1px solid #26a69a; padding: 10px; color: #26a69a; width:400px">You have recieved a new order</h3></div><div style="text-align: center;">  '+global.userData.providerData[0].displayName+' has placed a forex card sell order <br/>  contact: '+ global.phone+'</div><div style="margin-top: 50px">  <a href="'+baseurl+'/admin" style="text-align: center;background:#26a69a; padding: 10px; color: white; width:100px; border-radius:5px; margin-top:50px; cursor: pointer;">View Details</a></div></div>    </td>   </tr></table>  <div style="text-align: center; background: #26a69a; margin-top:50px"> <div><img src="https://voice-278216.web.app/logo_small.png" style="width: 170px; height: 50px"></div>  </div></div>'

        this.sendMail('Forex card sell order recieved', mailBody )

        M.toast({html: '<span className="yellow-text">Thank you. Your order placed successfully</span>'})
        })
      .catch(error => {
        console.log('firebase Error::'+error)
        M.toast({html: '<span className="yellow-text">Unable to place order</span>'})
         })
}

sendMail(subject, body){

  window.Email.send({
    Host: global.mailHost,
    Username : global.mailUser,
    Password : global.password,
    To : global.adminEmail,
    From : global.mailUser,
    Subject : subject,
    Body : body,
  }).then(
    // console.log('Mail send successfully'+global.userData.providerData[0].uid)
  );

  }


 
  render() {
    return (
    	<div className="col s12 m6 row  w-100p formContainer" style={{marginLeft:20, marginRight:20}}>
			<div className="row" style={{height:400}}>
                <div className="col s12">
                  <ul className="tabs transparent" >
                    <li className="tab col s6"><a className="active white-text" href="#reload-forex">RELOAD FOREX</a></li>
                    <li className="tab col s6"><a className="white-text" href="#upload-forex">UPLOAD FOREX</a></li>
                  </ul>
                </div>
                <div id="reload-forex" className="col s12">
                    <div className="row" style={{marginTop:30}}>
                    	<div className="col s12 m6 relative " id="country_07">
                          <div className="white-text t-left f-12 p-bottom-5">My nearest location </div>
                          <img className="flag-position"  width="25" height="25" src={'https://img.icons8.com/fluent-systems-regular/344/user-location.png'} style={{marginRight:5, marginLeft:10}} />
                          <Dropdown
                            options={global.buyCity}
                            onChange={this.onChangeBuyCity}
                            buttonLabel={this.state.selectedBuyCityOptions}
                            resetValue={''}
                            selectedOption={this.state.selectedBuyCity}
                          />
                        </div>
                        
                        <div className="col s12 m6 relative r-top-10" id="country_08">
                              <div className="white-text t-left f-12 p-bottom-5">Currency in </div>
                              <img className="flag-position"  width="30" height="25" src={'https://cdn.countryflags.com/thumbs/'+this.state.buyWantValue+'/flag-square-250.png'} style={{marginRight:5, marginLeft:10}} />
                              <Dropdown
                                options={global.coutryList}
                                onChange={this.onChangeBuyWant}
                                buttonLabel={this.state.buyWantOption}
                                resetValue={''}
                                selectedOption={this.state.buyWantValue}
                              />
                            
                        </div>
                    </div>

                    <div className="row">

                        <div className="col s12 m12" >
                          <div className="white-text t-left f-12 p-bottom-5">What kind of card do you have ? </div>
                          <Dropdown
                            options={global.forexCardService}
                            onChange={this.onChangeBuyService}
                            buttonLabel={this.state.setSelectedOption}
                            resetValue={''}
                            selectedOption={this.state.selectedOption}
                          />
                        </div>
                        
                    </div>

                    <div className="row">
                        <div className="input-field col s12 m6">
                           <input placeholder="Enter amount" id="forexCardAmountBuy" type="number" className=" black-text white" value={this.state.forexAmountBuy} onChange={this.handleChangeForexAmountBuy} style={{marginTop:5, paddingLeft:5}}/>
                          <label htmlFor="forexCardAmountBuy" className="white-text">Forex Amount</label>
                        </div>
                        <div className="input-field col s12 m6 r-top-10">
                          <input placeholder="Enter amount" id="inrCardAmountBuy" type="number" className=" black-text white" value={this.state.inrAmountBuy} onChange={this.handleChangeINRAmountBuy} style={{marginTop:5, paddingLeft:5}}/>
                          <label htmlFor="inrCardAmountBuy" className="white-text">INR Amount</label>
                        </div>
                    </div>

                    <div className="row m-0">
                      <div className="col s6">
                      </div>
                      <div className="col s6">
                          {this.state.rateAppliedBuy===0?null:
                          <div className="white-text"><i className="fas fa-chart-line white-text" style={{fontSize:20}}></i> Current Rate: {this.state.rateAppliedBuy}</div>
                          }
                      </div>
                    </div>

                    <div className="row">
                        <div className="input-field col s6">
                          {this.state.inrAmountBuy===0?null:
                          <h5 className="white-text">Total: ₹ {this.state.inrAmountBuy}</h5>
                          }
                        </div>
                        <div className="input-field col s6">
                          <a className="waves-effect waves-light btn-large w-100p" onClick={()=>{this.submitForexCardOrder()}}>RELOAD FOREX</a>
                        </div>
                    </div>
                </div>
                
                {/*sell form*/}
                <div id="upload-forex" className="col s12">
                    <div className="row" style={{marginTop:30}}>
                    	<div className="col s12 m6 relative " id="country_01">
                          <div className="white-text t-left f-12 p-bottom-5">My nearest location </div>
                          <img className="flag-position"  width="25" height="25" src={'https://img.icons8.com/fluent-systems-regular/344/user-location.png'} style={{marginRight:5, marginLeft:10}} />
                          <Dropdown
                            options={global.buyCity}
                            onChange={this.onChangeBuyCity}
                            buttonLabel={this.state.selectedBuyCityOptions}
                            resetValue={''}
                            selectedOption={this.state.selectedBuyCity}
                          />
                        </div>
                        
                        <div className="col s12 m6 relative r-top-10" id="country_01">
                              <div className="white-text t-left f-12 p-bottom-5">Currency in </div>
                              <img className="flag-position"  width="30" height="25" src={'https://cdn.countryflags.com/thumbs/'+this.state.sellHavingValue+'/flag-square-250.png'} style={{marginRight:5, marginLeft:10}} />
                              
                              <Dropdown
                                options={global.coutryList}
                                onChange={this.onChangeSellHaving}
                                buttonLabel={this.state.sellHavingOption}
                                resetValue={''}
                                selectedOption={this.state.sellHavingValue}
                              />
                        </div>
                    </div>

                    <div className="row">

                        <div className="col s12 m12" >
                          <div className="white-text t-left f-12 p-bottom-5">What kind of card do you have ? </div>
                          <Dropdown
                            options={global.forexCardService}
                            onChange={this.onChangeBuyService}
                            buttonLabel={this.state.setSelectedOption}
                            resetValue={''}
                            selectedOption={this.state.selectedOption}
                          />
                        </div>
                        
                    </div>

                    <div className="row">
                        <div className="input-field col s12 m6">
                           <input placeholder="Enter amount" id="forexCardAmountSell" type="number" className=" black-text white" value={this.state.forexAmountSell} onChange={this.handleChangeForexAmountSell} style={{marginTop:5, paddingLeft:5}}/>
                          <label htmlFor="forexCardAmountSell" className="white-text">Forex Amount</label>
                        </div>
                        <div className="input-field col s12 m6 r-top-10">
                          <input placeholder="Enter amount" id="inrCardAmountSell" type="number" className=" black-text white" value={this.state.inrAmountSell} onChange={this.handleChangeINRAmountSell} style={{marginTop:5, paddingLeft:5}}/>
                          <label htmlFor="inrCardAmountSell" className="white-text">INR Amount</label>
                        </div>
                    </div>

                    <div className="row m-0">
                      <div className="col s6">
                      </div>
                      <div className="col s6">
                          {this.state.rateAppliedSell===0?null:
                          <div className="white-text"><i className="fas fa-chart-line white-text" style={{fontSize:20}}></i> Current Rate: {this.state.rateAppliedSell}</div>
                          }
                      </div>
                    </div>

                    <div className="row">
                        <div className="input-field col s6">
                          {this.state.inrAmountSell===0?null:
                            <h5 className="white-text">Total: ₹ {this.state.inrAmountSell}</h5>
                          }
                        </div>
                        <div className="input-field col s6">
                          <a className="waves-effect waves-light btn-large w-100p"  onClick={()=>{this.submitForexCardSellOrder()}}>UPLOAD FOREX</a>
                        </div>
                    </div>
                </div>

              </div>
		</div>

 	)}
}