import React from 'react';

import Review from './Review';
import RateSlider from './RateSlider';

import firebase from "firebase";
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

import Dropdown from 'react-kiwi-dropdown';


export default class HomeMiddleSection extends React.Component {
     constructor (props) {
         super(props)
         this.state = {
          callMeBackPhone:'',
          callMeBackName:'',

          betterRateValue: 'united-states-of-america',
          betterRateOption: 'US Dollar',

          betterRateCityValue: 'Mumbai',
          betterRateCityOptions: 'Mumbai',

          betterRateINRAmount: '',
          betterRateForexAmount:'',

          data: this.props.data,

          rateAppliedBetterRate: 0,
          currentDateFormatted: '',
          
          selectedBuyCity: 'Mumbai',
          selectedBuyCityOptions: 'Mumbai',

         }  
         this.onChangeBuyCity = this.onChangeBuyCity.bind(this)
       }


componentDidMount() {
      // M.updateTextFields();
      this.setInitialBuyRate();
      this.getCurrentDate();
  }

  getCurrentDate(){
     var currentDate = new Date()
     const enquiryDate = currentDate.getDate() +'-'+(currentDate.getMonth()+1)+'-'+currentDate.getFullYear();
     this.setState({
          currentDateFormatted: enquiryDate
     })
  }

  setInitialBuyRate(){
  
    var a = this.state.data.find(x=>x.currency==="US Dollar")
    
    if (a!==undefined) {
      

      this.setState({
      rateAppliedBetterRate: ((Math.round(a.buy_forex_rate*100)/100)).toFixed(2) 
    })
    }
}

     callMeBackPhone(e){
     this.setState({
       callMeBackPhone: e.target.value
    })
  }

  callMeBackName(e){
     this.setState({
       callMeBackName: e.target.value
    })
  }

  validateCallMeBackForm(){
      
     if (this.state.callMeBackName.length===0) {
          M.toast({html: '<span className="yellow-text">Name cannot be blank</span>'})
     }else if(this.state.callMeBackPhone.length!==10){
          M.toast({html: '<span className="yellow-text">Phone number must be of 10 digit</span>'})
     }else{
      this.updateCallbackEnquiryToFirebase()
     }
  }

  uniqueNumber(){
    var date = new Date();
    var un = ''+date.getDate()+(date.getMonth()+1)+date.getFullYear()+date.getHours()+date.getMinutes()+date.getMilliseconds();
    return un
  }


  onBetterRateClicked(){
     if (global.login===true) {
          var modalBox = document.getElementById('requestBetterRate');
          modalBox.setAttribute('opened-from', 'better-rate');

          var instance = M.Modal.getInstance(document.getElementById('requestBetterRate'));
          instance.open()
     }else{
          var instance = M.Modal.getInstance(document.getElementById('auth-form'));
          instance.open();

          var modalBox = document.getElementById('auth-form');
          modalBox.setAttribute('opened-from', 'better-rate');
     }
     
  }


  updateCallbackEnquiryToFirebase(){
    var enquiryId = this.uniqueNumber()

    var betterRateEnquiry = 
      {
       id: enquiryId,
       name: this.state.callMeBackName,
       phone: this.state.callMeBackPhone,
       status: 'new',
       subject: 'Call me back enquiry',
       code:2,
       date: this.state.currentDateFormatted,
       selectedBuyCity: this.state.selectedBuyCity, 
       selectedBuyCityOptions: this.state.selectedBuyCityOptions
      }

    firebase.firestore().collection("enquries").doc(enquiryId).set(betterRateEnquiry)
    .then((doc) => {
        var a = document.getElementById('callBackModal')
        var instance = M.Modal.getInstance(a)
        instance.close()

        var baseurl = 'http://'+ window.location.host

        var mailBody = '<div>  <div style="text-align: center; margin-top:50px"> <img src="https://img.icons8.com/clouds/344/service-bell.png" style="height: 100px; width: 100px"></td>  </div><table style="width: 100%;">  <tr  style="text-align: center;"><td style="width: 50%;" align="center"> <div><div>  <h3 style="text-align: center;border: 1px solid #26a69a; padding: 10px; color: #26a69a; width:400px">You have recieved a call me back enquiry</h3></div><div style="text-align: center;">  '+this.state.callMeBackName+' has submitted call me back enquiry<br/>  contact: '+ this.state.callMeBackPhone+'</div><div style="margin-top: 50px">  <a href="'+baseurl+'/admin" style="text-align: center;background:#26a69a; padding: 10px; color: white; width:100px; border-radius:5px; margin-top:50px; cursor: pointer;">View Details</a></div></div>    </td>   </tr></table>  <div style="text-align: center; background: #26a69a; margin-top:50px"> <div><img src="https://voice-278216.web.app/logo_small.png" style="width: 170px; height: 50px"></div>  </div></div>'

        this.sendMailCallMeBack('Call me back enquiry recieved', mailBody )


        M.toast({html: '<span className="yellow-text">Thank you. Your request has been submitted successfully</span>'})
        })
      .catch(error => {
        console.log('firebase Error::'+error)
        M.toast({html: '<span className="yellow-text">Unable to submit enquiry</span>'})
         })

  }

  sendMailCallMeBack(subject, body){

  window.Email.send({
    Host: global.mailHost,
    Username : global.mailUser, //notification sender mail
    Password : global.password,
    To : global.adminEmail, //Admin reciever mail
    From : global.mailUser,
    Subject : subject,
    Body : body,
  }).then(
    // console.log('Mail send successfully'+global.adminEmail)
  );

  }


  betterRateForexAmount(e){

     var updateBuyINRAmount = e.target.value* this.state.rateAppliedBetterRate

     this.setState({
       betterRateForexAmount: e.target.value,
       betterRateINRAmount: ((Math.round(updateBuyINRAmount*100)/100)).toFixed(2)
    })
  }

  betterRateINRAmount(e){

     var updateForexRAmount = e.target.value/ this.state.rateAppliedBetterRate
     this.setState({
       betterRateINRAmount: e.target.value,
       betterRateForexAmount: ((Math.round(updateForexRAmount*100)/100)).toFixed(2)
    })
  }


  onChangeBetterRateCountry(option){

     var a = this.state.data.find(x=>x.currency===option.content)
     var updateBuyINRAmount = this.state.betterRateForexAmount* Number(a.buy_forex_rate)

     this.setState({
          betterRateValue: option.value, 
          betterRateOption: option.content,
          rateAppliedBetterRate: ((Math.round(a.buy_forex_rate*100)/100)).toFixed(2),
          betterRateINRAmount: ((Math.round(updateBuyINRAmount*100)/100)).toFixed(2)
     },()=>{console.log(this.state.rateAppliedBetterRate)})
  }


  onChangeBetterRateCity(option){
     this.setState({
          betterRateCityValue: option.value,
          betterRateCityOptions:  option.content
     })
  }

  submitBetterRate(){
     if (this.state.betterRateForexAmount===0) {
          M.toast({html: '<span className="yellow-text">Forex amount cannot be blank</span>'})
     }else{
          this.updateBetterRateEnquiryToFirebase()
     }
  }

  updateBetterRateEnquiryToFirebase(){
    var enquiryId = this.uniqueNumber()

    var betterRateEnquiry = 
      {
       id: enquiryId,
       name: global.userData.providerData[0].displayName,
       phone: global.phone,
       status: 'new',
       subject: 'Request better rate enquiry',
       code:3,
       forexAmount: this.state.betterRateForexAmount,
       inrAmount: this.state.betterRateINRAmount,
       city: this.state.betterRateCityOptions,
       selectedBuyCity: this.state.betterRateCityValue,
       selectedBuyCityOptions: this.state.betterRateCityOptions,
       currency: this.state.betterRateOption,
       flag: this.state.betterRateValue,
       date: this.state.currentDateFormatted,
       rate: this.state.rateAppliedBetterRate
      }

    firebase.firestore().collection("enquries").doc(enquiryId).set(betterRateEnquiry)
    .then((doc) => {
        var a = document.getElementById('requestBetterRate')
        var instance = M.Modal.getInstance(a)
        instance.close()

        var baseurl = 'http://'+ window.location.host

        var mailBody = '<div>  <div style="text-align: center; margin-top:50px"> <img src="https://img.icons8.com/clouds/344/service-bell.png" style="height: 100px; width: 100px"></td>  </div><table style="width: 100%;">  <tr  style="text-align: center;"><td style="width: 50%;" align="center"> <div><div>  <h3 style="text-align: center;border: 1px solid #26a69a; padding: 10px; color: #26a69a; width:400px">You have recieved a new enquiry</h3></div><div style="text-align: center;">  '+global.userData.providerData[0].displayName+' has submitted a better rate enquiry<br/>  contact: '+ global.phone+'</div><div style="margin-top: 50px">  <a href="'+baseurl+'/admin" style="text-align: center;background:#26a69a; padding: 10px; color: white; width:100px; border-radius:5px; margin-top:50px; cursor: pointer;">View Details</a></div></div>    </td>   </tr></table>  <div style="text-align: center; background: #26a69a; margin-top:50px"> <div><img src="https://voice-278216.web.app/logo_small.png" style="width: 170px; height: 50px"></div>  </div></div>'

        this.sendMail('Requested better rate enquiry', mailBody )


        M.toast({html: '<span className="yellow-text">Thank you. Your request has been submitted successfully</span>'})
        })
      .catch(error => {
        console.log('firebase Error::'+error)
        M.toast({html: '<span className="yellow-text">Unable to submit enquiry</span>'})
         })

  }


  onChangeBuyCity(option){
    this.setState({
    selectedBuyCity: option.value, 
    selectedBuyCityOptions: option.content
  },()=>{console.log(this.state.selectedBuyCity)})

}


sendMail(subject, body){

  window.Email.send({
    Host: global.mailHost,
    Username : global.mailUser,
    Password : global.password,
    To : global.adminEmail,
    From : global.mailUser,
    Subject : subject,
    Body : body,
  }).then(
    console.log('Mail send successfully'+global.adminEmail)
  );

  }



  emailTemplate(nameUser, subject){
    var a = ''
  return(a)
  }
     


  render() {
    return (
        <div>
          
          <div className="row">
          	<div className="col s12 m12 l4 row d-flex f-align-center">
          		<div className="col s3"><img alt="callbackicon"  src="/user_call_back.png" style={{borderRadius:10, marginTop:10}} className="relative" /></div>
          		<div className="col s9">
          			<a className="waves-effect waves-teal btn-flat green-border b-5 f-wt-500 teal-text modal-trigger" href="#callBackModal">REQUEST CALL BACK</a>
          			<div className="grey-text f-12" style={{marginTop:5, width:200}}>( Provide details and we'll call you back shortly )</div>
          		</div>
          	</div>
          	<div className="col s12 m12 l4 row d-flex f-align-center">
          		<div className="col s3"><img alt="moneyicon" src="/money.png" style={{borderRadius:10, marginTop:10}} className="relative" /></div>
          		<div className="col s9">
          			<a className="waves-effect waves-teal btn-flat green-border b-5 f-wt-500 teal-text" href="#!" onClick={()=>{this.onBetterRateClicked()}}>REQUEST BETTER RATE</a>
          			<div className="grey-text f-12" style={{marginTop:5, width:200}}>( We will send you best possible rate depending on the city, amount etc )</div>
          		</div>
          	</div>
          	{/*<div className="col s12 m12 l4 row d-flex f-align-center">
                          <div className="col s3"><img alt="moneyicon" src="/money.png" style={{borderRadius:10, marginTop:10}} className="relative" /></div>
                          <div className="col s9">
                            <a className="waves-effect waves-teal btn-flat green-border b-5 f-wt-500 teal-text">SET RATE ALEART</a>
                            <div className="grey-text f-12" style={{marginTop:5, width:200}}>( Set alert when your desired rate is available )</div>
                          </div>
                        </div>*/}
          </div>


          <div className="row grey lighten-3" style={{height:200}}>
          	<div className="col s1 m2 p-20"></div>
          	<div className="col s10 m4 p-20">
          		<div className="d-flex f-align-center">
          			<img alt="monitor" src="/monitor.png" style={{borderRadius:10, marginTop:10, height:50}} className="relative" />
          			<img alt="mobile" src="/mobile.png" style={{borderRadius:10, marginTop:10, height:50}} className="relative" />
          		</div>
          		<div className="f-wt-500" style={{marginTop:10}}>Trade from web and mobile</div>
          		<div className="grey-text f-12" style={{width:200, marginTop:5}} >Trade with reliable secure platform. Easy on the go trade options with the mobile app</div>
          	</div>
          	<div className="col hide-on-small-only m4 t-left">
          		<img  src="/banner_03.png" style={{height:200, marginTop:10}} className="relative " />
          	</div>
          	<div className="col s1 m2 p-20"></div>
          </div>

          <RateSlider/>


          <div className="row center-align" style={{marginTop:50}}>
          	<h5 className="f-wt-500">Why book-forex.com?</h5>
          </div>

          <div className="row">
          	<div className="col s12 m4 ">
          		<img alt="search" src="/search.png" style={{borderRadius:10, marginTop:10}} className="relative" />
          		<div className="f-16 f-wt-500 t-left">TRANSPARENT</div>
          		<div className="f-12 t-left p-right-150">
          			Buy Forex Online at Live Rates. No made-up daily rates. Buy Forex Cards at exact Inter-bank/ Mid-market Exchange Rates. Lock-in the current forex rate for as much as 3 days. Rate alerts - Get notified when the foreign exchange rates reach the level you want
          		</div>
          	</div>

          	<div className="col s12 m4 ">
          		<img alt="badge" src="/badge.png" style={{borderRadius:10, marginTop:10}} className="relative" />
          		<div className="f-16 f-wt-500 t-left">RELIABLE</div>
          		<div className="f-12 t-left p-right-150">
          			Reliable and reputed channel partners. Verified RBI Authorized Banks and Money Changers. Streamlined Online Forex order processing with pre-defined vendor SLAs. Regular status updates and live tracking of your Forex Order. Watermarked documents to prevent document piracy & misuse
          		</div>
          	</div>

          	<div className="col s12 m4 ">
          		<img alt="shield" src="/shield.png" style={{borderRadius:10, marginTop:10}} className="relative" />
          		<div className="f-16 f-wt-500 t-left">BEST VALUE</div>
          		<div className="f-12 t-left p-right-150">
          			Save 2.5% - 6.5% over banks & money changers. Save 3.5% - 6% over International credit/debit cards. Save 6.5% - 13% over airport exchange counters. Get the best exchange rates around you compared across hundreds of banks and money changers
          		</div>
          	</div>
          </div>


          <Review/>

          <div className="row center-align">
          	<h5 className="f-wt-500">How it works? </h5>
          </div>

          <div className="row">
          	<div className="col s12 m4 ">
          		<img alt="list" src="/list.png" style={{borderRadius:10, marginTop:10}} className="relative" />
          		<div className="f-16 f-wt-500 t-left">01</div>
          		<div className="f-12 t-left p-right-150">
          			Select your city, enter your forex requirements and complete a forex order booking online
          		</div>
          	</div>

          	<div className="col s12 m4 ">
          		<img alt="rebound" src="/rebound.png" style={{borderRadius:10, marginTop:10}} className="relative" />
          		<div className="f-16 f-wt-500 t-left">02</div>
          		<div className="f-12 t-left p-right-150">
          			The bank or money exchanger with the best and the most reliable service standards around you will be selected
          		</div>
          	</div>

          	<div className="col s12 m4 ">
          		<img alt="calender" src="/calender.png" style={{borderRadius:10, marginTop:10}} className="relative" />
          		<div className="f-16 f-wt-500 t-left">03</div>
          		<div className="f-12 t-left p-right-150">
          			The bank or money exchanger selected will schedule your door delivery or you can pick up the order from their location
          		</div>
          	</div>
          </div>


          <div id="callBackModal" className="modal">
              <div className="modal-content">
                <h4>Call Me Back</h4>
                <div className="t-center">
                    <img alt="callback" src="/callback.png" style={{height:100}}/>
                </div>
                <div>
                    <div className="input-field col s12   r-top-10"  style={{marginTop:20}}>
                      <input placeholder="Full name" id="call-me-back-name-enquiry" type="text" className=" black-text white" value={this.state.callMeBackName} onChange={(e)=>{this.callMeBackName(e)}} style={{marginTop:5, paddingLeft:5}}/>
                      <label htmlFor="call-me-back-name-enquiry" className="">Name</label>
                    </div> 

                    <div className="input-field col s12   r-top-10"  style={{marginTop:20}}>
                      <input placeholder="10 digit phone number" id="call-me-back-phone-enquiry" type="number" className=" black-text white" value={this.state.callMeBackPhone} onChange={(e)=>{this.callMeBackPhone(e)}} style={{marginTop:5, paddingLeft:5}}/>
                      <label htmlFor="call-me-back-phone-enquiry" className="">Phone</label>
                    </div> 

                    <div className="relative "  id="city_06">
                        <div className="white-text t-left f-12 p-bottom-5">My nearest location </div>
                        <img alt="flag" className="flag-position"  width="25" height="25" src={'https://img.icons8.com/fluent-systems-regular/344/user-location.png'} style={{marginRight:5, marginLeft:10}} />
                        <Dropdown
                          options={global.buyCity}
                          onChange={this.onChangeBuyCity}
                          buttonLabel={this.state.selectedBuyCityOptions}
                          resetValue={''}
                          selectedOption={this.state.selectedBuyCity}
                        />
                      </div>

                    <a href="#!" className="waves-effect waves-green btn" onClick={()=>{this.validateCallMeBackForm()}} style={{marginTop:20}}>Request Callback</a>

                </div>
              </div>
              <div className="modal-footer">
                <a href="#!"  className="modal-close waves-effect waves-green btn-flat">Close</a>
              </div>
          </div>


          <div id="requestBetterRate" className="modal">
              <div className="modal-content">
                <h4>Better rate enquiry</h4>
                <div className="t-center">
                    <img alt="rate" src="/betterrate.png" style={{height:100}}/>
                </div>
                <div className="row">
                    <div className="col s12 m6 relative " id="country_better_rate_currency_01">
                         <div className="white-text t-left f-12 p-bottom-5">I have currency in </div>
                         <img alt="flag" className="flag-position"  width="30" height="25" src={'https://cdn.countryflags.com/thumbs/'+this.state.betterRateValue+'/flag-square-250.png'} style={{marginRight:5, marginLeft:10}} />
                         <Dropdown
                           options={global.coutryList}
                           onChange={(options)=>{this.onChangeBetterRateCountry(options)}}
                           buttonLabel={this.state.betterRateOption}
                           resetValue={''}
                           selectedOption={this.state.betterRateValue}
                         /> 
                    </div>

                    <div className="col s12 m6 relative " id="country_better_rate_city_01">
                         <div className="white-text t-left f-12 p-bottom-5">Location </div>
                         <img alt="flag" className="flag-position"  width="25" height="25" src={'https://img.icons8.com/fluent-systems-regular/344/user-location.png'} style={{marginRight:5, marginLeft:10}} />
                          
                         <Dropdown
                           options={global.buyCity}
                           onChange={(options)=>{this.onChangeBetterRateCity(options)}}
                           buttonLabel={this.state.betterRateCityOptions}
                           resetValue={''}
                           selectedOption={this.state.betterRateCityValue}
                         /> 
                    </div> 
                </div>

                <div className="row" style={{marginTop:20}}>
                    <div className="input-field col s12 m6  r-top-10"  style={{marginTop:20}}>
                      <input placeholder="Forex Amount" id="better-rate-forexamount-enquiry" type="number" className=" black-text white" value={this.state.betterRateForexAmount} onChange={(e)=>{this.betterRateForexAmount(e)}} style={{marginTop:5, paddingLeft:5}}/>
                      <label htmlFor="better-rate-forexamount-enquiry" className="">Forex Amount</label>
                    </div>

                    <div className="input-field col s12 m6  r-top-10"  style={{marginTop:20}}>
                      <input placeholder="Amount" id="better-rate-inramount-enquiry" type="number" className=" black-text white" value={this.state.betterRateINRAmount} onChange={(e)=>{this.betterRateINRAmount(e)}} style={{marginTop:5, paddingLeft:5}}/>
                      <label htmlFor="better-rate-inramount-enquiry" className="">INR Amount</label>
                    </div>
               </div>
               <div className="row" style={{marginTop:20}}>
                    <div className="input-field col s12 m6  r-top-10"  style={{marginTop:20}}>
                      
                    </div>

                    <div className="input-field col s12 m6  r-top-10 t-right"  style={{marginTop:20}}>
                      <div><i className="fas fa-chart-line grey-text" style={{fontSize: 20}}></i> Current Rate: {this.state.rateAppliedBetterRate}</div>
                    </div>
               </div>

               <a href="#!" onClick={()=>{this.submitBetterRate()}}  className="waves-effect waves-green btn-large w-100p">Enquiry now</a>


              </div>
              <div className="modal-footer">
                <a href="#!"  className="modal-close waves-effect waves-green btn-flat">Close</a>
              </div>
          </div>

          


        </div>
    );
  }
}
