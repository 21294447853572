 global.coutryList =[
{ value: "united-states-of-america", content: 'US Dollar' },
{ value: "europe", content: 'Euro' },
{ value: "united-kingdom", content: 'British Pound' },
{ value: "australia", content: 'Australian Dollar' },
{ value: "canada", content: 'Canadian Dollar' },
{ value: "singapore", content: 'Singapore Dollar' },
{ value: "new-zealand", content: 'New Zealand Dollar' },
{ value: "hongkong", content: 'Hong Kong Dollar' },
{ value: "united-arab-emirates", content: 'UAE Dirham' },
{ value: "saudi-arabia", content: 'Saudi Riyal' },
{ value: "france", content: 'Swiss Franc' },
{ value: "japan", content: 'Japanese Yen' },
{ value: "sweden", content: 'Swedish Krona' },
{ value: "thailand", content: 'Thai Baht' },
{ value: "malaysia", content: 'Malaysian Ringgits' },
{ value: "china", content: 'Chinese Yuan' },
{ value: "south-africa", content: 'South African Rand' },
{ value: "oman", content: 'Omani Rial' },
{ value: "bahrain", content: 'Bahraini Dinar' },
{ value: "kuwait", content: 'Kuwaiti Dinar' },
{ value: "norway", content: 'Norwegian Krone' },
{ value: "denmark", content: 'Danish Krone' },
{ value: "indonesia", content: 'Indonesian Rupiah' },
{ value: "sri-lanka", content: 'Sri Lankan Rupee' },
{ value: "korea", content: 'Korean Won' },
{ value: "turkey", content: 'Turkish Lira' },
{ value: "russia", content: 'Russian Ruble' },
{ value: "quatar", content: 'Qatari Rial' },
{ value: "philippines", content: 'Philippine Peso' },
{ value: "vietnam", content: 'Vietnam Dong' },
{ value: "mauritius", content: 'Mauritius Rupee' },
{ value: "bangladesh", content: 'Bangladesh Taka' },
{ value: "brazil", content: 'Brazilian Real' },
{ value: "kenya", content: 'Kenyan Shilling' },
{ value: "egypt", content: 'Egyptian Pound' },
{ value: "israel", content: 'Israeli Shekel' },
{ value: "poland", content: 'Polish Zloty' },
{ value: "macau", content: 'Macau Pataca' },
{ value: "czech-republic", content: 'Czech Koruna' }]
