import React, { Component } from 'react';

export default class Footer extends React.Component {
  render() {
    return (
        <div >
          <footer className="page-footer blue darken-4">
          <div className="container">
            <div className="row">
              <div className="col l6 s12">
                <h5 className="white-text">BOOK-FOREX</h5>
                <p className="grey-text text-lighten-4 f-12">Book-Forex is a fully authorized money exchanger regulated by the Reserve Bank of India (RBI) having registered office at Opera House, Charni Road and Navi Mumbai -Koparkhirane

* Zero margin rates/ interbank rates are available only on forex cards on specific currencies, in select cities for orders amounting to Rs. 1 lakh or more</p>
              </div>
              <div className="col l4 offset-l2 s12">
                <h5 className="white-text"> </h5>
                <ul>
                  <li><a className="grey-text text-lighten-3" href="/">Home</a></li>
                  <li><a className="grey-text text-lighten-3" href="/terms-of-use">Terms of use</a></li>
                  <li><a className="grey-text text-lighten-3" href="/privacy-policy">Privacy Policy</a></li>
                  <li><a className="grey-text text-lighten-3" href="/about">About Us</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="container">
            © 2020 Copyright book-forex
            <a className="grey-text text-lighten-4 right" href="#!">contact: 9323612754</a>
            </div>
          </div>
        </footer>
            

        </div>
    );
  }
}
