import React  from 'react';
import DataTable from 'react-data-table-component';
import '@fortawesome/fontawesome-free/css/all.min.css';

import firebase from "firebase";

import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

import MoneyTransferLayout from './popupLayouts/MoneyTransferLayout';
import CallmeBackEnquiry from './popupLayouts/CallmeBackEnquiry';
import SimEnquiryLayout from './popupLayouts/SimEnquiryLayout'; 
import TravelInsuranceEnquiryLayout from './popupLayouts/TravelInsuranceEnquiryLayout';

import RequestBetterRateEnquiry from './popupLayouts/RequestBetterRateEnquiry';


import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

export default class EnquiryAdmin extends React.Component {

	constructor (props) {
	    super(props)
	    this.state = {
	    	enquiryData:[],
	    	selectedRow:{}
	    }  

	    this.openModal = this.openModal.bind(this)
	    
	  }

	componentDidMount(){
		this.getDataFromFiretore(0)
	    M.Modal.init(this.Modal,{dismissible:false});
  	}

	getDataFromFiretore(pagination){
      if (global.login===true && global.adminLocation!= null) {
        var tempArray = []
        var nextButton = false

        if (pagination===0) {
          if (global.admin===false) {
            var locations = global.adminLocation.split(',')
            
            var query = firebase.firestore().collection("enquries").where("selectedBuyCity", "in", locations).limit(global.perPageLength)
            
          }else{
            var query = firebase.firestore().collection("enquries").orderBy("date", "desc").limit(global.perPageLength)
            
          }
          
        }else{
          var lastSet= this.state.lastState
          if (global.admin===false) {
            var locations = global.adminLocation.split(',')
            
            
            var query = firebase.firestore().collection("enquries").where("selectedBuyCity", "in", locations).limit(global.perPageLength).startAfter(lastSet)
            
          }else{
            
            var query = firebase.firestore().collection("enquries").orderBy("date", "desc").startAfter(lastSet).limit(global.perPageLength)
           
          }
          
          
        }



        query.get()
        .then((querySnapshot)=> {
        
        var  last = querySnapshot.docs[querySnapshot.docs.length - 1];

        querySnapshot.forEach((doc)=> {
            // doc.data() is never undefined for query doc snapshots
            tempArray.push(doc.data())
        })


            if (tempArray.length===global.perPageLength) {
              nextButton=true
            }else{
              nextButton=false
            }

            this.setState({
              enquiryData: tempArray,
              lastState: last,
              nextButton:nextButton
            },()=>{
              if (pagination===0) {
                $('#table_id_admin_orders').DataTable({
                      responsive: true,
                       "bInfo" : false,
                       "bLengthChange": false,
                       "pageLength": 50,
                       "language": { search: "" },
                       "aoColumns": [
                            { "bSortable": true },
                            { "bSortable": true },
                            { "bSortable": false }
                        ] 
                    })
              }
            })


        })
        
        .catch(error => {
            console.log('firebase Error::::::::::::'+error)
        })
    }
 }

   





    updateActionDoneData(e){


    }

    openModal(e){

    	this.setState({
    		selectedRow: e
    	},()=>{
    		var a = document.getElementById('rowDetails')
			var instance = M.Modal.getInstance(a)
			instance.open()

    	})
		
	}

	close(){
        var modalBox = document.getElementById('rowDetails');
    	var c =	modalBox.getAttribute('reload');
		if (c==='true') {
			window.location.reload(false);
		}else{
			
			var a = document.getElementById('rowDetails')
			var instance = M.Modal.getInstance(a)
			instance.close()
		}
	}


  renderTable(){
      

      var tempData = this.state.enquiryData

      var allRows = []

      for (var i = 0; i < tempData.length ; i++) {
        var eachRow =   <tr key={[i]}>
                      <td className="f-left">
                        {tempData[i].status==='new'? 
                          <i className="fas fa-circle orange-text" style={{fontSize:9, paddingRight:5}}></i>
                          :
                          <div  className="f-left">
                            {tempData[i].status==='completed'?
                             <i className="fas fa-circle green-text" style={{fontSize:9, paddingRight:5}}></i>
                             :
                             <i className="fas fa-circle grey-text" style={{fontSize:9, paddingRight:5}}></i>
                            }
                          </div>
                          
                        } {tempData[i].id}
                      </td>
                      <td>
                        <div>


                        {tempData[i].code===1?
                          <i className="fas fa-random" style={{fontSize:12, marginRight:5}}></i>
                          :
                          <div className="f-left">
                            {tempData[i].code===2?
                            <i className="fas fa-phone-volume " style={{fontSize:12, marginRight:5}}></i>
                            :
                                        <div className="f-left">
                              {tempData[i].code===3?
                                            <i className="fas fa-coins" style={{fontSize:12, marginRight:5}}></i>
                                            :
                                            <div className="f-left">
                                                {tempData[i].code===4?
                                                <i className="fas fa-sim-card" style={{fontSize:12, marginRight:5}}></i>
                                                :
                                                <i className="fas fa-suitcase-rolling" style={{fontSize:12, marginRight:5}}></i>
                                                }
                                            </div>
                                          }
                                        </div>
                            }
                          </div>
                        }



                        {tempData[i].subject}
                        </div>

                        
                      </td>
                      <td className="t-right"><a className="waves-effect waves-light btn-flat green-border-light b-5 " href="#!" id={[i]} onClick={(e)=>{this.openModalOrders(e)}}>DETAILS</a></td>
                    </tr>
      allRows.push(eachRow)
      }  
      return(allRows)

    }


  openModalOrders(e){
      
      var selectedData = this.state.enquiryData[Number(e.currentTarget.id)]
      this.setState({
        selectedRow: selectedData
      },()=>{
        var a = $('#rowDetails')
        M.Modal.init(a)
      var instance = M.Modal.getInstance(a)
      instance.open()
      console.log(this.state.selectedRow)

  
      })
    
  }



  render() {
    return (
    	<div>
	        <h5 className="black-text">ENQUIRY LIST</h5>

            <div style={{marginBottom:100, marginTop:25}}>
              <table id="table_id_admin_orders">
                <thead>
                    <tr>
                        <th>Order Id</th>
                        <th>Subject</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {this.state.enquiryData.length===0?<div>No data</div>:
                  this.renderTable()
                }
                </tbody>
              </table>
        </div>

        {this.state.nextButton===true?
          <a class="waves-effect waves-light btn-flat green-border-light" onClick={()=>{this.getDataFromFiretore(1)}}>Next 200 records</a>
          :null
        }

        <div id="rowDetails" className="modal" ref={Modal => {this.Modal = Modal; }} >
          <div className="modal-content">
            <h4>{this.state.selectedRow.subject}</h4>
            {this.state.selectedRow.code===1?
              <div>
                <MoneyTransferLayout selectedRow={this.state.selectedRow} updateActionDoneData={this.updateActionDoneData.bind(this)}/>
              </div>
              :
              <div>
                {this.state.selectedRow.code===2?
                  <CallmeBackEnquiry selectedRow={this.state.selectedRow} updateActionDoneData={this.updateActionDoneData.bind(this)}/>
                  :
                            <div>
                            {this.state.selectedRow.code===3?
                  <RequestBetterRateEnquiry selectedRow={this.state.selectedRow} updateActionDoneData={this.updateActionDoneData.bind(this)}/>
                            :
                              <div>
                                {this.state.selectedRow.code===4?
                                <SimEnquiryLayout  selectedRow={this.state.selectedRow} updateActionDoneData={this.updateActionDoneData.bind(this)}/>
                                :
                                <TravelInsuranceEnquiryLayout  selectedRow={this.state.selectedRow} updateActionDoneData={this.updateActionDoneData.bind(this)}/>
                                }

                                </div>
                            }
                            </div>
                  
                }
              </div>

            }
          </div>
          <div className="modal-footer">
            <a href="#!" className="modal-close waves-effect waves-green btn-flat" onClick={()=>{this.close()}}>Close</a>
          </div>
        </div>
		    
	    </div>
    );
  }
}
