import React, { Component } from 'react';

export default class PrivacyPolicy extends React.Component {
  render() {
    return (
        <div >
        	<div className="t-center">
          		<h5 className="blue-text text-darken-2 t-center">PrivacyPolicy</h5>
          		<img src="/termsandpolicy.png" class="responsive-img" />
          	</div>

          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8"> Book-Forex respects your privacy as a visitor to our site www.book-forex.com It is important for you to understand what information we collect about you during your visit and what we do with that information. Your visit to this Site is subject to this "Privacy Policy" and our Terms of Use, as may be amended from time to time. </div>
          		<div className="col s2"></div>
          	</div>
          	
          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8">
          			<div className="f-wt-500"> Information Collection and Use </div>
          			<div>We will not use information provided through your use of this Site except as set forth in this Privacy Policy.</div>
          		</div>
          		<div className="col s2"></div>
          	</div>


          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8">
          			<div className="f-wt-500"> Active Data Collection </div>
          			<div>We collect information from our visitors both by asking specific questions on this site and by permitting you to communicate directly with us via e-mail/ phone/ live chat or another channel. Some information that you submit may be personally identifiable/personal information (that is, information that can be uniquely identified with you, such as your name, address, e-mail address, phone number, etc.) and/or sensitive personal information (that is, information which relates to the following - usernames/ passwords or financial information such as bank account or credit card or debit card or other payment instrument details. All such information is stored or used for processing orders and under no circumstance are any of these details made available in the public domain. We may use this information to send you further information regarding our sevices.</div>
          		</div>
          		<div className="col s2"></div>
          	</div>


          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8">
          			<div className="f-wt-500"> Passive Data Collection </div>
          			<div>We may collect, store or accumulate certain non-personally identifiable information concerning your use of this Site, such as information regarding which of our pages are most often visited. We may share with or transfer to third parties such non-personally identifiable information for the purpose of maintaining the Site, responding to direct inquiries or measuring traffic and traffic trends on this Site. We do not otherwise provide or sell any of this information to third parties. We currently also store "cookies" - files written to the visitor's computer that are used by some sites for record-keeping or to remember certain information about visitors. Information stored in such cookies is only for temporary use to help in the proper functioning of the site. Information stored in cookies is not shared with any third party.</div>
          		</div>
          		<div className="col s2"></div>
          	</div>

          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8">
          			<div className="f-wt-500"> Use of Information </div>
          			<div>Book-Forex uses your personal information and sensitive personal information to enhance the operation of this site, allowing you to use all of its features and respond to your requests for information. We may contact you about new features or services in which we believe you may be interested.

					We may disclose any information we deem necessary, in our sole discretion, to comply with any applicable law, regulation, legal process or governmental request.</div>
          		</div>
          		<div className="col s2"></div>
          	</div>

          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8">
          			<div className="f-wt-500"> Log Files </div>
          			<div>We may use IP addresses to analyze usage, administer our site, and gather demographic information for aggregate use.</div>
          		</div>
          		<div className="col s2"></div>
          	</div>

          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8">
          			<div className="f-wt-500"> Third Parties and Retention of Information </div>
          			<div>We may retain other companies and individuals to perform functions on our behalf. Examples include data analysis firms and web hosting companies. Such third parties may be provided with the information described above. A list of such third parties may be provided under circumstances warranted under applicable law. Further, we will not retain any personal information or sensitive personal information for longer than is necessary for the purposes for which it has been collected</div>
          		</div>
          		<div className="col s2"></div>
          	</div>

          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8">
          			<div className="f-wt-500"> Transfer and Disclosure of Sensitive Personal Information </div>
          			<div>We may transfer or disclose your sensitive personal information to other third parties provided they implement the same level of security practices as we do i.e. not below the standards of IS/ISO/IEC 27001. Such transfer or disclosure shall be for lawful purposes.</div>
          		</div>
          		<div className="col s2"></div>
          	</div>

          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8">
          			<div className="f-wt-500"> Choices You Have About How We Use Your Information </div>
          			<div>We strive to provide you with as many choices and as much control as possible regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:<br/><br/>

						You may send us an e-mail message at info@book-forex.com to request access to, correct or delete any personal information and sensitive personal information that you have provided to us.<br/><br/>

						You may also opt out of the consent so given to us to collect, use, transfer and/or disclose your personal information and/or sensitive personal information and of receiving marketing messages from us by notifying us at info@book-forex.com<br/><br/>

						You should review this privacy policy carefully, because if you do not agree with our practices, your ultimate choice is not to use this site. Remember, by using any part of this Site, you accept and agree to our privacy practices. If we update this privacy policy, your continued use of this Site (following the posting of the revised policy) means that you accept and agree to the terms of the revised privacy policy.</div>
          		</div>
          		<div className="col s2"></div>
          	</div>

          	
          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8">
          			<div className="f-wt-500"> Links </div>
          			<div>This Site may contain links to or from other websites. Please be aware that we are not responsible for the privacy practices of other websites. This Privacy Policy applies only to the information we collect on this site. We encourage you to read the privacy policies of other websites you link to from our Site or otherwise visit.</div>
          		</div>
          		<div className="col s2"></div>
          	</div>

          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8">
          			<div className="f-wt-500"> Notification of Changes </div>
          			<div>We may revise this Privacy Policy from time to time. If we decide to change our Privacy Policy, we will post the revised policy here. As we may make changes at any time without notifying you, we suggest that you periodically consult this Privacy Policy. Please note that our rights to use any information collected will be based on the privacy policy in effect at the time the information is used.</div>
          		</div>
          		<div className="col s2"></div>
          	</div>

          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8">
          			<div className="f-wt-500"> What We Do About Children Visiting This Web Site </div>
          			<div>This Site is not intended for minors. We will not knowingly collect information from Site visitors in the age group of minors, as per applicable law.</div>
          		</div>
          		<div className="col s2"></div>
          	</div>

          	<div className="row">
          		<div className="col s2"></div>
          		<div className="col s8">
          			<div className="f-wt-500"> Comments and Grievances </div>
          			<div>We have taken great measures to ensure that your visit to our Site is a satisfying one and that your privacy is constantly respected. If you have any questions, comments or concerns about our privacy practices, please contact our grievance officer by e-mail at info@book-forex.com or telephone at 32432XXX</div>
          		</div>
          		<div className="col s2"></div>
          	</div>

          	

        </div>
    );
  }
}
