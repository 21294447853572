import React, { Component } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import firebase from "firebase";

var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };



export default class RateSlider extends React.Component {

	constructor (props) {
    super(props)
    this.state = {
     	data:[]

    }   

  }

	componentDidMount(){
     // this.makeSetOfSliders()
     this.getDataFromFiretore();
  }

  getDataFromFiretore(){
	firebase.firestore().collection("rate").doc("master").get()
	.then((doc) => {
	    
	    this.setState({
	    	data: doc.data().data
	    })
	})
	.catch(error => {
        console.log('firebase Error::'+error)
       })
	
}


  lastSliderSet(setLength, i){
  	
  	var a = []

  	for (var j = 0; j < setLength ; j++) {					
		var eachSLide = 
			<div key={j} className="col s2 f-10-small" style={{height:100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    				<div>
	    				<div className="f-wt-500">{this.state.data[i+j].currency}</div>
	    				<div className="">Buy: {this.state.data[i+j].buy_forex_rate} </div>
	    				<div className="">Sell: {this.state.data[i+j].sell_forex_rate} </div>
    				</div>
    			</div>
    				
	    			
		a.push(eachSLide)
				
			}
	return(a)
  }


	makeSetOfSliders(){
		if (this.state.data.length!=0) {


		var a = []
		for (var i = 0; i < this.state.data.length; i++) {

			if (i%5==0) {

				if ((i+5)>this.state.data.length) {
					
				var eachSLide = <div className="green-border-light b-5" key={i}>
				        <div className="col s2"></div>
						<div className="col s8 row ">
							{this.lastSliderSet(this.state.data.length-i, i)}
						</div>
						<div className="col s2"></div>
				      </div>


			}else{
					var eachSLide = <div className="green-border-light b-5" key={i}>
				        <div className="col s2"></div>
						<div className="col s8 row ">
							<div className="col s1 " style={{height:100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			    				
			    			</div>
			    			<div className="col s2 f-10-small" style={{height:100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			    				<div>
				    				<div className="f-wt-500">{this.state.data[i].currency}</div>
				    				<div className="">Buy: {this.state.data[i].buy_forex_rate} </div>
				    				<div className="">Sell: {this.state.data[i].sell_forex_rate} </div>
			    				</div>
			    			</div>
			    			<div className="col s2 f-10-small" style={{height:100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			    				<div>
				    				<div className="f-wt-500">{this.state.data[i+1].currency}</div>
				    				<div className="">Buy: {this.state.data[i+1].buy_forex_rate} </div>
				    				<div className="">Sell: {this.state.data[i+1].sell_forex_rate} </div>
			    				</div>
			    			</div>
			    			<div className="col s2 f-10-small" style={{height:100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			    				<div>
				    				<div className="f-wt-500">{this.state.data[i+2].currency}</div>
				    				<div className="">Buy: {this.state.data[i+2].buy_forex_rate} </div>
				    				<div className="">Sell: {this.state.data[i+2].sell_forex_rate} </div>
			    				</div>
			    			</div>
			    			<div className="col s2 f-10-small" style={{height:100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			    				<div>
				    				<div className="f-wt-500">{this.state.data[i+3].currency}</div>
				    				<div className="">Buy: {this.state.data[i+3].buy_forex_rate} </div>
				    				<div className="">Sell: {this.state.data[i+3].sell_forex_rate} </div>
			    				</div>
			    			</div>
			    			<div className="col s2 f-10-small" style={{height:100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			    				<div>
				    				<div className="f-wt-500">{this.state.data[i+4].currency}</div>
				    				<div className="">Buy: {this.state.data[i+4].buy_forex_rate} </div>
				    				<div className="">Sell: {this.state.data[i+4].sell_forex_rate} </div>
			    				</div>
			    			</div>
			    			<div className="col s1" style={{height:100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
			    				
			    			</div>
						</div>
						<div className="col s2"></div>
				      </div>
				}

				a.push(eachSLide)

			}
		}
		
		
		return (a);
	}
}



  render() {
    return (
        <div id="rateContainer" >
    	<Slider {...settings} style={{height:100}}>
	      {this.makeSetOfSliders()}
	    </Slider>
	    
	    <a className="waves-effect waves-light btn left-rate-button modal-trigger" href="#home-full-rate">Full rate card</a>
	   </div>
  
    );
  }
}
